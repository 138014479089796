import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AdminFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15.96 19.569c1.843-1.381 4.04-3.501 4.04-5.763V4.48L12 2 4 4.48v9.326c0 2.262 2.197 4.382 4.04 5.763A27.147 27.147 0 0 0 12 22a27.149 27.149 0 0 0 3.96-2.431zm-5.862-7.451-.45 2.618L12 13.5l2.351 1.236-.449-2.618 1.902-1.854-2.628-.382L12 7.5l-1.176 2.382-2.628.382 1.902 1.854z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AdminFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AdminFilledSvg} {...props} />
));
AdminFilled.displayName = 'AdminFilled';
export default AdminFilled;
