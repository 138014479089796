import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EditMembersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm4-2a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"
        clipRule="evenodd"
      />
      <path d="M9 14.5a6 6 0 0 0-6 6h-.004v.5h-2v-.5H1a8 8 0 1 1 16 0v.5h-2v-.5a6 6 0 0 0-6-6z" />
      <path
        fillRule="evenodd"
        d="M17.75 7.272a2.984 2.984 0 0 1 .5-.177V6h1.5v1.095a2.975 2.975 0 0 1 .774.32l.775-.774 1.06 1.06-.774.775a3.002 3.002 0 0 1 .321.774h1.095v1.5h-1.095a2.975 2.975 0 0 1-.32.774l.773.774-1.06 1.06-.774-.773a3.006 3.006 0 0 1-.774.32V14h-1.5v-1.095a2.975 2.975 0 0 1-.774-.32l-.775.774-1.06-1.06.774-.775a3.006 3.006 0 0 1-.321-.774h-1.094v-1.5h1.094a2.969 2.969 0 0 1 .32-.774l-.773-.774 1.06-1.06.774.773c.089-.052.18-.1.274-.143zM20 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const EditMembers = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EditMembersSvg} {...props} />
));
EditMembers.displayName = 'EditMembers';
export default EditMembers;
