import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VendorShopifyColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#95BF47"
        fillRule="evenodd"
        d="m10.944 17.875-.037-13.966c-.094-.094-.277-.065-.35-.044l-.478.148a3.334 3.334 0 0 0-.228-.563c-.34-.647-.836-.99-1.436-.99h-.002c-.04 0-.08.004-.12.007h-.005A1.26 1.26 0 0 0 7.235 2c-.774.023-1.545.583-2.172 1.577-.44.7-.774 1.579-.87 2.259l-1.526.473c-.449.14-.463.155-.522.578-.044.32-1.22 9.41-1.22 9.41L10.776 18l.169-.125zM7.717 2.639a.793.793 0 0 0-.466-.122C6.055 2.55 5.01 4.42 4.745 5.665l1.35-.418c.15-.79.527-1.611 1.02-2.14a2.25 2.25 0 0 1 .602-.468zM6.662 5.071l1.645-.51c.005-.428-.042-1.06-.256-1.508-.229.094-.42.259-.559.407-.37.397-.672 1.003-.83 1.611zm2.16-.668.764-.237c-.122-.397-.412-1.062-1-1.174.183.472.23 1.018.236 1.41z"
        clipRule="evenodd"
      />
      <path
        fill="#5E8E3E"
        d="M13.15 4.948c-.058-.004-1.197-.022-1.197-.022s-.952-.925-1.046-1.02a.235.235 0 0 0-.132-.06v14.152l4.268-1.062L13.29 5.08a.155.155 0 0 0-.14-.13z"
      />
      <path
        fill="#fff"
        d="M8.41 7.146 7.914 9s-.553-.252-1.21-.21c-.96.06-.97.667-.96.82.051.828 2.233 1.01 2.356 2.953.097 1.529-.81 2.575-2.118 2.657-1.568.1-2.432-.827-2.432-.827l.332-1.414s.87.656 1.565.611a.615.615 0 0 0 .601-.66c-.069-1.081-1.846-1.018-1.958-2.796-.094-1.497.888-3.013 3.056-3.15.836-.052 1.264.16 1.264.16z"
      />
    </svg>
  )),
  { width: 16, height: 20 },
);
const VendorShopifyColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VendorShopifyColoredSvg} {...props} />
));
VendorShopifyColored.displayName = 'VendorShopifyColored';
export default VendorShopifyColored;
