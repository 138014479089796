import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PollFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M18.07 8.483 10.245 1 4.1 6.876l2.561 2.45H17.19l.88-.843z" />
      <path
        fillRule="evenodd"
        d="M1 11.429V23h22V11.429H1zm9.775 5.887 3.76-3.486 1.531 1.51-5.315 4.929-3.405-3.256 1.556-1.488 1.873 1.791z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PollFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PollFilledSvg} {...props} />
));
PollFilled.displayName = 'PollFilled';
export default PollFilled;
