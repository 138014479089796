import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrAboutSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-ab-a)" clipRule="evenodd">
        <path d="M18.295 4.567a9 9 0 1 1-12.59 12.866 9 9 0 0 1 12.59-12.866zM11.25 9.5v6h1.5v-6h-1.5zm.75-3c-.546 0-1 .456-1 .994 0 .55.454 1.006 1 1.006s1-.456 1-1.006c0-.538-.454-.994-1-.994z" />
        <path d="M4.573 18.17c4.14 4.321 10.78 4.244 14.83-.172l1.097 1.146c-4.644 5.062-12.255 5.15-17 .196l1.073-1.17z" />
      </g>
      <defs>
        <clipPath id="br-ab-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrAbout = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrAboutSvg} {...props} />
));
BrAbout.displayName = 'BrAbout';
export default BrAbout;
