import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CalendarSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15 2v3h2V2h-2z" />
      <path
        fillRule="evenodd"
        d="M22 6.5H2v13A2.5 2.5 0 0 0 4.5 22h15a2.5 2.5 0 0 0 2.5-2.5v-13zM4 11V8.5h16V11H4zm0 2v6.5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5V13H4z"
        clipRule="evenodd"
      />
      <path d="M7 5V2h2v3H7z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Calendar = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CalendarSvg} {...props} />
));
Calendar.displayName = 'Calendar';
export default Calendar;
