const randomAvatarSvg = {
  application: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%235959D3' d='M0 0h64v64H0z'/%3E%3Cpath d='m18.528 22.933.606.3.037-.075L32 29.573l12.83-6.415.024.05.587-.29a30 30 0 0 0-26.913.015zM47 25.494 33.5 32.18v15.57L47 41V25.493zM30.5 47.75V32.183L17 25.525V41l13.5 6.75z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23027D69' d='M0 0h64v64H0z'/%3E%3Cpath d='m18.528 22.933.606.3.037-.075L32 29.573l12.83-6.415.024.05.587-.29a30 30 0 0 0-26.913.015zM47 25.494 33.5 32.18v15.57L47 41V25.493zM30.5 47.75V32.183L17 25.525V41l13.5 6.75z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23844B08' d='M0 0h64v64H0z'/%3E%3Cpath d='m18.528 22.933.606.3.037-.075L32 29.573l12.83-6.415.024.05.587-.29a30 30 0 0 0-26.913.015zM47 25.494 33.5 32.18v15.57L47 41V25.493zM30.5 47.75V32.183L17 25.525V41l13.5 6.75z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%234B11A1' d='M0 0h64v64H0z'/%3E%3Cpath d='m18.528 22.933.606.3.037-.075L32 29.573l12.83-6.415.024.05.587-.29a30 30 0 0 0-26.913.015zM47 25.494 33.5 32.18v15.57L47 41V25.493zM30.5 47.75V32.183L17 25.525V41l13.5 6.75z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238012B3' d='M0 0h64v64H0z'/%3E%3Cpath d='m18.528 22.933.606.3.037-.075L32 29.573l12.83-6.415.024.05.587-.29a30 30 0 0 0-26.913.015zM47 25.494 33.5 32.18v15.57L47 41V25.493zM30.5 47.75V32.183L17 25.525V41l13.5 6.75z' fill='%23fff'/%3E%3C/svg%3E",
  ],
  bot: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%235959D3' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 39.5v-9h3v9h-3zM47 39.5v-9h3v9h-3zM29 20a3 3 0 1 1 4.5 2.599V24.5h8.25a3.75 3.75 0 0 1 3.75 3.75v15A3.75 3.75 0 0 1 41.75 47h-19.5a3.75 3.75 0 0 1-3.75-3.75V24.5h12v-1.901A2.999 2.999 0 0 1 29 20zm-1.5 10.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm6 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23027D69' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 39.5v-9h3v9h-3zM47 39.5v-9h3v9h-3zM29 20a3 3 0 1 1 4.5 2.599V24.5h8.25a3.75 3.75 0 0 1 3.75 3.75v15A3.75 3.75 0 0 1 41.75 47h-19.5a3.75 3.75 0 0 1-3.75-3.75V24.5h12v-1.901A2.999 2.999 0 0 1 29 20zm-1.5 10.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm6 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23844B08' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 39.5v-9h3v9h-3zM47 39.5v-9h3v9h-3zM29 20a3 3 0 1 1 4.5 2.599V24.5h8.25a3.75 3.75 0 0 1 3.75 3.75v15A3.75 3.75 0 0 1 41.75 47h-19.5a3.75 3.75 0 0 1-3.75-3.75V24.5h12v-1.901A2.999 2.999 0 0 1 29 20zm-1.5 10.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm6 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%234B11A1' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 39.5v-9h3v9h-3zM47 39.5v-9h3v9h-3zM29 20a3 3 0 1 1 4.5 2.599V24.5h8.25a3.75 3.75 0 0 1 3.75 3.75v15A3.75 3.75 0 0 1 41.75 47h-19.5a3.75 3.75 0 0 1-3.75-3.75V24.5h12v-1.901A2.999 2.999 0 0 1 29 20zm-1.5 10.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm6 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238012B3' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 39.5v-9h3v9h-3zM47 39.5v-9h3v9h-3zM29 20a3 3 0 1 1 4.5 2.599V24.5h8.25a3.75 3.75 0 0 1 3.75 3.75v15A3.75 3.75 0 0 1 41.75 47h-19.5a3.75 3.75 0 0 1-3.75-3.75V24.5h12v-1.901A2.999 2.999 0 0 1 29 20zm-1.5 10.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm6 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0z' fill='%23fff'/%3E%3C/svg%3E",
  ],
  channel: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%235959D3' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.75 17h22.5A3.75 3.75 0 0 1 47 20.75v16.5A3.75 3.75 0 0 1 43.25 41H23l-6 6V20.75A3.75 3.75 0 0 1 20.75 17z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23027D69' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.75 17h22.5A3.75 3.75 0 0 1 47 20.75v16.5A3.75 3.75 0 0 1 43.25 41H23l-6 6V20.75A3.75 3.75 0 0 1 20.75 17z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23844B08' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.75 17h22.5A3.75 3.75 0 0 1 47 20.75v16.5A3.75 3.75 0 0 1 43.25 41H23l-6 6V20.75A3.75 3.75 0 0 1 20.75 17z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%234B11A1' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.75 17h22.5A3.75 3.75 0 0 1 47 20.75v16.5A3.75 3.75 0 0 1 43.25 41H23l-6 6V20.75A3.75 3.75 0 0 1 20.75 17z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238012B3' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.75 17h22.5A3.75 3.75 0 0 1 47 20.75v16.5A3.75 3.75 0 0 1 43.25 41H23l-6 6V20.75A3.75 3.75 0 0 1 20.75 17z' fill='%23fff'/%3E%3C/svg%3E",
  ],
  member: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h64v64H0z'/%3E%3Cpath fill='%235959D3' d='M0 0h64v64H0z'/%3E%3Cpath d='M32 30.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM32 32.75c6.628 0 12 5.373 12 12v.75H19.995v-.75h.007c0-6.627 5.372-12 12-12z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h64v64H0z'/%3E%3Cpath fill='%23027D69' d='M0 0h64v64H0z'/%3E%3Cpath d='M32 30.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM32 32.75c6.628 0 12 5.373 12 12v.75H19.995v-.75h.007c0-6.627 5.372-12 12-12z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h64v64H0z'/%3E%3Cpath fill='%23844B08' d='M0 0h64v64H0z'/%3E%3Cpath d='M32 30.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM32 32.75c6.628 0 12 5.373 12 12v.75H19.995v-.75h.007c0-6.627 5.372-12 12-12z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h64v64H0z'/%3E%3Cpath fill='%234B11A1' d='M0 0h64v64H0z'/%3E%3Cpath d='M32 30.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM32 32.75c6.628 0 12 5.373 12 12v.75H19.995v-.75h.007c0-6.627 5.372-12 12-12z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h64v64H0z'/%3E%3Cpath fill='%238012B3' d='M0 0h64v64H0z'/%3E%3Cpath d='M32 30.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM32 32.75c6.628 0 12 5.373 12 12v.75H19.995v-.75h.007c0-6.627 5.372-12 12-12z' fill='%23fff'/%3E%3C/svg%3E",
  ],
  organization: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%235959D3' d='M0 0h64v64H0z'/%3E%3Cpath d='M18.5 38.75A3.75 3.75 0 0 1 22.25 35h19.5a3.75 3.75 0 0 1 3.75 3.75V47h-6v-6h-3v6h-3v-6h-3v6h-3v-6h-3v6h-6v-8.25zM33.5 21.25 41 27.5H23l7.5-6.25V17h3v4.25zM23 33.5V29h18v4.5H23z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23027D69' d='M0 0h64v64H0z'/%3E%3Cpath d='M18.5 38.75A3.75 3.75 0 0 1 22.25 35h19.5a3.75 3.75 0 0 1 3.75 3.75V47h-6v-6h-3v6h-3v-6h-3v6h-3v-6h-3v6h-6v-8.25zM33.5 21.25 41 27.5H23l7.5-6.25V17h3v4.25zM23 33.5V29h18v4.5H23z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23844B08' d='M0 0h64v64H0z'/%3E%3Cpath d='M18.5 38.75A3.75 3.75 0 0 1 22.25 35h19.5a3.75 3.75 0 0 1 3.75 3.75V47h-6v-6h-3v6h-3v-6h-3v6h-3v-6h-3v6h-6v-8.25zM33.5 21.25 41 27.5H23l7.5-6.25V17h3v4.25zM23 33.5V29h18v4.5H23z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%234B11A1' d='M0 0h64v64H0z'/%3E%3Cpath d='M18.5 38.75A3.75 3.75 0 0 1 22.25 35h19.5a3.75 3.75 0 0 1 3.75 3.75V47h-6v-6h-3v6h-3v-6h-3v6h-3v-6h-3v6h-6v-8.25zM33.5 21.25 41 27.5H23l7.5-6.25V17h3v4.25zM23 33.5V29h18v4.5H23z' fill='%23fff'/%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238012B3' d='M0 0h64v64H0z'/%3E%3Cpath d='M18.5 38.75A3.75 3.75 0 0 1 22.25 35h19.5a3.75 3.75 0 0 1 3.75 3.75V47h-6v-6h-3v6h-3v-6h-3v6h-3v-6h-3v6h-6v-8.25zM33.5 21.25 41 27.5H23l7.5-6.25V17h3v4.25zM23 33.5V29h18v4.5H23z' fill='%23fff'/%3E%3C/svg%3E",
  ],
  user0: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='M47.19 10 34.566 22.529l.181.228c3.174 4 4.978 8.194 5.224 12.564.246 4.373-1.08 8.71-3.797 12.969l-.092.144-.592.65c-2.869 3.134-6.595 6.92-11.17 11.352l-.42.408C30.34 77.4 48.35 80 48.35 80H92S55.291 43.119 55.393 43.02c-7.765-8.695-8.203-18.718-8.203-18.718V10z' fill='%2330308F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M33.18 24h-.096L22 35l-7 7h7v11.382a33.703 33.703 0 0 0 1.175 5.38c.486-.473.962-.937 1.429-1.394 3.797-3.718 6.934-6.93 9.411-9.635l.473-.52c4.74-7.43 4.682-14.66-.175-21.688A27.846 27.846 0 0 0 33.18 24zM32 34a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3Cpath d='M47.19 10 34.566 22.529l.181.228c3.174 4 4.978 8.194 5.224 12.564.246 4.373-1.08 8.71-3.797 12.969l-.092.144-.592.65c-2.869 3.134-6.595 6.92-11.17 11.352l-.42.408C30.34 77.4 48.35 80 48.35 80H92S55.291 43.119 55.393 43.02c-7.765-8.695-8.203-18.718-8.203-18.718V10z' fill='%23084D42'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M33.18 24h-.096L22 35l-7 7h7v11.382a33.703 33.703 0 0 0 1.175 5.38c.486-.473.962-.937 1.429-1.394 3.797-3.718 6.934-6.93 9.411-9.635l.473-.52c4.74-7.43 4.682-14.66-.175-21.688A27.846 27.846 0 0 0 33.18 24zM32 34a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23084D42'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3Cpath d='M47.19 10 34.566 22.529l.181.228c3.174 4 4.978 8.194 5.224 12.564.246 4.373-1.08 8.71-3.797 12.969l-.092.144-.592.65c-2.869 3.134-6.595 6.92-11.17 11.352l-.42.408C30.34 77.4 48.35 80 48.35 80H92S55.291 43.119 55.393 43.02c-7.765-8.695-8.203-18.718-8.203-18.718V10z' fill='%23673E0D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M33.18 24h-.096L22 35l-7 7h7v11.382a33.703 33.703 0 0 0 1.175 5.38c.486-.473.962-.937 1.429-1.394 3.797-3.718 6.934-6.93 9.411-9.635l.473-.52c4.74-7.43 4.682-14.66-.175-21.688A27.846 27.846 0 0 0 33.18 24zM32 34a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23673E0D'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3Cpath d='M47.19 10 34.566 22.529l.181.228c3.174 4 4.978 8.194 5.224 12.564.246 4.373-1.08 8.71-3.797 12.969l-.092.144-.592.65c-2.869 3.134-6.595 6.92-11.17 11.352l-.42.408C30.34 77.4 48.35 80 48.35 80H92S55.291 43.119 55.393 43.02c-7.765-8.695-8.203-18.718-8.203-18.718V10z' fill='%23350C73'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M33.18 24h-.096L22 35l-7 7h7v11.382a33.703 33.703 0 0 0 1.175 5.38c.486-.473.962-.937 1.429-1.394 3.797-3.718 6.934-6.93 9.411-9.635l.473-.52c4.74-7.43 4.682-14.66-.175-21.688A27.846 27.846 0 0 0 33.18 24zM32 34a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23350C73'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3Cpath d='M47.19 10 34.566 22.529l.181.228c3.174 4 4.978 8.194 5.224 12.564.246 4.373-1.08 8.71-3.797 12.969l-.092.144-.592.65c-2.869 3.134-6.595 6.92-11.17 11.352l-.42.408C30.34 77.4 48.35 80 48.35 80H92S55.291 43.119 55.393 43.02c-7.765-8.695-8.203-18.718-8.203-18.718V10z' fill='%23551174'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M33.18 24h-.096L22 35l-7 7h7v11.382a33.703 33.703 0 0 0 1.175 5.38c.486-.473.962-.937 1.429-1.394 3.797-3.718 6.934-6.93 9.411-9.635l.473-.52c4.74-7.43 4.682-14.66-.175-21.688A27.846 27.846 0 0 0 33.18 24zM32 34a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23551174'/%3E%3C/g%3E%3C/svg%3E",
  ],
  user1: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%2330308F'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%23084D42'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%23084D42'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%23673E0D'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%23673E0D'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%23350C73'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%23350C73'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%23551174'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%23551174'/%3E%3C/g%3E%3C/svg%3E",
  ],
  user2: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.597 73.532S20 66.776 20 58.832v-27.35s.05-.45.217-1.189C18.457 28.516 14 24 14 24h8.96c1.072-1.527 2.521-3.001 4.476-4.103 6.556-3.698 12.8-1.367 16.596 1.706C45.484 23.323 99 76 99 76l-75.403-2.468zM30 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.597 73.532S20 66.776 20 58.832v-27.35s.05-.45.217-1.189C18.457 28.516 14 24 14 24h8.96c1.072-1.527 2.521-3.001 4.476-4.103 6.556-3.698 12.8-1.367 16.596 1.706C45.484 23.323 99 76 99 76l-75.403-2.468zM30 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23084D42'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.597 73.532S20 66.776 20 58.832v-27.35s.05-.45.217-1.189C18.457 28.516 14 24 14 24h8.96c1.072-1.527 2.521-3.001 4.476-4.103 6.556-3.698 12.8-1.367 16.596 1.706C45.484 23.323 99 76 99 76l-75.403-2.468zM30 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23673E0D'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.597 73.532S20 66.776 20 58.832v-27.35s.05-.45.217-1.189C18.457 28.516 14 24 14 24h8.96c1.072-1.527 2.521-3.001 4.476-4.103 6.556-3.698 12.8-1.367 16.596 1.706C45.484 23.323 99 76 99 76l-75.403-2.468zM30 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23350C73'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.597 73.532S20 66.776 20 58.832v-27.35s.05-.45.217-1.189C18.457 28.516 14 24 14 24h8.96c1.072-1.527 2.521-3.001 4.476-4.103 6.556-3.698 12.8-1.367 16.596 1.706C45.484 23.323 99 76 99 76l-75.403-2.468zM30 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23551174'/%3E%3C/g%3E%3C/svg%3E",
  ],
  user3: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M68.907 49.058C63.362 18.765 32.994 18 32.994 18v1.994l.157.012L33 22v28c5.106 0 14.55-3.883 15-13.826C47.941 23.128 33 22 33 22c.15-1.994.152-1.994.152-1.994h.008l.015.002.046.004.155.015c.13.013.312.034.54.066a23.54 23.54 0 0 1 1.85.334c1.513.334 3.555.93 5.622 1.987C45.571 24.55 49.966 28.67 50 36.165v.05l-.002.05c-.256 5.676-3.113 9.66-6.578 12.17C40.02 50.9 35.97 52 33 52h-.006v19.315h40.444c.65 2.173.515 0 .515 0h-.515c-.6-2.005-1.87-7.715-4.531-22.257zM39 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%2330308F'/%3E%3Cpath d='m31 19.843 1.99.15A90.224 90.224 0 0 0 32.975 18C11.626 21.927 12 41 12 41h19V19.843zM31 49.697v-8.694H19.994S23.319 48.149 31 49.697z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M68.907 49.058C63.362 18.765 32.994 18 32.994 18v1.994l.157.012L33 22v28c5.106 0 14.55-3.883 15-13.826C47.941 23.128 33 22 33 22c.15-1.994.152-1.994.152-1.994h.008l.015.002.046.004.155.015c.13.013.312.034.54.066a23.54 23.54 0 0 1 1.85.334c1.513.334 3.555.93 5.622 1.987C45.571 24.55 49.966 28.67 50 36.165v.05l-.002.05c-.256 5.676-3.113 9.66-6.578 12.17C40.02 50.9 35.97 52 33 52h-.006v19.315h40.444c.65 2.173.515 0 .515 0h-.515c-.6-2.005-1.87-7.715-4.531-22.257zM39 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23084D42'/%3E%3Cpath d='m31 19.843 1.99.15A90.224 90.224 0 0 0 32.975 18C11.626 21.927 12 41 12 41h19V19.843zM31 49.697v-8.694H19.994S23.319 48.149 31 49.697z' fill='%23084D42'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M68.907 49.058C63.362 18.765 32.994 18 32.994 18v1.994l.157.012L33 22v28c5.106 0 14.55-3.883 15-13.826C47.941 23.128 33 22 33 22c.15-1.994.152-1.994.152-1.994h.008l.015.002.046.004.155.015c.13.013.312.034.54.066a23.54 23.54 0 0 1 1.85.334c1.513.334 3.555.93 5.622 1.987C45.571 24.55 49.966 28.67 50 36.165v.05l-.002.05c-.256 5.676-3.113 9.66-6.578 12.17C40.02 50.9 35.97 52 33 52h-.006v19.315h40.444c.65 2.173.515 0 .515 0h-.515c-.6-2.005-1.87-7.715-4.531-22.257zM39 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23673E0D'/%3E%3Cpath d='m31 19.843 1.99.15A90.224 90.224 0 0 0 32.975 18C11.626 21.927 12 41 12 41h19V19.843zM31 49.697v-8.694H19.994S23.319 48.149 31 49.697z' fill='%23673E0D'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M68.907 49.058C63.362 18.765 32.994 18 32.994 18v1.994l.157.012L33 22v28c5.106 0 14.55-3.883 15-13.826C47.941 23.128 33 22 33 22c.15-1.994.152-1.994.152-1.994h.008l.015.002.046.004.155.015c.13.013.312.034.54.066a23.54 23.54 0 0 1 1.85.334c1.513.334 3.555.93 5.622 1.987C45.571 24.55 49.966 28.67 50 36.165v.05l-.002.05c-.256 5.676-3.113 9.66-6.578 12.17C40.02 50.9 35.97 52 33 52h-.006v19.315h40.444c.65 2.173.515 0 .515 0h-.515c-.6-2.005-1.87-7.715-4.531-22.257zM39 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23350C73'/%3E%3Cpath d='m31 19.843 1.99.15A90.224 90.224 0 0 0 32.975 18C11.626 21.927 12 41 12 41h19V19.843zM31 49.697v-8.694H19.994S23.319 48.149 31 49.697z' fill='%23350C73'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M68.907 49.058C63.362 18.765 32.994 18 32.994 18v1.994l.157.012L33 22v28c5.106 0 14.55-3.883 15-13.826C47.941 23.128 33 22 33 22c.15-1.994.152-1.994.152-1.994h.008l.015.002.046.004.155.015c.13.013.312.034.54.066a23.54 23.54 0 0 1 1.85.334c1.513.334 3.555.93 5.622 1.987C45.571 24.55 49.966 28.67 50 36.165v.05l-.002.05c-.256 5.676-3.113 9.66-6.578 12.17C40.02 50.9 35.97 52 33 52h-.006v19.315h40.444c.65 2.173.515 0 .515 0h-.515c-.6-2.005-1.87-7.715-4.531-22.257zM39 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23551174'/%3E%3Cpath d='m31 19.843 1.99.15A90.224 90.224 0 0 0 32.975 18C11.626 21.927 12 41 12 41h19V19.843zM31 49.697v-8.694H19.994S23.319 48.149 31 49.697z' fill='%23551174'/%3E%3C/g%3E%3C/svg%3E",
  ],
  user4: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.74 46.133 19 66h53L54.233 37.45l-33.492 8.683z' fill='%2330308F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.203 18a7.036 7.036 0 0 1 5.996 3.361L53 35.703 21 44l.78-9.03c-3.323.023-10.755.049-10.78-.018 3.023-4.489 3.67-5.564 3.84-5.846a.549.549 0 0 1 .07-.106c.018.01.076.04 7.672-3.316l.246-2.841A5.289 5.289 0 0 1 28.089 18h10.114zM33 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.74 46.133 19 66h53L54.233 37.45l-33.492 8.683z' fill='%23084D42'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.203 18a7.036 7.036 0 0 1 5.996 3.361L53 35.703 21 44l.78-9.03c-3.323.023-10.755.049-10.78-.018 3.023-4.489 3.67-5.564 3.84-5.846a.549.549 0 0 1 .07-.106c.018.01.076.04 7.672-3.316l.246-2.841A5.289 5.289 0 0 1 28.089 18h10.114zM33 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23084D42'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.74 46.133 19 66h53L54.233 37.45l-33.492 8.683z' fill='%23673E0D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.203 18a7.036 7.036 0 0 1 5.996 3.361L53 35.703 21 44l.78-9.03c-3.323.023-10.755.049-10.78-.018 3.023-4.489 3.67-5.564 3.84-5.846a.549.549 0 0 1 .07-.106c.018.01.076.04 7.672-3.316l.246-2.841A5.289 5.289 0 0 1 28.089 18h10.114zM33 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23673E0D'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.74 46.133 19 66h53L54.233 37.45l-33.492 8.683z' fill='%23350C73'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.203 18a7.036 7.036 0 0 1 5.996 3.361L53 35.703 21 44l.78-9.03c-3.323.023-10.755.049-10.78-.018 3.023-4.489 3.67-5.564 3.84-5.846a.549.549 0 0 1 .07-.106c.018.01.076.04 7.672-3.316l.246-2.841A5.289 5.289 0 0 1 28.089 18h10.114zM33 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23350C73'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3Cpath d='M20.74 46.133 19 66h53L54.233 37.45l-33.492 8.683z' fill='%23551174'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.203 18a7.036 7.036 0 0 1 5.996 3.361L53 35.703 21 44l.78-9.03c-3.323.023-10.755.049-10.78-.018 3.023-4.489 3.67-5.564 3.84-5.846a.549.549 0 0 1 .07-.106c.018.01.076.04 7.672-3.316l.246-2.841A5.289 5.289 0 0 1 28.089 18h10.114zM33 28a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23551174'/%3E%3C/g%3E%3C/svg%3E",
  ],
  user5: [
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='M34.548 16H22a6 6 0 0 0-6 6v2.017A11.487 11.487 0 0 1 25.5 19C31.851 19 37 24.149 37 30.5c0 11.474-9.2 20.797-20.627 20.997h-.01L16 51.5V66a1 1 0 0 0 1 1h45.106a1 1 0 0 0 .946-1.325l-13.374-38.88A16 16 0 0 0 34.548 16z' fill='%2330308F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.5 21a9.5 9.5 0 0 1 9.5 9.5c0 10.38-8.325 18.817-18.662 18.997L16 49.5v-11h-4a4.5 4.5 0 0 1 4-4.472V30.5l.004-.27A9.5 9.5 0 0 1 25.5 21zM27 32a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23D1F0D3' d='M0 0h64v64H0z'/%3E%3Cpath d='M34.548 16H22a6 6 0 0 0-6 6v2.017A11.487 11.487 0 0 1 25.5 19C31.851 19 37 24.149 37 30.5c0 11.474-9.2 20.797-20.627 20.997h-.01L16 51.5V66a1 1 0 0 0 1 1h45.106a1 1 0 0 0 .946-1.325l-13.374-38.88A16 16 0 0 0 34.548 16z' fill='%23084D42'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.5 21a9.5 9.5 0 0 1 9.5 9.5c0 10.38-8.325 18.817-18.662 18.997L16 49.5v-11h-4a4.5 4.5 0 0 1 4-4.472V30.5l.004-.27A9.5 9.5 0 0 1 25.5 21zM27 32a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23084D42'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23FFF2B6' d='M0 0h64v64H0z'/%3E%3Cpath d='M34.548 16H22a6 6 0 0 0-6 6v2.017A11.487 11.487 0 0 1 25.5 19C31.851 19 37 24.149 37 30.5c0 11.474-9.2 20.797-20.627 20.997h-.01L16 51.5V66a1 1 0 0 0 1 1h45.106a1 1 0 0 0 .946-1.325l-13.374-38.88A16 16 0 0 0 34.548 16z' fill='%23673E0D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.5 21a9.5 9.5 0 0 1 9.5 9.5c0 10.38-8.325 18.817-18.662 18.997L16 49.5v-11h-4a4.5 4.5 0 0 1 4-4.472V30.5l.004-.27A9.5 9.5 0 0 1 25.5 21zM27 32a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23673E0D'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23CCF' d='M0 0h64v64H0z'/%3E%3Cpath d='M34.548 16H22a6 6 0 0 0-6 6v2.017A11.487 11.487 0 0 1 25.5 19C31.851 19 37 24.149 37 30.5c0 11.474-9.2 20.797-20.627 20.997h-.01L16 51.5V66a1 1 0 0 0 1 1h45.106a1 1 0 0 0 .946-1.325l-13.374-38.88A16 16 0 0 0 34.548 16z' fill='%23350C73'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.5 21a9.5 9.5 0 0 1 9.5 9.5c0 10.38-8.325 18.817-18.662 18.997L16 49.5v-11h-4a4.5 4.5 0 0 1 4-4.472V30.5l.004-.27A9.5 9.5 0 0 1 25.5 21zM27 32a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23350C73'/%3E%3C/g%3E%3C/svg%3E",
    "data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23F4D7FF' d='M0 0h64v64H0z'/%3E%3Cpath d='M34.548 16H22a6 6 0 0 0-6 6v2.017A11.487 11.487 0 0 1 25.5 19C31.851 19 37 24.149 37 30.5c0 11.474-9.2 20.797-20.627 20.997h-.01L16 51.5V66a1 1 0 0 0 1 1h45.106a1 1 0 0 0 .946-1.325l-13.374-38.88A16 16 0 0 0 34.548 16z' fill='%23551174'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.5 21a9.5 9.5 0 0 1 9.5 9.5c0 10.38-8.325 18.817-18.662 18.997L16 49.5v-11h-4a4.5 4.5 0 0 1 4-4.472V30.5l.004-.27A9.5 9.5 0 0 1 25.5 21zM27 32a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='%23551174'/%3E%3C/g%3E%3C/svg%3E",
  ],
};
export default randomAvatarSvg;
