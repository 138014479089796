import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrAnalyzeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 13h3v8H3v-8zM8 10h3v11H8V10zM13 12h3v9h-3v-9zM18 8h3v13h-3V8z" />
      <path d="m21 3-6.6 4.643L9.447 5.66 3 9.276v1.72L9.554 7.32 14.6 9.338 21 4.834V3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrAnalyze = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrAnalyzeSvg} {...props} />
));
BrAnalyze.displayName = 'BrAnalyze';
export default BrAnalyze;
