import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

import { CalendarDate, DateFormatter, isSameMonth, today } from '@internationalized/date';
import { useLocale } from '@react-aria/i18n';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import { IconButton } from '@feather/components/button';
import * as Icons from '@feather/components/icons';
import { cssVariables } from '@feather/theme';
import { Headings, Typography } from '@feather/typography';

import { Direction } from './constants';

const HeaderWrapper = styled.div`
  position: relative;
  padding: 0 18px;
  height: 32px;
`;

const PrevArrow = styled(IconButton).attrs({ buttonType: 'secondary', size: 'small' })`
  position: absolute;
  top: 0;
  left: 20px;
`;

const NextArrow = styled(IconButton).attrs({ buttonType: 'secondary', size: 'small' })`
  position: absolute;
  top: 0;
  right: 20px;
`;

const HeaderYear = styled.div`
  ${Headings['heading-01']};
  text-align: center;
  line-height: 32px;
  color: ${cssVariables('neutral-10')};
`;

const StyledCalendar = styled.div`
  padding: 12px 0 16px;
  max-width: 272px;
`;

const MonthItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  min-height: 40px;
`;

const Item = styled.button<{
  isRange?: boolean;
  isActive: boolean;
  isThisMonth: boolean;
  isHoveredRange: boolean;
}>`
  position: relative;
  transition: all 0.2s ${transitionDefault};
  border: 1px solid transparent;
  border: 0;
  background: #fff;
  padding: 0 12px;
  margin: 4px 0;
  width: 80px;
  height: 32px;
  text-align: center;
  line-height: 40px;
  ${Typography['code-01']};

  ${({ isRange }) =>
    isRange &&
    css`
      background: ${cssVariables('purple-1')};
      color: ${cssVariables('primary')};
    `}

  ${({ isActive }) =>
    isActive
      ? css`
          background: ${cssVariables('primary')};
          color: #fff;
        `
      : css`
          &:not(:disabled):hover {
            background: ${cssVariables('bg-2')};
            cursor: pointer;
          }
        `}

  ${({ isHoveredRange }) =>
    isHoveredRange &&
    css`
      background: ${cssVariables('bg-2')};
    `}

  ${({ isThisMonth, isActive }) =>
    isThisMonth &&
    css`
      color: ${cssVariables('primary')};

      &:after {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background: ${cssVariables('primary')};
        width: 4px;
        height: 4px;
        content: '';
      }
      ${isActive &&
      css`
        color: #fff;

        &:after {
          background: #fff;
        }
      `}
    `}
`;

type Range = {
  start: CalendarDate | null;
  end: CalendarDate | null;
};

type Props = {
  range?: Range;
  date?: CalendarDate | null;
  handleMonthChange: (newDate: CalendarDate) => void;
  selectedYear: number;
  setSelectedYear: Dispatch<SetStateAction<number>>;
  minDate?: CalendarDate;
  maxDate: CalendarDate;
  showPrevArrow?: boolean;
  showNextArrow?: boolean;
  onPrevClickCallback?: (newYear: number) => void;
  onNextClickCallback?: (newYear: number) => void;
  handleMouseEnter?: (newDate: CalendarDate) => void;
  handleMouseLeave?: () => void;
  hoveredRange?: { from: CalendarDate; to: CalendarDate } | null;
  timeZone: string;
};

const isDateWithinRange = (target: CalendarDate, { start, end }: Range) => {
  if (start && end) {
    if (end.compare(start) < 0) {
      return false;
    }
    return target.compare(start) >= 0 && target.compare(end) <= 0;
  }
  return false;
};

export const Calendar = ({
  range,
  date,
  handleMonthChange,
  selectedYear,
  setSelectedYear,
  minDate,
  maxDate,
  showPrevArrow = true,
  showNextArrow = true,
  onPrevClickCallback,
  onNextClickCallback,
  handleMouseEnter,
  handleMouseLeave,
  hoveredRange,
  timeZone,
}: Props) => {
  const { locale } = useLocale();
  const formatter = useMemo(() => new DateFormatter(locale, { month: 'short' }), [locale]);

  const onMonthChange = useCallback(
    (month: number) => () => {
      const newDate = new CalendarDate(selectedYear, month, 1);
      handleMonthChange(newDate);
    },
    [handleMonthChange, selectedYear],
  );

  const onHoveredMonthChange = useCallback(
    (month: number) => () => {
      const newDate = new CalendarDate(selectedYear, month, 1);
      handleMouseEnter?.(newDate);
    },
    [handleMouseEnter, selectedYear],
  );

  const onYearChange = useCallback(
    (direction: Direction) => {
      if (selectedYear > 0) {
        // selectedYear should be positive.
        if (direction === Direction.prev) {
          const newYear = selectedYear - 1;
          setSelectedYear(newYear);
          onPrevClickCallback?.(newYear);
        } else {
          const newYear = selectedYear + 1;
          setSelectedYear(newYear);
          onNextClickCallback?.(newYear);
        }
      }
    },
    [onNextClickCallback, onPrevClickCallback, selectedYear, setSelectedYear],
  );

  const renderMonthPicker = useCallback(() => {
    const months = Array.from(Array(12), (_, i) => i);

    return (
      <MonthItems>
        {months.map((monthIndex) => {
          const month = monthIndex + 1;
          const current = new CalendarDate(selectedYear, month, 1);
          const isActive = (() => {
            if (range) {
              // active start and end date for MonthRangePicker
              const { start, end } = range;
              return (!!start && isSameMonth(start, current)) || (!!end && isSameMonth(end, current));
            }
            // active date for MonthSinglePicker
            return !!date && isSameMonth(date, current);
          })();
          const isBeforeMinDate = minDate && selectedYear <= minDate.year && minDate.month > month;
          const isAfterMaxDate = selectedYear >= maxDate.year && maxDate.month < month;

          const isHoveredRange = (() => {
            if (hoveredRange) {
              const { from, to } = hoveredRange;
              return from.compare(current) < 0 && current.compare(to) < 0;
            }
            return false;
          })();

          const label = formatter.format(current.toDate(timeZone));

          return (
            <Item
              key={`calendar_item_month_${monthIndex}`}
              type="button"
              isActive={isActive}
              isRange={!!range && isDateWithinRange(current, range)}
              isHoveredRange={isHoveredRange}
              onClick={onMonthChange(month)}
              disabled={isBeforeMinDate || isAfterMaxDate}
              isThisMonth={isSameMonth(current, today(timeZone))}
              onMouseEnter={onHoveredMonthChange(month)}
              onMouseLeave={() => {
                handleMouseLeave?.();
              }}
            >
              {label}
            </Item>
          );
        })}
      </MonthItems>
    );
  }, [
    date,
    handleMouseLeave,
    hoveredRange,
    locale,
    maxDate,
    minDate,
    onHoveredMonthChange,
    onMonthChange,
    range,
    selectedYear,
    timeZone,
  ]);

  return (
    <StyledCalendar>
      <HeaderWrapper>
        {showPrevArrow && (
          <PrevArrow
            icon={Icons.ChevronLeft}
            onClick={() => onYearChange(Direction.prev)}
            disabled={minDate && selectedYear <= minDate.year}
          />
        )}

        <HeaderYear>{selectedYear}</HeaderYear>
        {showNextArrow && (
          <NextArrow
            icon={Icons.ChevronRight}
            onClick={() => onYearChange(Direction.next)}
            disabled={selectedYear >= maxDate.year}
          />
        )}
      </HeaderWrapper>
      {renderMonthPicker()}
    </StyledCalendar>
  );
};
