import type { CSSVariableKey } from '@feather/types';

import colorSet from './colorSet';
import createThemeVariables from './utils/createThemeVariables';

type CSSColor = (lookupKey: CSSVariableKey | (string | number)[]) => string;
const { cssColors: createdCSSColors } = createThemeVariables({
  target: colorSet,
  options: { excludedKeys: ['default'] },
});

/**
 * @param lookupKey A string value or an array [string, number] to query a color
 * @returns A CSS color such as a hex color, an rgb(), and rgba() notation e.g. `#CABDFF`
 */
const cssColors: CSSColor = (lookupKey) => {
  const colorKey = Array.isArray(lookupKey) ? lookupKey.join('-') : lookupKey;

  if (createdCSSColors[colorKey] === undefined) {
    throw new Error(`The color "${lookupKey}" does not exist in "cssColors"`);
  }

  return createdCSSColors[colorKey];
};

export default cssColors;
