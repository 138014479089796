import { useCallback, forwardRef } from 'react';

import styled from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { InlineNotificationProps, NotificationSize } from '@feather/types';
import { OverlayButtonBackgroundType } from '@feather/types';
import { Subtitles } from '@feather/typography';

import { OverlayButton, OverlayButtonBackgroundTypeContext } from '../button';
import * as Icons from '../icons';

const Container = styled.div<{ backgroundColor: string; columns: string[] }>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns.map((item) => ({ icon: '20px', message: '1fr', action: 'auto', close: '16px' }[item])).join(' ')};
  grid-template-rows: auto;
  grid-gap: 8px;
  border-radius: 4px;
  padding: 0 16px;
  background-color: ${(props) => props.backgroundColor};
`;

const IconWrapper = styled.div<{ size: NotificationSize }>`
  padding-top: ${({ size }) => (size === 'small' ? 10 : 14)}px;

  svg {
    display: block;
  }
`;

const MessageWrapper = styled.div<{ hasAction: boolean; size: NotificationSize }>`
  grid-column: 2 / span 1;
  ${Subtitles['subtitle-01']};
  padding: ${({ size }) => (size === 'small' ? 10 : 14)}px 0;
  font-weight: 400;
  color: ${cssVariables('neutral-10')};
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const InlineNotification = styled(
  forwardRef<HTMLDivElement, InlineNotificationProps>(
    ({ action, type, size = 'medium', message, className, onClose, iconProps, ...props }, ref) => {
      const backgroundColor = {
        error: cssVariables('red-2'),
        info: cssVariables('neutral-2'),
        success: cssVariables('green-2'),
        warning: cssVariables('yellow-2'),
      }[type];

      const overlayButtonBackgroundType = {
        error: OverlayButtonBackgroundType.Danger,
        info: OverlayButtonBackgroundType.Mono,
        success: OverlayButtonBackgroundType.Success,
        warning: OverlayButtonBackgroundType.Warning,
      }[type];

      const handleClickClose = useCallback(() => {
        onClose?.();
      }, [onClose]);

      const renderAction = (definedAction: NonNullable<typeof action>) => {
        const { label, ...buttonProps } = definedAction;
        return (
          <ActionWrapper>
            <OverlayButton {...buttonProps}>{label}</OverlayButton>
          </ActionWrapper>
        );
      };

      const renderIcon = () => {
        const iconColor = {
          error: cssVariables('red-5'),
          info: cssVariables('neutral-10'),
          success: cssVariables('green-5'),
          warning: cssVariables('neutral-10'),
        }[type];

        const defaultIcon = {
          error: Icons.ErrorFilled,
          info: Icons.InfoFilled,
          success: Icons.SuccessFilled,
          warning: Icons.WarningFilled,
        }[type];

        const defaultIconProps = {
          size: 20,
          color: iconColor,
          assistiveText: 'Notification',
        };

        const { icon: customIcon, ...customIconProps } = iconProps || {};
        const IconComponent = customIcon || defaultIcon;
        const notificationIconProps = {
          ...defaultIconProps,
          ...customIconProps,
        };

        return (
          <IconWrapper size={size}>
            <IconComponent {...notificationIconProps} />
          </IconWrapper>
        );
      };

      return (
        <OverlayButtonBackgroundTypeContext.Provider value={overlayButtonBackgroundType}>
          <Container
            ref={ref}
            role="alert"
            className={className}
            backgroundColor={backgroundColor}
            columns={['icon', 'message', action && 'action', onClose && 'close'].filter((v): v is string => !!v)}
            {...props}
          >
            {renderIcon()}
            <MessageWrapper hasAction={!!action} size={size}>
              {message}
            </MessageWrapper>
            {action && renderAction(action)}
            {onClose && (
              <CloseWrapper onClick={handleClickClose}>
                <Icons.Close size={16} color={cssVariables('neutral-9')} />
              </CloseWrapper>
            )}
          </Container>
        </OverlayButtonBackgroundTypeContext.Provider>
      );
    },
  ),
)``;
