import type { SimpleInterpolation } from 'styled-components';
import { css } from 'styled-components';

import type { ToastID, ToastQueueItem } from '@feather/types';

export const transitionDuration = 300;
export const defaultAutoHideDuration = 5000;
export const maxWidth = 440;
export const minWidth = 360;
export const toastSpacing = 8;

export interface ToastQueueRef {
  addToast: (item: ToastQueueItem) => void;
  removeToast: (item: ToastID) => void;
  removeAllToast: () => void;
}

export const initialContainerStyles: SimpleInterpolation = css`
  .toast-container {
    width: ${maxWidth}px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      position: absolute;
      bottom: 0;
    }
  }
`;
