import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FrameIndependentSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7 3a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H7zM4 6a1 1 0 0 0-1 1v10a1 1 0 1 0 2 0V7a1 1 0 0 0-1-1zM19 7a1 1 0 1 1 2 0v10a1 1 0 1 1-2 0V7zM7 19a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H7z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FrameIndependent = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FrameIndependentSvg} {...props} />
));
FrameIndependent.displayName = 'FrameIndependent';
export default FrameIndependent;
