import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ThemeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13.566 3.5H3.5v11.848a2.5 2.5 0 0 0 1.856 2.415L9.5 18.868V20a2.5 2.5 0 0 0 5 0v-1.132l4.144-1.105a2.5 2.5 0 0 0 1.856-2.415V3.5h-5.066L14.5 5.056 13.566 3.5zm4.934 8v-6h-1.934L14.5 8.944 12.434 5.5H5.5v6h13zm-13 3.848V13.5h13v1.848a.5.5 0 0 1-.371.483l-5.629 1.5V20a.5.5 0 0 1-1 0v-2.668L5.871 15.83a.5.5 0 0 1-.371-.483z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Theme = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ThemeSvg} {...props} />
));
Theme.displayName = 'Theme';
export default Theme;
