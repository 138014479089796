import { createContext, forwardRef, useContext } from 'react';

import rgba from 'polished/lib/color/rgba';
import styled from 'styled-components';

import { cssColors, cssVariables } from '@feather/theme';
import type { OverlayButtonProps, ButtonContainerColorSet, FeatherTheme } from '@feather/types';
import { OverlayButtonBackgroundType } from '@feather/types';

import { Spinner } from '../spinner';
import * as hideOutlineEventListeners from './hideOutlineEventListeners';
import { generateButtonContainerStyle, generateButtonSizeStyle } from './styleGenerators';

export const OverlayButtonBackgroundTypeContext = createContext(OverlayButtonBackgroundType.Mono);

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface GetContainerStyleGeneratorOptions {
  (props: { theme: FeatherTheme; backgroundType: OverlayButtonBackgroundType }): ButtonContainerColorSet;
}

const getContainerStyleGeneratorOptions: GetContainerStyleGeneratorOptions = ({ backgroundType }) => {
  const generateOptionsFromBaseColor = (baseColor: string) => {
    return {
      contentColor: baseColor === 'white' ? 'white' : cssVariables('neutral-10'),
      disabledContentColor: rgba(baseColor, 0.32),
      bgColor: rgba(baseColor, 0.08),
      hoverBgColor: rgba(baseColor, 0.16),
      activeBgColor: rgba(baseColor, 0.12),
      pressedBgColor: rgba(baseColor, 0.12),
      disabledBgColor: rgba(baseColor, 0.08),
      borderColor: 'transparent',
      disabledBorderColor: 'transparent',
    };
  };
  switch (backgroundType) {
    case OverlayButtonBackgroundType.Inverse:
      return generateOptionsFromBaseColor('white');
    case OverlayButtonBackgroundType.Success:
      return generateOptionsFromBaseColor(cssColors('green-6'));
    case OverlayButtonBackgroundType.Danger:
      return generateOptionsFromBaseColor(cssColors('red-6'));
    case OverlayButtonBackgroundType.Warning:
      return generateOptionsFromBaseColor(cssColors('yellow-6'));
    default:
      return generateOptionsFromBaseColor(cssColors('neutral-6'));
  }
};

const StyledButton = styled.button<OverlayButtonProps & { backgroundType: OverlayButtonBackgroundType }>`
  :disabled {
    cursor: ${(props) => (props.disabled && !props.isLoading ? 'not-allowed' : 'progress')};
  }

  ${(props) => {
    const styleGeneratorOptions = getContainerStyleGeneratorOptions(props);
    return generateButtonContainerStyle(styleGeneratorOptions);
  }}
  ${generateButtonSizeStyle({ size: 'small' })}
  
  &[data-is-loading='true'] {
    color: transparent !important;
  }
`;

export const OverlayButton = forwardRef<HTMLButtonElement, OverlayButtonProps>(
  ({ isLoading, children, type = 'button', ...restProps }, ref) => {
    const backgroundType = useContext(OverlayButtonBackgroundTypeContext);

    return (
      <StyledButton
        ref={ref}
        type={type}
        backgroundType={backgroundType}
        data-is-loading={isLoading}
        {...hideOutlineEventListeners}
        {...restProps}
      >
        {isLoading && (
          <SpinnerWrapper>
            <Spinner
              size={20}
              stroke={backgroundType === OverlayButtonBackgroundType.Inverse ? 'white' : cssVariables('purple-7')}
            />
          </SpinnerWrapper>
        )}
        {children}
      </StyledButton>
    );
  },
);
