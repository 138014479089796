import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UpgradeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M22.414 12 12 1.586 1.586 12H7.5v6h9v-6h5.914zM9.5 10H6.414L12 4.414 17.586 10H14.5v6h-5v-6z"
        clipRule="evenodd"
      />
      <path d="M7 22h10v-2H7v2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Upgrade = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UpgradeSvg} {...props} />
));
Upgrade.displayName = 'Upgrade';
export default Upgrade;
