import type { HTMLAttributes } from 'react';

import type { PrimitiveColor } from '@feather/types';

export enum LozengeVariant {
  Light = 'light',
  Dark = 'dark',
}

export interface LozengeProps extends HTMLAttributes<HTMLDivElement> {
  color: PrimitiveColor;
  variant?: LozengeVariant;
  children: string;
  className?: string;
}
