import type { ComponentProps } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { DateFormatter, endOfMonth, getLocalTimeZone, isSameMonth, startOfMonth, today } from '@internationalized/date';
import { useLocale } from '@react-aria/i18n';
import { useSelect } from 'downshift';
import type { PopperProps } from 'react-popper';
import { Manager, Reference, Popper } from 'react-popper';
import type { SimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

import { Button } from '@feather/components/button';
import * as Icons from '@feather/components/icons';
import { elevation } from '@feather/elevation';
import { cssVariables } from '@feather/theme';
import { ZIndexes } from '@feather/zIndexes';

import type { DropdownSize } from '../../types/components/dropdown';
import { Calendar } from './calendar';
import { MonthTargetStart, MonthTarget, MonthIcon, MonthWrapper } from './components';

type SingleMonthPickerProps = {
  date: CalendarDate | null;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  placement?: PopperProps['placement'];
  disabled?: boolean;
  className?: string;
  popperProps?: Partial<PopperProps>;
  targetStyles?: SimpleInterpolation;
  size?: DropdownSize;
  /**
   * Change event handler.
   * You have to update date prop in this event handler to get SingleMonthPicker working.
   */
  onChange: (date: CalendarDate) => void;
  onClose?: () => void;
  timeZone?: string;
};

const JumpToCurrentMonthButtonWrapper = styled.div`
  border-top: 1px solid ${cssVariables('border-3')};
`;

const JumpToCurrentMonthButton = styled(Button).attrs({ variant: 'ghost', buttonType: 'primary' })`
  width: 100%;
  height: 63px;
  border-radius: 0 0 4px 4px;
`;

const MonthPickerWrapper = styled.div`
  position: absolute;
  border-radius: 4px;
  overflow: hidden;
  z-index: ${ZIndexes.dropdownMenu};
  background: white;
  ${elevation.popover};
`;

export const SingleMonthPicker = ({
  date,
  onChange,
  placement = 'bottom-start',
  disabled,
  className,
  size = 'small',
  popperProps,
  onClose,
  targetStyles,
  minDate: minDateProp,
  maxDate: maxDateProp,
  timeZone = getLocalTimeZone(),
}: SingleMonthPickerProps) => {
  const todayCalendarDate = today(timeZone);
  const minDate = useMemo(() => (minDateProp ? startOfMonth(minDateProp) : undefined), [minDateProp]);
  const maxDate = useMemo(() => endOfMonth(maxDateProp ?? todayCalendarDate), [maxDateProp, todayCalendarDate]);

  const { locale } = useLocale();
  const defaultSelectedYear = date?.year || today(timeZone).year;
  const [selectedYear, setSelectedYear] = useState<number>(defaultSelectedYear);
  const { isOpen, getToggleButtonProps, getMenuProps, closeMenu } = useSelect({
    items: [],
    onIsOpenChange: (changes) => {
      if (changes.type === useSelect.stateChangeTypes.ToggleButtonClick && selectedYear !== defaultSelectedYear) {
        // If selectedYear is different from defaultSelectedYear immediately after dropdown is opened, we need to update selectedYear.
        setSelectedYear(defaultSelectedYear);
      }
      return changes;
    },
  });

  const handleClose = useCallback(() => {
    onClose?.();
    closeMenu();
  }, [closeMenu, onClose]);

  const handleMonthChange: ComponentProps<typeof Calendar>['handleMonthChange'] = useCallback(
    (date) => {
      onChange(startOfMonth(date));
      handleClose();
    },
    [handleClose, onChange],
  );

  const targetDate = useMemo(() => {
    if (!date) return 'Select month';
    const formatter = new DateFormatter(locale, { year: 'numeric', month: 'long', timeZone });
    return isSameMonth(date, todayCalendarDate) ? 'Current month' : formatter.format(date.toDate(timeZone));
  }, [date, locale, timeZone, todayCalendarDate]);

  const isThisMonthWithinMinMaxDate = minDate
    ? todayCalendarDate.compare(minDate) >= 0 && todayCalendarDate.compare(maxDate) <= 0
    : todayCalendarDate.compare(maxDate) <= 0;
  const isThisMonthSelected = !!date && isSameMonth(date, todayCalendarDate);

  const { ref: menuPropsRef, ...menuProps } = getMenuProps({
    onMouseUp: (event) => {
      // prevent document mouseup event handler (which will close the date picker) from being called.
      event.stopPropagation();
    },
  });

  return (
    <Manager>
      <Reference>
        {({ ref: popperRef }) => {
          return (
            <MonthTarget
              {...getToggleButtonProps({ ref: popperRef })}
              type="button"
              size={size}
              disabled={disabled}
              className={className}
              aria-pressed={isOpen}
              isActive={isOpen}
              styles={targetStyles}
              data-test-id="SingleMonthPickerTarget"
            >
              <MonthTargetStart dateTime={date?.toString().substring(0, 7)} isThisMonth={isThisMonthSelected}>
                {targetDate}
              </MonthTargetStart>
              <MonthIcon>
                <Icons.ChevronDown size={18} />
              </MonthIcon>
            </MonthTarget>
          );
        }}
      </Reference>
      <Popper placement={placement} {...popperProps} innerRef={menuPropsRef}>
        {({ ref: setPopperRef, style }) => (
          <MonthPickerWrapper ref={setPopperRef} {...menuProps} style={style} data-placement={placement}>
            {isOpen && (
              <MonthWrapper>
                <Calendar
                  handleMonthChange={handleMonthChange}
                  date={date}
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                  minDate={minDate}
                  maxDate={maxDate}
                  timeZone={timeZone}
                />
                <JumpToCurrentMonthButtonWrapper>
                  <JumpToCurrentMonthButton
                    disabled={!isThisMonthWithinMinMaxDate || isThisMonthSelected}
                    onClick={() => handleMonthChange(todayCalendarDate)}
                  >
                    Jump to current month
                  </JumpToCurrentMonthButton>
                </JumpToCurrentMonthButtonWrapper>
              </MonthWrapper>
            )}
          </MonthPickerWrapper>
        )}
      </Popper>
    </Manager>
  );
};
