import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UserChatHistorySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 6H3V4h18v2zM3 15.347h2.906v-2H3v2zM3 20h4.339v-2H3v2zM3 10.663h4.79v-2H3v2zM16 11h-2v2.926l2.076 2.076 1.414-1.414-1.49-1.49V11z" />
      <path
        fillRule="evenodd"
        d="M8.5 13.75a6.25 6.25 0 1 1 12.5 0 6.25 6.25 0 0 1-12.5 0zm6.25-4.25a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UserChatHistory = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UserChatHistorySvg} {...props} />
));
UserChatHistory.displayName = 'UserChatHistory';
export default UserChatHistory;
