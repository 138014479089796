import styled from 'styled-components';

import type { FeatherIconProps } from '@feather/components/icons/types';
import cssVariables from '@feather/theme/cssVariables';

import type { FeatherIconComponent } from '../icons/types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
`;

type TooltipTargetIconProps = { icon: FeatherIconComponent } & Omit<FeatherIconProps, 'size'> & {
    size?: FeatherIconProps['size'];
  };

export const TooltipTargetIcon = ({ icon: Icon, size = 16, className, ...props }: TooltipTargetIconProps) => (
  <Wrapper className={className}>
    <Icon size={size} {...props} />
  </Wrapper>
);

TooltipTargetIcon.defaultProps = {
  size: 16,
  color: cssVariables('neutral-6'),
};
