import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TagSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.13 8.138a1.256 1.256 0 0 0 0-1.771 1.244 1.244 0 0 0-1.765 0 1.256 1.256 0 0 0 0 1.771c.487.49 1.277.49 1.764 0z" />
      <path
        fillRule="evenodd"
        d="M12.407 2 2 2.003V12.41l9.098 9.079a2.5 2.5 0 0 0 3.535 0l6.858-6.855a2.5 2.5 0 0 0 0-3.537L12.408 2zm-8.394 9.58-.015-7.575 7.58.008 8.5 8.5a.5.5 0 0 1 0 .707l-6.86 6.855a.5.5 0 0 1-.707 0l-8.498-8.494z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Tag = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TagSvg} {...props} />
));
Tag.displayName = 'Tag';
export default Tag;
