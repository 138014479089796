import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VideoOnFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <path d="M4 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2.969l4.35 3.728A1 1 0 0 0 23 18V6a1 1 0 0 0-1.65-.76L17 8.97V6a3 3 0 0 0-3-3H4z" />
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const VideoOnFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VideoOnFilledSvg} {...props} />
));
VideoOnFilled.displayName = 'VideoOnFilled';
export default VideoOnFilled;
