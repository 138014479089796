import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UnifiedInboxColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#6210CC"
        fillRule="evenodd"
        d="M15.73 6.205A4.2 4.2 0 0 0 11.535 2a4.2 4.2 0 0 0-4.193 4.205h8.386zM1.576 11.987A4.2 4.2 0 0 1 5.77 7.782v4.205H1.577zm4.193 4.205a4.2 4.2 0 0 1-4.193-4.205H5.77v4.205zm11.532 0a4.2 4.2 0 0 0 4.193-4.205h-4.193v4.205zm0-8.41a4.2 4.2 0 0 1 4.193 4.205h-4.193V7.782zm-9.959 9.987a4.2 4.2 0 0 0 4.193 4.205 4.2 4.2 0 0 0 4.193-4.205H7.343zM21.495 6.205A4.2 4.2 0 0 0 17.302 2v4.205h4.193zM5.77 21.974a4.2 4.2 0 0 1-4.193-4.205H5.77v4.205zM5.77 2a4.2 4.2 0 0 0-4.193 4.205H5.77V2zm15.725 15.769a4.2 4.2 0 0 1-4.193 4.205v-4.205h4.193zm-9.959-1.577a4.2 4.2 0 0 0 4.194-4.205 4.2 4.2 0 0 0-4.194-4.205 4.2 4.2 0 0 0-4.193 4.205 4.2 4.2 0 0 0 4.193 4.205z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UnifiedInboxColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UnifiedInboxColoredSvg} {...props} />
));
UnifiedInboxColored.displayName = 'UnifiedInboxColored';
export default UnifiedInboxColored;
