import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AgentsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 4a8 8 0 0 0-8 8v6.5H2V12C2 6.477 6.477 2 12 2s10 4.477 10 10v6.5h-2V12a8 8 0 0 0-8-8z" />
      <path d="M5 16a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0v-4zM17 14a2 2 0 0 0-2 2v4a2 2 0 1 0 4 0v-4a2 2 0 0 0-2-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AgentsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AgentsFilledSvg} {...props} />
));
AgentsFilled.displayName = 'AgentsFilled';
export default AgentsFilled;
