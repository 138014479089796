import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PlusCircleFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeWidth={2}
        d="M10 16.5v1h4V14h3.5v-4H14V6.5h-4V10H6.5v4H10v2.5zM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PlusCircleFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PlusCircleFilledSvg} {...props} />
));
PlusCircleFilled.displayName = 'PlusCircleFilled';
export default PlusCircleFilled;
