import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { getLocalTimeZone } from '@internationalized/date';

export enum CalendarType {
  Calendar,
  RangeCalendar,
}

export type CalendarOptions = {
  locale: string;
  showOutsideVisibleRange: boolean;
  timeZone: string;
  type: CalendarType;
};

const defaultValue: CalendarOptions = {
  locale: 'en-US',
  showOutsideVisibleRange: false,
  timeZone: getLocalTimeZone(),
  type: CalendarType.Calendar,
};

const Context = createContext<CalendarOptions>(defaultValue);

type Props = {
  children: ReactNode;
  options: CalendarOptions;
};
export const CalendarOptionsProvider = ({ children, options }: Props) => {
  return <Context.Provider value={options}>{children}</Context.Provider>;
};

export const useCalendarOptions = () => useContext(Context);
