import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { createRoot } from 'react-dom/client';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import type { SimpleInterpolation } from 'styled-components';
import styled, { keyframes } from 'styled-components';

import type { ToastID, ToastQueueItem } from '@feather/types';
import { ZIndexes } from '@feather/zIndexes';

import { ToastComponent } from './ToastComponent';
import type { PrerenderResult } from './ToastComponent';
import type { ToastQueueRef } from './constants';
import { defaultAutoHideDuration, toastSpacing, transitionDuration } from './constants';
import { initialContainerStyles } from './constants';

/**
 * Use of window.setTimeout and window.setInterval causes Dashboard test failures during unmount.
 * So we need to use `window` with optional chaining.
 */

const slidein = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Container = styled.div<{ containerStyles: SimpleInterpolation }>`
  position: fixed;
  z-index: ${ZIndexes.toast};
  left: 24px;
  bottom: 24px;

  ${({ containerStyles }) => {
    return containerStyles;
  }}

  .item {
    animation: ${transitionDuration}ms ease-in ${slidein};
  }

  .item-exit {
    opacity: 1;
  }

  .item-exit-active {
    opacity: 0;
    height: 0;
    transition: ${transitionDuration}ms ease-in;
    transition-property: opacity, height;
  }
`;

const findElement = (
  callback: () => HTMLElement | null,
  options: { timeout: number; interval: number } = { timeout: 1000, interval: 10 },
) => {
  if (typeof window === 'undefined') {
    const target = callback();
    return Promise.resolve(target);
  }

  return new Promise<HTMLElement | null>((resolve) => {
    const timer = window?.setInterval(() => {
      const target = callback();
      if (target) {
        window?.clearInterval(timer);
        resolve(target);
      }
    }, options.interval);

    // clear interval after timeout
    window?.setTimeout(() => {
      window?.clearInterval(timer);
      resolve(null);
    }, options.timeout);
  });
};

const buildStyleAttribute = ({ translateY, transitionDuration }: { translateY: number; transitionDuration: number }) =>
  `transform: translateY(${translateY}px); transition-duration: ${transitionDuration}ms;`;

type ToastStateItem = ToastQueueItem & PrerenderResult;

export const ToastQueue = forwardRef<ToastQueueRef, {}>((props, ref) => {
  const [toasts, setToasts] = useState<ToastStateItem[]>([]);
  const toastElementRefs = useRef<Record<string, HTMLElement | null>>({});
  const autoHideTimerRefs = useRef<Record<string, number>>({});

  const containerStyles = initialContainerStyles;

  const deleteToast = useCallback((id: string) => {
    setToasts((toasts) => toasts.filter((item) => item.id !== id));
  }, []);

  /**
   * Returns the rendered component of a ToastQueueItem.
   *
   * @param item ToastQueueItem to render as component
   * @param renderOptions Set `renderOptions.prerender` true to measure the rendered component. The rendered DOM node
   * will have `data-prerender-height` and `data-prerender-layout` attributes. Set `renderOptions.layout` either `short`
   * or `tall` to preset the layout to avoid rerendering during animation.
   */
  const renderToast = (
    { id, status, option }: ToastQueueItem,
    renderOptions: { prerender?: boolean; layout?: PrerenderResult['layout'] } = { prerender: false },
  ) => {
    const { title, message, onClose, actions, iconProps } = option;
    const { prerender, layout } = renderOptions;

    return (
      <ToastComponent
        ref={
          prerender
            ? undefined
            : (node) => {
                toastElementRefs.current[id] = node;
              }
        }
        className="item"
        status={status}
        iconProps={iconProps}
        title={title}
        message={message}
        actions={actions}
        onCloseButtonClick={() => {
          deleteToast(id);
          onClose?.({ closedBy: 'default' });
        }}
        onActionClick={({ closeToastOnClick = false }) => {
          if (closeToastOnClick) {
            deleteToast(id);
            onClose?.({ closedBy: 'action' });
          }
        }}
        prerender={prerender}
        layout={layout}
      />
    );
  };

  useImperativeHandle(ref, () => ({
    addToast: (item) => {
      // prerender a hidden Toast component to know its height and layout.
      const tempElement = document.createElement('div');
      tempElement.setAttribute('style', 'position: absolute; visibility: hidden;');
      document.body.appendChild(tempElement);

      const tempRoot = createRoot(tempElement);
      const unmount = () => {
        window?.setTimeout(() => {
          tempRoot.unmount();
          document.body.removeChild(tempElement);
        });
      };
      const Prerenderer = () => {
        useEffect(() => {
          const callback = async () => {
            // wait until prerender is completed
            const element = await findElement(() => tempElement.querySelector('[data-prerender-height]'));

            if (element) {
              const height = Number(element.dataset.prerenderHeight);
              const layout = element.dataset.prerenderLayout as PrerenderResult['layout'];
              if (!Number.isNaN(height)) {
                setToasts((toasts) => [...toasts, { ...item, height, layout }]);
              }
              unmount();
            }
          };

          callback();
        }, []);

        return renderToast(item, { prerender: true });
      };
      tempRoot.render(<Prerenderer />);
    },
    removeToast: (item: ToastID) => {
      deleteToast(item);
    },
    removeAllToast: () => {
      Object.keys(toastElementRefs.current).forEach((id) => {
        window?.requestAnimationFrame(() => deleteToast(id));
      });
    },
  }));

  useEffect(() => {
    toasts.forEach((toast) => {
      const { id, option } = toast;
      const { autoHide = true, autoHideDuration = defaultAutoHideDuration } = option;

      if (!autoHide) {
        if (autoHideTimerRefs.current[id]) {
          window?.clearTimeout(autoHideTimerRefs.current[id]);
        }
        return;
      }

      if (autoHideTimerRefs.current[id] == null) {
        autoHideTimerRefs.current[id] = window?.setTimeout(() => {
          deleteToast(id);
          option?.onClose?.({ closedBy: 'timeout' });
        }, autoHideDuration);
      }
    });
  }, [deleteToast, toasts]);

  useEffect(() => {
    return () => {
      // clear all timers on unmount
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(autoHideTimerRefs.current).forEach((timerId) => {
        window?.clearTimeout(timerId);
      });
    };
  }, []);

  return (
    <Container containerStyles={containerStyles}>
      <TransitionGroup className="toast-container">
        {toasts.map((item) => {
          return (
            <CSSTransition
              key={item.id}
              in={true}
              timeout={transitionDuration}
              classNames="item"
              onEnter={(node: HTMLElement) => {
                toasts
                  .filter((item) => toastElementRefs.current[item.id] && toastElementRefs.current[item.id] !== node)
                  .reverse()
                  .reduce((bottom, item) => {
                    const refNode = toastElementRefs.current[item.id]!;

                    // shift an existing toast upward quickly - to avoid overlapping with the new toast
                    refNode.setAttribute(
                      'style',
                      buildStyleAttribute({ translateY: -bottom, transitionDuration: transitionDuration / 2 }),
                    );

                    window?.setTimeout(() => {
                      // restore the original transition duration
                      refNode.setAttribute('style', buildStyleAttribute({ translateY: -bottom, transitionDuration }));
                    }, 0);

                    refNode.dataset.bottom = String(bottom);
                    return bottom + refNode.scrollHeight + toastSpacing;
                  }, node.scrollHeight + toastSpacing);
              }}
              onExit={(node: HTMLElement) => {
                toasts
                  .filter((item) => toastElementRefs.current[item.id] && toastElementRefs.current[item.id] !== node)
                  .forEach((item) => {
                    const refNode = toastElementRefs.current[item.id]!;

                    // move down the remaining toasts
                    const bottom = Number(refNode.dataset.bottom) - node.scrollHeight - toastSpacing;
                    refNode.setAttribute('style', buildStyleAttribute({ translateY: -bottom, transitionDuration }));

                    refNode.dataset.bottom = String(bottom);
                  });
              }}
            >
              {renderToast(item, { layout: item.layout })}
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </Container>
  );
});
