import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallP2PSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.317 2.414 13.907 1 10.21 4.707l3.696 3.707L15.317 7l-1.29-1.293h5.156L17.895 7l1.41 1.414L23 4.707 19.305 1l-1.41 1.414 1.289 1.293h-5.156l1.289-1.293z" />
      <path
        fillRule="evenodd"
        d="M19 16.95a3.018 3.018 0 0 0-2.174-2.911l-2.167-.63a3.017 3.017 0 0 0-3.161.965l-1.626 1.952a15.187 15.187 0 0 1-4.197-4.198l1.946-1.623a3.018 3.018 0 0 0 .962-3.173L7.957 5.21a3.017 3.017 0 0 0-2.86-2.163L1 3l.047 1.063c.194 4.36 1.875 8.57 5.098 11.793 3.222 3.222 7.431 4.904 11.79 5.098l1.046.046.019-4.05zm-2.02 1.915a15.988 15.988 0 0 1-5.338-1.522l1.401-1.681c.256-.307.67-.434 1.054-.322l2.167.63c.43.126.727.522.724.971l-.008 1.924zM6.334 8.96l-1.677 1.397a15.989 15.989 0 0 1-1.52-5.32l1.938.022c.44.005.827.297.952.72l.627 2.123c.114.385-.012.8-.32 1.058z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallP2P = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallP2PSvg} {...props} />
));
CallP2P.displayName = 'CallP2P';
export default CallP2P;
