import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrWorkToCompletionSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-wo-a)">
        <path d="M22.007 15h-17V3h17l-4 6 4 6z" />
        <path fillRule="evenodd" d="M3.507 3v18h-1.5V3h1.5z" clipRule="evenodd" />
      </g>
      <defs>
        <clipPath id="br-wo-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrWorkToCompletion = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrWorkToCompletionSvg} {...props} />
));
BrWorkToCompletion.displayName = 'BrWorkToCompletion';
export default BrWorkToCompletion;
