import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CustomersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#custo-a)" clipRule="evenodd">
        <path d="M15.06 19H22V5a2 2 0 0 0-2-2H2v16h7.06L12 22l3.06-3zm-3.031.171L14.244 17H20V6a1 1 0 0 0-1-1H4v12h5.901l2.128 2.171z" />
        <path d="M12 14a2 2 0 0 0-2 2H8a4 4 0 0 1 8 0h-2a2 2 0 0 0-2-2z" />
        <path d="M12 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM9 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0z" />
      </g>
      <defs>
        <clipPath id="custo-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Customers = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CustomersSvg} {...props} />
));
Customers.displayName = 'Customers';
export default Customers;
