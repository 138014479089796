import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const RuleFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 5.5A3.5 3.5 0 0 1 8.965 5H15V2h7v7h-7V7H8.663a3.514 3.514 0 0 1-1.64 1.652A9.002 9.002 0 0 0 15 16.945V15h7v7h-7v-3.045A11.003 11.003 0 0 1 5.042 8.97 3.5 3.5 0 0 1 2 5.5z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const RuleFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={RuleFilledSvg} {...props} />
));
RuleFilled.displayName = 'RuleFilled';
export default RuleFilled;
