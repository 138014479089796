import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const InviteSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM9 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        clipRule="evenodd"
      />
      <path d="M15 11h3v3h2v-3h3V9h-3V6h-2v3h-3v2zM3 20.5a6 6 0 0 1 12 0v.5h2v-.5a8 8 0 1 0-16 0H.996v.5h2v-.5H3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Invite = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={InviteSvg} {...props} />
));
Invite.displayName = 'Invite';
export default Invite;
