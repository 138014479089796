import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PermissionSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 .665a8 8 0 0 0-5 14.246v7.697l5-1.875 5 1.875v-7.697A8 8 0 0 0 12 .666zm-6 8a6 6 0 1 1 12 0 6 6 0 0 1-12 0zm9 7.419a7.978 7.978 0 0 1-3 .581 7.978 7.978 0 0 1-3-.581v3.638l3-1.125 3 1.125v-3.638z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Permission = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PermissionSvg} {...props} />
));
Permission.displayName = 'Permission';
export default Permission;
