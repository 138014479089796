import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeactivateFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM9 12.5a8 8 0 0 1 8 8v.5H.996v-.5H1a8 8 0 0 1 8-8zM15.5 12.1l2.1-2.1-2.1-2.1 1.4-1.4L19 8.6l2.1-2.1 1.4 1.4-2.1 2.1 2.1 2.1-1.4 1.4-2.1-2.1-2.1 2.1-1.4-1.4z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeactivateFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeactivateFilledSvg} {...props} />
));
DeactivateFilled.displayName = 'DeactivateFilled';
export default DeactivateFilled;
