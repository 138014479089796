import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ReportsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 4h-3v16h3V4zM11 5H8v15h3V5zM6 15H3v5h3v-5zM13 10h3v10h-3V10z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ReportsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ReportsFilledSvg} {...props} />
));
ReportsFilled.displayName = 'ReportsFilled';
export default ReportsFilled;
