import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PauseFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path strokeWidth={2} d="M13 7.5H8v9h8v-9h-3zm8 4.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PauseFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PauseFilledSvg} {...props} />
));
PauseFilled.displayName = 'PauseFilled';
export default PauseFilled;
