import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AppleColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0D0D0D"
        fillRule="evenodd"
        d="M15.98 1c.125 1.268-.34 2.542-1.029 3.459h.001c-.69.915-1.82 1.627-2.927 1.533-.15-1.246.409-2.54 1.047-3.352.713-.916 1.915-1.595 2.908-1.64zm4.422 7.248c-.24.152-2.395 1.515-2.368 4.256.03 3.32 2.82 4.469 2.96 4.527.004 0 .005.001.006.002a1.75 1.75 0 0 0-.01.03c-.073.236-.521 1.674-1.521 3.155-.921 1.366-1.876 2.723-3.382 2.75-.724.015-1.208-.197-1.711-.417-.526-.231-1.074-.471-1.939-.471-.906 0-1.479.248-2.03.486-.479.207-.94.407-1.591.433-1.453.055-2.56-1.474-3.49-2.834-1.899-2.78-3.35-7.86-1.4-11.286.965-1.703 2.695-2.781 4.573-2.81.808-.014 1.592.301 2.277.577.525.211.992.4 1.37.4.338 0 .793-.183 1.323-.394.835-.334 1.857-.743 2.909-.634.72.03 2.743.293 4.042 2.218a.979.979 0 0 1-.018.012z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AppleColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AppleColoredSvg} {...props} />
));
AppleColored.displayName = 'AppleColored';
export default AppleColored;
