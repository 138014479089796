import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DocumentTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path id="Color_02" fillRule="evenodd" d="M14.414 2H4v20h16V7.586L14.414 2z" clipRule="evenodd" />
      <path id="Color_01" d="M8 9h4V7H8v2zM8 13h8v-2H8v2zM16 17H8v-2h8v2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DocumentTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DocumentTwotoneSvg} {...props} />
));
DocumentTwotone.displayName = 'DocumentTwotone';
export default DocumentTwotone;
