import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LoactionSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-1 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 2c-4.683 0-8.5 3.752-8.5 8.408 0 1.579.58 2.963 1.343 4.171.72 1.14 1.646 2.189 2.477 3.13L12 23l4.68-5.29c.831-.942 1.758-1.99 2.477-3.13.764-1.21 1.343-2.593 1.343-4.172C20.5 5.752 16.683 2 12 2zm-6.5 8.408C5.5 6.88 8.398 4 12 4s6.5 2.88 6.5 6.408c0 1.071-.39 2.083-1.034 3.104-.631 1-1.461 1.94-2.323 2.916l-.085.096L12 20l-3.058-3.476-.085-.096c-.862-.976-1.692-1.916-2.323-2.916C5.889 12.49 5.5 11.479 5.5 10.408z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Loaction = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LoactionSvg} {...props} />
));
Loaction.displayName = 'Loaction';
export default Loaction;
