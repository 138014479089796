import { createContext } from 'react';

import type { ControllerStateAndHelpers } from 'downshift';

import type { DropdownItem, DropdownProps } from '@feather/types';

interface DropdownContextValue extends ControllerStateAndHelpers<DropdownItem> {
  dropdownProps: DropdownProps<DropdownItem>;
  setInputValue: (value: string) => void;
}

export const DropdownContext = createContext<DropdownContextValue>({} as any);
