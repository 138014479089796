import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LinkedinColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0078D4"
        d="M24 20.667A3.333 3.333 0 0 1 20.667 24H3.333A3.333 3.333 0 0 1 0 20.667V3.333A3.333 3.333 0 0 1 3.333 0h17.334A3.333 3.333 0 0 1 24 3.333v17.334z"
      />
      <path
        fill="#000"
        d="M16 20.667v-6.733c0-1.126-.546-1.798-1.462-1.798-.543 0-.942.306-1.186.909-.011.043-.027.216-.02.742l.001 6.88H8.666V8h4.667v.707C14.014 8.237 14.85 8 15.825 8c3.031 0 4.84 2.062 4.84 5.516l.001 7.15H16zm-12.667 0V8h2.305C4.302 8 3.333 7.02 3.333 5.666c0-1.351.985-2.333 2.343-2.333 1.341 0 2.296.954 2.324 2.32C8 7.016 7.014 8 5.656 8H8v12.667H3.333z"
        opacity={0.05}
      />
      <path
        fill="#000"
        d="M16.334 20.333v-6.4c0-1.315-.688-2.131-1.795-2.131-.863 0-1.29.608-1.495 1.118-.055.133-.048.66-.045.884l.001 6.53H9v-12h4v1.091c.53-.549 1.384-1.092 2.826-1.092 2.822 0 4.507 1.938 4.507 5.183v6.817h-4zm-12.667 0v-12h4v12h-4zM5.638 7.667c-1.142 0-1.971-.842-1.971-2.001 0-1.159.845-2 2.01-2 1.149 0 1.967.82 1.99 1.993 0 1.166-.845 2.008-2.01 2.008h-.019z"
        opacity={0.07}
      />
      <path
        fill="#fff"
        d="M4 8.667h3.333V20H4V8.667zm1.657-1.334h-.019C4.643 7.333 4 6.592 4 5.666 4 4.72 4.663 4 5.676 4c1.014 0 1.639.72 1.657 1.666 0 .925-.643 1.667-1.676 1.667zM20 20h-3.333v-6.066c0-1.465-.817-2.465-2.128-2.465-1.001 0-1.542.674-1.805 1.326-.096.234-.067.879-.067 1.205v6H9.333V8.667h3.334v1.744c.48-.744 1.233-1.744 3.158-1.744 2.386 0 4.174 1.5 4.174 4.849L20 20z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LinkedinColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LinkedinColoredSvg} {...props} />
));
LinkedinColored.displayName = 'LinkedinColored';
export default LinkedinColored;
