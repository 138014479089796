import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';

import type { PropGetters } from 'downshift';
import styled from 'styled-components';

import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import type { DropdownItem, DropdownProps } from '@feather/types';

import { OptionListItem } from './components';
import { defaultItemToString, defaultTransition } from './constants';
import { DropdownContext } from './dropdownContext';
import { isDropdownItemEqual } from './isDropdownItemEqual';

type Props<T extends DropdownItem> = {
  item: T;
  index: number;
  itemProps: ReturnType<PropGetters<T>['getItemProps']>;
  selectedItem: T | null;
  highlightedIndex: number | null;
  itemToString?: DropdownProps<T>['itemToString'];
  itemToElement?: DropdownProps<T>['itemToElement'];
  style?: CSSProperties;
};

const MenuItem = styled(OptionListItem)`
  position: relative;
  padding-right: 44px;
`;

const CheckIcon = styled(Icons.Done)`
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -10px;

  transition: ${defaultTransition.css};
  transition-property: opacity;
`;

export const DropdownMenuItem = <T extends DropdownItem>({
  item,
  index,
  itemProps,
  selectedItem,
  highlightedIndex,
  itemToString = defaultItemToString,
  itemToElement,
  style,
}: Props<T>) => {
  const isSelected = selectedItem != null && isDropdownItemEqual(selectedItem, item);
  const checkIcon = useMemo(() => <CheckIcon size={20} color={cssVariables('purple-7')} />, []);

  return (
    <MenuItem {...itemProps} isSelected={isSelected} isHighlighted={highlightedIndex === index} style={style}>
      {itemToElement ? itemToElement(item, isSelected) : itemToString(item)}
      {isSelected && checkIcon}
    </MenuItem>
  );
};

export const DropdownMenuItemWithDropdownContext = <T extends DropdownItem>(
  props: Pick<Props<T>, 'item' | 'index' | 'style'>,
) => {
  const {
    getItemProps,
    selectedItem,
    highlightedIndex,
    itemToString,
    dropdownProps: { itemToElement, isItemDisabled = () => false, itemProps: externalItemProps },
  } = useContext(DropdownContext);

  const { item, index } = props;
  const itemProps = getItemProps({ item, index, disabled: isItemDisabled(item), ...externalItemProps });
  const propsFromContext = {
    itemProps,
    selectedItem,
    highlightedIndex,
    itemToString,
    itemToElement,
    isItemDisabled,
  };

  return <DropdownMenuItem<T> {...props} {...propsFromContext} />;
};
