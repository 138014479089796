import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallCanceledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m15.833 3.417 1.75-1.75 1.167 1.167L17 4.584l1.75 1.75L17.584 7.5l-1.75-1.75-1.75 1.75-1.168-1.166 1.75-1.75-1.75-1.75 1.168-1.167 1.75 1.75zM14.626 11.876c.718.21 1.21.87 1.208 1.618l-.019 4.006-.871-.038c-3.632-.162-7.14-1.563-9.824-4.249C2.435 10.527 1.034 7.02.873 3.386L.833 2.5l4.033.047c.736.008 1.38.496 1.588 1.201l.854 2.891c.19.642-.02 1.335-.535 1.763l-2.045 1.705a12.661 12.661 0 0 0 3.497 3.498l1.706-2.048a1.676 1.676 0 0 1 1.756-.536l2.939.855z" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const CallCanceled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallCanceledSvg} {...props} />
));
CallCanceled.displayName = 'CallCanceled';
export default CallCanceled;
