import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LayoutListViewFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M22 4H12v16h7.5a2.5 2.5 0 0 0 2.5-2.5V4z" />
      <path fillRule="evenodd" d="M10 7H2V5h8v2zM10 13H2v-2h8v2zM10 19H2v-2h8v2z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LayoutListViewFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LayoutListViewFilledSvg} {...props} />
));
LayoutListViewFilled.displayName = 'LayoutListViewFilled';
export default LayoutListViewFilled;
