import { useCallback, useEffect, useRef, useState } from 'react';

import { Time } from '@internationalized/date';
import { useSelect } from 'downshift';
import type { PopperChildrenProps, PopperProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import styled, { css } from 'styled-components';

import { IconButton } from '@feather/components/button';
import { DropdownToggleButton } from '@feather/components/dropdown';
import { defaultTransition } from '@feather/components/dropdown/constants';
import * as Icons from '@feather/components/icons';
import { elevation } from '@feather/elevation';
import { cssVariables } from '@feather/theme';
import { Body } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

import { ScrollBar } from '../ScrollBar';
import { ToggleText } from './ToggleText';
import type { TimePickerCommonProps } from './types';
import { defaultTimeFormatter } from './utils';

const ButtonWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  ${({ disabled }) =>
    disabled
      ? ''
      : css`
          &:hover {
            svg {
              fill: ${cssVariables('content-primary')};
            }
          }
        `}
`;

const ClearButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

const ToggleButton = styled(DropdownToggleButton)<{ hasError?: boolean }>`
  min-width: 100px;
  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${cssVariables('red-5')};
    `}

  &:hover ${ClearButton} {
    svg {
      fill: ${cssVariables('content-primary')};
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const PopperContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 160px;
  height: 150px;
  border-radius: 4px;
  ${elevation.popover};
  z-index: ${ZIndexes.dropdownMenu};
  overflow: hidden;
  background: #ffffff;
`;

const HourMinuteListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const HourMinuteList = styled.ul<{ isHour?: boolean }>`
  margin: 0;
  padding: 0;
  ${({ isHour }) =>
    isHour &&
    css`
      border-right: 1px solid ${cssVariables('border-3')};
    `}
`;

const HourMinuteItem = styled.li<{ disabled?: boolean; isSelected?: boolean }>`
  list-style: none;
  padding: 6px 16px;
  cursor: pointer;
  ${Body['body-short-01']};

  &[disabled] {
    cursor: not-allowed;
    color: ${cssVariables('neutral-5')};
  }

  &:hover {
    background-color: ${cssVariables('neutral-1')};
  }

  ${(props) =>
    props.isSelected &&
    css`
      &,
      &:hover {
        background-color: ${cssVariables('bg-primary-light')};
        color: ${cssVariables('content-primary')};
        transition: ${defaultTransition.css};
      }
    `}
`;

type TimePickerProps = TimePickerCommonProps & {
  className?: string;
  time?: Time;
  onChange: (time?: Time) => void;
  placeholder?: string;
  popperProps?: Partial<PopperProps>;
  hasError?: boolean;
};
const TimePicker = ({
  className,
  time,
  disabled,
  formatTime = defaultTimeFormatter,
  hasError = false,
  minTime,
  maxTime,
  onChange,
  placement = 'bottom-start',
  placeholder = '—',
  popperProps,
  size = 'medium',
}: TimePickerProps) => {
  const { getMenuProps, getToggleButtonProps, isOpen } = useSelect({ items: [] });
  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();

  useEffect(() => {
    scheduleUpdateRef.current?.();
  }, [time, size]);

  const toggleButtonProps = getToggleButtonProps();
  const { ref: menuPropsRef, ...menuProps } = getMenuProps();

  const [hour, setHour] = useState<number | null>(null);
  const [minute, setMinute] = useState<number | null>(null);

  const isHourDisabled = useCallback(
    (hour: number) => (minTime && minTime.hour > hour) || (maxTime && maxTime.hour < hour),
    [maxTime, minTime],
  );
  const isMinuteDisabled = useCallback(
    (minute: number) =>
      hour === null ||
      (minTime && ((hour !== null && minTime.hour > hour) || (minTime.hour === hour && minTime.minute > minute))) ||
      (maxTime && ((hour !== null && maxTime.hour < hour) || (maxTime.hour === hour && maxTime.minute < minute))),
    [hour, maxTime, minTime],
  );

  useEffect(() => {
    if (time) {
      setHour(time.hour);
      setMinute(time.minute);
    } else {
      setHour(null);
      setMinute(null);
    }
  }, [time]);

  const hourListRef = useRef<HTMLUListElement>(null);
  const scrollIntoHour = (hour: number) => {
    hourListRef.current?.querySelector(`[data-hour="${hour}"]`)?.scrollIntoView({ block: 'nearest' });
  };
  const minuteListRef = useRef<HTMLUListElement>(null);
  const scrollIntoMinute = (minute: number) => {
    minuteListRef.current?.querySelector(`[data-minute="${minute}"]`)?.scrollIntoView({ block: 'nearest' });
  };

  const handleChange = (hour: number, minute: number) => {
    let newTime = new Time(hour, minute, 0, 0);
    if (minTime && newTime.compare(minTime) < 0) {
      newTime = newTime.set({ hour: minTime.hour, minute: minTime.minute });
    }
    if (maxTime && maxTime.compare(newTime) < 0) {
      newTime = newTime.set({ hour: maxTime.hour, minute: maxTime.minute });
    }
    setHour(newTime.hour);
    setMinute(newTime.minute);
    onChange(newTime);
  };

  const handleClickHour = (hour: number) => {
    if (isHourDisabled(hour)) {
      return;
    }
    setHour(hour);
    if (minute !== null) {
      handleChange(hour, minute);
      scrollIntoHour(hour);
    }
  };

  const handleClickMinute = (minute: number) => {
    if (isMinuteDisabled(minute)) {
      return;
    }
    setMinute(minute);
    if (hour !== null) {
      handleChange(hour, minute);
      scrollIntoMinute(minute);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (hour !== null) {
        scrollIntoHour(hour);
      }
      if (minute !== null) {
        scrollIntoMinute(minute);
      }
    }
  }, [hour, isOpen, minute]);

  const isTimeValueVisible = time || hour !== null || minute !== null;
  const showClearButton = isTimeValueVisible && !disabled;
  return (
    <>
      <Manager>
        <Reference innerRef={toggleButtonProps.ref}>
          {({ ref }) => {
            return (
              <ButtonWrapper disabled={disabled}>
                <ToggleButton
                  {...toggleButtonProps}
                  className={className}
                  disabled={disabled}
                  hasError={hasError}
                  isPlaceholder={!isTimeValueVisible}
                  ref={ref}
                  size={size}
                  variant="default"
                  showArrow={false}
                  aria-pressed={isOpen}
                >
                  <ToggleText size={size}>
                    {time
                      ? formatTime(time)
                      : `${
                          hour === null && minute === null
                            ? placeholder
                            : `${hour === null ? placeholder : hour}:${
                                minute === null ? placeholder : minute.toString().padStart(2, '0')
                              }`
                        }`}
                  </ToggleText>
                  {!showClearButton && (
                    <IconWrapper>
                      <Icons.Time
                        color={`${
                          disabled
                            ? cssVariables('content-disabled')
                            : `${isOpen ? cssVariables('content-primary') : cssVariables('content-1')}`
                        }`}
                        size={16}
                      />
                    </IconWrapper>
                  )}
                </ToggleButton>
                {showClearButton && (
                  <ClearButton
                    icon={Icons.Close}
                    aria-label="Clear value"
                    buttonType={isOpen ? 'primary' : 'secondary'}
                    onClick={() => onChange(undefined)}
                    size="xsmall"
                  />
                )}
              </ButtonWrapper>
            );
          }}
        </Reference>

        <Popper placement={placement} {...popperProps} innerRef={menuPropsRef}>
          {({ ref, style, scheduleUpdate }) => {
            scheduleUpdateRef.current = scheduleUpdate;
            return (
              <PopperContentWrapper
                {...menuProps}
                ref={ref}
                style={{ ...style, ...(isOpen ? null : { opacity: 0, pointerEvents: 'none' }) }}
              >
                <HourMinuteListWrapper>
                  <ScrollBar>
                    <HourMinuteList ref={hourListRef} isHour={true}>
                      {Array(24)
                        .fill(0)
                        .map((_, i) => {
                          const disabled = isHourDisabled(i);
                          return (
                            <HourMinuteItem
                              key={i}
                              disabled={disabled}
                              isSelected={i === hour}
                              onClick={() => handleClickHour(i)}
                              data-hour={i}
                            >
                              {i}
                            </HourMinuteItem>
                          );
                        })}
                    </HourMinuteList>
                  </ScrollBar>
                </HourMinuteListWrapper>
                <HourMinuteListWrapper>
                  <ScrollBar>
                    <HourMinuteList ref={minuteListRef}>
                      {Array(60)
                        .fill(0)
                        .map((_, i) => {
                          const disabled = isMinuteDisabled(i);
                          return (
                            <HourMinuteItem
                              key={i}
                              disabled={disabled}
                              isSelected={i === minute}
                              onClick={() => handleClickMinute(i)}
                              data-minute={i}
                            >
                              {String(i).padStart(2, '0')}
                            </HourMinuteItem>
                          );
                        })}
                    </HourMinuteList>
                  </ScrollBar>
                </HourMinuteListWrapper>
              </PopperContentWrapper>
            );
          }}
        </Popper>
      </Manager>
    </>
  );
};

export default TimePicker;
