import type { AnchorHTMLAttributes } from 'react';

import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom';

import type { FeatherIconComponent, FeatherIconProps } from '@feather/components/icons/types';

export enum LinkVariant {
  Default = 'default',
  Inline = 'inline',
  Neutral = 'neutral',
  Mono = 'mono',
}

export interface LinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  href?: string | ReactRouterLinkProps['to'];
  variant?: LinkVariant;
  iconProps?: { icon: FeatherIconComponent } & Pick<FeatherIconProps, 'size'>;
  useReactRouter?: boolean;
  disabled?: boolean;
}
