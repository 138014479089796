import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BusinessMessagingHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m7.13 14.94-4.829-1.728a.678.678 0 0 1-.067-1.249l16.752-8.11-1.954 15.296a.678.678 0 0 1-.991.512L8.46 15.605l7.476-8.192-8.805 7.527zM7.782 21.029V16.96l4.066 2.26-4.066 1.808zM59.747 5.563a1.73 1.73 0 0 1-1.717 1.718 1.73 1.73 0 0 1-1.717-1.718c0-.939.778-1.698 1.717-1.698.938 0 1.717.778 1.717 1.698z" />
      <path
        fillRule="evenodd"
        d="M35.405 12.983c0 2.817-2.017 5.114-4.553 5.114-1.359 0-2.417-.56-3.096-1.578v1.378h-2.815V4.357h2.816v5.109c.68-1.02 1.738-1.578 3.096-1.578 2.536 0 4.553 2.277 4.553 5.095zm-2.756 0a2.417 2.417 0 0 0-2.437-2.418c-1.358 0-2.456 1.059-2.456 2.418 0 1.359 1.078 2.417 2.456 2.417s2.437-1.058 2.437-2.417z"
        clipRule="evenodd"
      />
      <path d="M46.09 17.897v-9.81h-2.817v5.174c0 1.3-.76 2.138-1.937 2.138-1.178 0-1.937-.839-1.937-2.138V8.087h-2.816v5.534c0 2.777 1.519 4.476 4.034 4.476 1.139 0 2.057-.54 2.656-1.5v1.3h2.816zM49.803 14.58H47.11c.2 2.217 1.977 3.517 4.533 3.517 2.216 0 3.914-1.379 3.914-3.177 0-2.546-2.137-2.892-3.755-3.153-.985-.16-1.777-.288-1.777-.862 0-.46.48-.68 1.118-.68.9 0 1.439.44 1.478.94h2.835c-.239-2.178-2.136-3.277-4.253-3.277-2.416 0-3.895 1.398-3.895 3.216 0 2.196 1.988 2.555 3.576 2.841 1.056.19 1.936.35 1.936.996 0 .46-.48.74-1.318.74-.939 0-1.638-.441-1.698-1.1zM59.448 17.897v-9.81h-2.816v9.81h2.816zM70.55 17.898v-5.556c0-2.776-1.518-4.454-4.034-4.454-1.118 0-2.036.539-2.656 1.478V8.087h-2.816v9.811h2.816v-5.194c0-1.28.779-2.118 1.937-2.118 1.159 0 1.937.839 1.937 2.118v5.194h2.816z" />
      <path
        fillRule="evenodd"
        d="M74.284 13.662h7.289l.001.001c.04-.32.06-.779.06-.999 0-2.697-2.178-4.775-4.833-4.775-2.856 0-5.093 2.157-5.093 5.054 0 2.897 2.257 5.155 5.152 5.155 2.458 0 4.315-1.38 4.634-3.497h-2.756c-.16.68-.859 1.16-1.958 1.16-1.319 0-2.297-.82-2.496-2.099zm2.396-3.497c1.039 0 1.937.7 2.017 1.699h-4.274c.34-1.16 1.218-1.699 2.257-1.699z"
        clipRule="evenodd"
      />
      <path d="M84.867 14.58h-2.694c.2 2.217 1.977 3.517 4.533 3.517 2.216 0 3.914-1.379 3.914-3.177 0-2.546-2.138-2.892-3.755-3.153-.985-.16-1.777-.288-1.777-.862 0-.46.479-.68 1.118-.68.9 0 1.439.44 1.478.94h2.835c-.239-2.178-2.137-3.277-4.253-3.277-2.417 0-3.895 1.398-3.895 3.216 0 2.196 1.988 2.555 3.576 2.841 1.056.19 1.936.35 1.936.996 0 .46-.48.74-1.318.74-.939 0-1.638-.441-1.698-1.1zM93.654 14.58H90.96c.2 2.217 1.977 3.517 4.534 3.517 2.215 0 3.913-1.379 3.913-3.177 0-2.546-2.137-2.892-3.754-3.153-.985-.16-1.777-.288-1.777-.862 0-.46.48-.68 1.118-.68.899 0 1.438.44 1.478.94h2.835c-.24-2.178-2.137-3.277-4.253-3.277-2.417 0-3.895 1.398-3.895 3.216 0 2.196 1.987 2.555 3.575 2.841 1.057.19 1.936.35 1.936.996 0 .46-.48.74-1.317.74-.94 0-1.639-.441-1.698-1.1zM119.514 17.898v-6.095c0-2.377-1.678-3.915-4.014-3.915-1.239 0-2.378.599-2.956 1.437-.599-1.018-1.497-1.437-2.776-1.437-.898 0-2.056.299-2.795 1.378V8.087h-2.816v9.811h2.816v-5.435c0-1.278.819-1.878 1.737-1.878 1.039 0 1.698.72 1.698 1.878v5.435h2.816v-5.435c0-1.258.838-1.878 1.777-1.878 1.019 0 1.678.72 1.678 1.878v5.435h2.835z" />
      <path
        fillRule="evenodd"
        d="M123.247 13.662h7.289l.001.001c.04-.32.06-.779.06-.999 0-2.697-2.177-4.775-4.833-4.775-2.856 0-5.093 2.157-5.093 5.054 0 2.897 2.257 5.155 5.153 5.155 2.456 0 4.314-1.38 4.633-3.497h-2.756c-.16.68-.859 1.16-1.957 1.16-1.319 0-2.297-.82-2.497-2.099zm2.396-3.497c1.039 0 1.937.7 2.017 1.699h-4.273c.339-1.16 1.217-1.699 2.256-1.699z"
        clipRule="evenodd"
      />
      <path d="M133.83 14.58h-2.694c.2 2.217 1.977 3.517 4.533 3.517 2.216 0 3.914-1.379 3.914-3.177 0-2.546-2.137-2.892-3.755-3.153-.984-.16-1.776-.288-1.776-.862 0-.46.478-.68 1.118-.68.899 0 1.438.44 1.478.94h2.835c-.24-2.178-2.137-3.277-4.254-3.277-2.416 0-3.894 1.398-3.894 3.216 0 2.196 1.987 2.555 3.575 2.841 1.056.19 1.936.35 1.936.996 0 .46-.479.74-1.318.74-.938 0-1.638-.441-1.698-1.1zM142.618 14.58h-2.694c.2 2.217 1.976 3.517 4.533 3.517 2.216 0 3.914-1.379 3.914-3.177 0-2.546-2.138-2.892-3.755-3.153-.985-.16-1.777-.288-1.777-.862 0-.46.48-.68 1.118-.68.899 0 1.439.44 1.478.94h2.835c-.239-2.178-2.137-3.277-4.253-3.277-2.417 0-3.895 1.398-3.895 3.216 0 2.196 1.988 2.555 3.576 2.841 1.056.19 1.936.35 1.936.996 0 .46-.48.74-1.318.74-.939 0-1.638-.441-1.698-1.1z" />
      <path
        fillRule="evenodd"
        d="M159.392 17.897v-9.81h-2.816v1.399c-.678-1.02-1.738-1.598-3.115-1.598-2.536 0-4.554 2.278-4.554 5.095 0 2.816 2.018 5.114 4.554 5.114 1.377 0 2.436-.58 3.115-1.599v1.399h2.816zm-5.272-7.332c1.377 0 2.456 1.059 2.456 2.418 0 1.379-1.099 2.417-2.456 2.417s-2.456-1.079-2.456-2.418c0-1.338 1.078-2.417 2.456-2.417zM171.033 17.477v-9.41h-2.814v1.359c-.678-.999-1.738-1.538-3.115-1.538-2.536 0-4.554 2.217-4.554 4.914 0 2.696 2.018 4.914 4.554 4.914 1.377 0 2.436-.54 3.115-1.538v1.419c0 1.198-1.139 1.997-2.357 1.997-1.118 0-2.117-.439-2.197-1.318h-2.816c.18 2.997 3.295 3.656 5.112 3.656 2.835 0 5.072-1.678 5.072-4.455zm-5.272-7.013c1.378 0 2.457 1.02 2.457 2.338s-1.098 2.317-2.457 2.317c-1.358 0-2.456-1.018-2.456-2.317 0-1.3 1.079-2.338 2.456-2.338z"
        clipRule="evenodd"
      />
      <path d="M175.427 17.897v-9.81h-2.816v9.81h2.816zM183.713 17.898h2.816v-5.556c0-2.776-1.518-4.454-4.034-4.454-1.118 0-2.037.539-2.656 1.478V8.087h-2.816v9.811h2.816v-5.194c0-1.28.778-2.118 1.937-2.118s1.937.839 1.937 2.118v5.194z" />
      <path
        fillRule="evenodd"
        d="M198.171 17.477v-9.41h-2.815v1.359c-.678-.999-1.737-1.538-3.115-1.538-2.536 0-4.553 2.217-4.553 4.914 0 2.696 2.017 4.914 4.553 4.914 1.378 0 2.436-.54 3.115-1.538v1.419c0 1.198-1.138 1.997-2.357 1.997-1.118 0-2.116-.439-2.196-1.318h-2.816c.179 2.997 3.294 3.656 5.112 3.656 2.835 0 5.072-1.678 5.072-4.455zm-5.272-7.013c1.377 0 2.456 1.02 2.456 2.338s-1.099 2.317-2.456 2.317-2.456-1.018-2.456-2.317c0-1.3 1.078-2.338 2.456-2.338z"
        clipRule="evenodd"
      />
      <path d="M175.726 5.563a1.73 1.73 0 0 1-1.718 1.718 1.73 1.73 0 0 1-1.717-1.718c0-.939.779-1.698 1.717-1.698.939 0 1.718.778 1.718 1.698z" />
    </svg>
  )),
  { width: 200, height: 24 },
);
const BusinessMessagingHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BusinessMessagingHorizontalSvg} {...props} />
));
BusinessMessagingHorizontal.displayName = 'BusinessMessagingHorizontal';
export default BusinessMessagingHorizontal;
