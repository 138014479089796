import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SettingsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm1 3a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M14.688 2H9.311v2.729c-.188.07-.372.146-.553.23l-1.93-1.93-3.8 3.8 1.93 1.93c-.084.18-.16.365-.23.553H2v5.376h2.729c.07.188.146.372.23.553l-1.93 1.93 3.8 3.8 1.93-1.93c.18.084.365.16.553.23V22h5.376v-2.729c.188-.07.372-.146.553-.23l1.93 1.93 3.8-3.8-1.93-1.93c.084-.18.16-.365.23-.553H22V9.311h-2.729a7.76 7.76 0 0 0-.23-.553l1.93-1.93-3.8-3.8-1.93 1.93a7.718 7.718 0 0 0-.553-.23V2zm-3.376 4.238V4h1.376v2.238l.75.193a5.71 5.71 0 0 1 1.482.614l.668.395 1.583-1.583.972.972-1.583 1.583.395.668c.27.457.478.955.614 1.482l.193.75H20v1.376h-2.238l-.193.75a5.71 5.71 0 0 1-.614 1.482l-.395.668 1.583 1.583-.972.972-1.583-1.583-.668.395a5.71 5.71 0 0 1-1.482.614l-.75.193V20h-1.376v-2.238l-.75-.193a5.71 5.71 0 0 1-1.482-.614l-.668-.395-1.583 1.583-.972-.972 1.583-1.583-.395-.668a5.71 5.71 0 0 1-.614-1.482l-.193-.75H4v-1.376h2.238l.193-.75a5.71 5.71 0 0 1 .614-1.482l.395-.668L5.857 6.83l.972-.972L8.412 7.44l.668-.395a5.71 5.71 0 0 1 1.482-.614l.75-.193z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Settings = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SettingsSvg} {...props} />
));
Settings.displayName = 'Settings';
export default Settings;
