import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MuteFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.58 17.995 21.587 23 23 21.586 2.418 1.004 1.004 2.418l1.168 1.168A2.494 2.494 0 0 0 2 4.5V22l4.005-4.005H16.58zM21.375 17.148A2.49 2.49 0 0 0 22 15.495V4.5A2.5 2.5 0 0 0 19.5 2H6.227l15.148 15.148z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MuteFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MuteFilledSvg} {...props} />
));
MuteFilled.displayName = 'MuteFilled';
export default MuteFilled;
