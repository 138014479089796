import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HaweiColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FE0000"
        d="M12.798 16.411s-.037.069-.107.04c0 0-.049-.012-.056-.08h-.002C11.57 5.802 13.757 3 13.757 3c.597.085 1.16.226 1.16.226 1.957.474 2.438 2.266 2.438 2.266.34.939-.01 2.33-.01 2.33-.65 2.866-3.84 7.57-4.544 8.59h-.003zM10.351 16.952a.075.075 0 0 1-.02.087c-.03.04-.084.024-.098.017-1.029-.508-5.46-2.748-7.293-4.524 0 0-1.135-.89-1.215-2.393-.15-1.985 1.95-3.967 1.95-3.967s3.857 4.65 6.678 10.78h-.002zM9.55 18.492s.079 0 .098.066c0 0 .019.04-.036.09l.001.003c-.725.516-2.917 2.038-3.887 2.279-1.403.337-2.752-.765-3.551-2.185l7.376-.256v.003zM9.756 17.898c.038-.058-.026-.109-.026-.109h.002C6.492 15.613.21 12.275.21 12.275c-.571 1.758.198 3.174.198 3.174.8 1.684 2.328 2.193 2.328 2.193.705.29 1.41.309 1.41.309.112.019 4.39.002 5.536-.003.049 0 .073-.05.073-.05zM14.354 18.552c.018-.048.084-.057.084-.057l7.388.256c-1.659 2.83-3.565 2.186-3.565 2.186-1.032-.291-3.174-1.78-3.879-2.284v-.002c-.043-.033-.028-.099-.028-.099zM13.682 17.04s.05.037.098.008c1.057-.521 5.456-2.75 7.278-4.516 0 0 1.151-.92 1.212-2.402.132-2.055-1.945-3.958-1.945-3.958s-3.846 4.631-6.667 10.75h.002s-.032.071.022.117zM23.787 12.258s-6.27 3.35-9.514 5.524l.002.002s-.058.038-.04.106c0 0 .033.057.078.057 1.16.002 5.555.006 5.667-.013 0 0 .568-.023 1.268-.292 0 0 1.563-.494 2.376-2.26 0 0 .724-1.44.163-3.124zM9.06 3.226S9.926 3.03 10.248 3c0 0 2.175 2.801 1.114 13.364 0 0 .005.07-.07.09 0 0-.04.018-.087-.032-.69-.992-3.899-5.723-4.55-8.6 0 0-.367-1.22-.01-2.33 0 0 .462-1.764 2.415-2.266z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const HaweiColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HaweiColoredSvg} {...props} />
));
HaweiColored.displayName = 'HaweiColored';
export default HaweiColored;
