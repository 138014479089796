import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AnalyticsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 7c0 .723-.256 1.387-.682 1.905l3.087 5.154a3.017 3.017 0 0 1 .966-.036l2.47-4.94a3 3 0 1 1 1.789.895l-2.47 4.94a3 3 0 1 1-4.47.169L9.606 9.938a3.014 3.014 0 0 1-.977.038l-2.47 4.94a3 3 0 1 1-1.789-.895l2.47-4.94A3 3 0 1 1 12 7z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AnalyticsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AnalyticsFilledSvg} {...props} />
));
AnalyticsFilled.displayName = 'AnalyticsFilled';
export default AnalyticsFilled;
