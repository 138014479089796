import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ReplyTemplateFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.5 2h-15A2.5 2.5 0 0 0 2 4.5V22l4.005-4.005H19.5a2.5 2.5 0 0 0 2.5-2.5V4.5A2.5 2.5 0 0 0 19.5 2zm-4.719 12.09L12 12.405l-2.781 1.687.738-3.179L7.5 8.774l3.236-.276L12 5.5l1.264 2.998 3.236.276-2.457 2.138.738 3.179z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ReplyTemplateFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ReplyTemplateFilledSvg} {...props} />
));
ReplyTemplateFilled.displayName = 'ReplyTemplateFilled';
export default ReplyTemplateFilled;
