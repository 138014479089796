import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrRestAndRejuvenateSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.29 4.396a1.977 1.977 0 0 1 1.456-1.58 2.035 2.035 0 0 1 2.07.657c4.174-.24 8.087 2.403 9.212 6.502L3.64 14.525c-1.125-4.1.911-8.298 4.649-10.13z" />
      <path
        fillRule="evenodd"
        d="m14.983 19.227-1.666-6.318-1.449.395 1.408 5.339a6.641 6.641 0 0 0-4.624.786A6.607 6.607 0 0 0 5.3 18.52c-1.197 0-2.323.32-3.297.878v1.84A5.14 5.14 0 0 1 5.3 20.044c1.088 0 2.095.337 2.93.915l.422.291.422-.292a5.13 5.13 0 0 1 2.929-.914 5.13 5.13 0 0 1 2.93.915l.421.291.422-.292a5.13 5.13 0 0 1 2.93-.914 5.14 5.14 0 0 1 3.297 1.194v-1.84a6.605 6.605 0 0 0-3.298-.878c-1.22 0-2.364.331-3.35.91a6.661 6.661 0 0 0-.372-.203z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrRestAndRejuvenate = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrRestAndRejuvenateSvg} {...props} />
));
BrRestAndRejuvenate.displayName = 'BrRestAndRejuvenate';
export default BrRestAndRejuvenate;
