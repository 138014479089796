import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ExportSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m8.293 5.703 1.414 1.414L11 5.824v12.237h2V5.824l1.293 1.293 1.414-1.414L12 1.996 8.293 5.703z" />
      <path d="M7.5 9H2v10.5A2.5 2.5 0 0 0 4.5 22h15a2.5 2.5 0 0 0 2.5-2.5V9h-6v2h4v8.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5V11h3.5V9z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Export = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ExportSvg} {...props} />
));
Export.displayName = 'Export';
export default Export;
