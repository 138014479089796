import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrDocsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-do-a)" clipRule="evenodd">
        <path d="M14.443 3.5H6V2h9.043L21 7.679V19h-1.5V8.321L14.443 3.5z" />
        <path d="M4 5h9.5L18 9.5V19a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5zm8 6h4.5L12 6.5V11z" />
      </g>
      <defs>
        <clipPath id="br-do-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrDocs = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrDocsSvg} {...props} />
));
BrDocs.displayName = 'BrDocs';
export default BrDocs;
