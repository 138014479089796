import isEqualWith from 'lodash/isEqualWith';

import type { DropdownItem } from '@feather/types';

export const isDropdownItemEqual = (a: DropdownItem, b: DropdownItem) => {
  return isEqualWith(a, b, (left, right, index) => {
    if (index !== undefined && typeof left === 'function' && typeof right === 'function') {
      return true;
    }
  });
};
