import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OngoingSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 3c-.4 0-.792.026-1.177.076l-.258-1.983C11.035 1.032 11.514 1 12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12c0-.486.032-.965.093-1.435l1.983.258A9 9 0 1 0 12 3z" />
      <path d="M8.555 3.683a8.99 8.99 0 0 0-2.034 1.176L5.303 3.273a10.99 10.99 0 0 1 2.486-1.438l.766 1.848zM4.859 6.521a8.99 8.99 0 0 0-1.176 2.034L1.835 7.79a10.99 10.99 0 0 1 1.438-2.486L4.86 6.52zM13 6v5.382l3.447 1.724-.894 1.788L11 12.618V6h2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Ongoing = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OngoingSvg} {...props} />
));
Ongoing.displayName = 'Ongoing';
export default Ongoing;
