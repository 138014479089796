import type { ReactNode } from 'react';

import type { PrimitiveColor } from '@feather/types';

export enum BadgeVariant {
  Light = 'light',
  Dark = 'dark',
}

export interface BadgeProps {
  count?: number;
  color: PrimitiveColor;

  /**
   * Count over max value will be displayed as [max]+. (e.g. count: 1000, max: 999 → displayed as 999+)
   * Default value: 999
   */
  max?: number;
  variant?: BadgeVariant;
  className?: string;
  isRemovable?: boolean;
  isLoading?: boolean;
  prefixNode?: ReactNode;
  onClose?: () => void;
}
