import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrGamingSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-ga-a)">
        <path
          fillRule="evenodd"
          d="M11.31 19h3.364a6 6 0 1 0 3.318-11h-10a6 6 0 1 0 3.318 11zm-2.568-5.75V11.5h-1.5v1.75h-1.75v1.5h1.75v1.75h1.5v-1.75h1.75v-1.5h-1.75zm11.25.75a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
          clipRule="evenodd"
        />
        <path d="M21.992 4h-7a1.5 1.5 0 0 0-1.5 1.5v1h-1.5v.003V5.5a3 3 0 0 1 3-3h7V4z" />
      </g>
      <defs>
        <clipPath id="br-ga-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrGaming = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrGamingSvg} {...props} />
));
BrGaming.displayName = 'BrGaming';
export default BrGaming;
