import type { ReactNode } from 'react';

import type { FeatherThemeConfig } from '@feather/types';

import ColorModeProvider, { useColorMode } from './ColorModeProvider';
import FeatherThemeProvider from './FeatherThemeProvider';
import getThemeVariables from './utils/getThemeVariables';

interface FeatherProviderProps {
  config?: FeatherThemeConfig;
  cssVariablesRoot?: string;
  children: ReactNode;
}

type ThemeProviderProps = FeatherProviderProps;

const ThemeProvider = ({ config, cssVariablesRoot, children }: ThemeProviderProps) => {
  const { colorMode } = useColorMode();
  const theme = getThemeVariables(config);
  return (
    <FeatherThemeProvider
      theme={{
        ...theme,
        colorMode,
      }}
      cssVariablesRoot={cssVariablesRoot}
    >
      {children}
    </FeatherThemeProvider>
  );
};

export const FeatherProvider = ({ config, cssVariablesRoot, children }: FeatherProviderProps) => {
  return (
    <ColorModeProvider>
      <ThemeProvider config={config} cssVariablesRoot={cssVariablesRoot}>
        {children}
      </ThemeProvider>
    </ColorModeProvider>
  );
};
