import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ThemeFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13.167 3.5H3.5v9h17v-9h-4.667L14.5 6.707 13.167 3.5zM20.5 14.5h-17v1c0 1.043.701 1.955 1.709 2.224l5.291 1.41V21a1.5 1.5 0 1 0 3 0v-1.865l5.291-1.411A2.301 2.301 0 0 0 20.5 15.5v-1z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ThemeFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ThemeFilledSvg} {...props} />
));
ThemeFilled.displayName = 'ThemeFilled';
export default ThemeFilled;
