import type { InputHTMLAttributes, MutableRefObject, ReactNode } from 'react';
import { forwardRef, useRef } from 'react';

import styled, { css } from 'styled-components';

import { transitions } from '@feather/animation';
import { focusRing } from '@feather/mixins';
import cssVariables from '@feather/theme/cssVariables';
import { Body } from '@feather/typography';

const RadioLabelText = styled.span`
  ${Body['body-short-01']};
  margin-left: 8px;
`;

const borderWithInnerOffset = (color: string) => css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 1px;
    border-radius: 50%;
    border: 2px solid ${color};
  }
`;

const Input = styled.input<{ hasError: boolean }>`
  -webkit-appearance: none;
  position: relative;
  transition: ${transitions({ duration: 0.2, properties: ['background-color', 'border-color', 'box-shadow'] })};
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  background-color: white;
  width: 20px;
  height: 20px;
  ${(props) => borderWithInnerOffset(cssVariables(props.hasError ? 'border-negative' : 'neutral-7'))};

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ${transitions({ duration: 0.2, properties: ['opacity'] })};
    opacity: 0;
    border-radius: 4px;
    background: ${cssVariables('purple-7')};
    width: 8px;
    height: 8px;
    content: '';
    pointer-events: none;
  }

  &:checked {
    ${borderWithInnerOffset(cssVariables('purple-7'))};

    &:before {
      opacity: 1;
    }
  }

  &:disabled {
    ${borderWithInnerOffset(cssVariables('neutral-4'))};

    &:checked {
      ${borderWithInnerOffset(cssVariables('bg-primary-disabled'))};

      &:before {
        background: ${cssVariables('bg-primary-disabled')};
      }
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus-visible {
    ${focusRing};
  }
`;

const RadioLabel = styled.label<{ $disabled?: boolean }>`
  margin-bottom: 0; /* FIXME: temporary hotfix for dashboard default style */
  display: flex;
  align-items: center;
  cursor: pointer;

  &:active {
    ${Input}:focus {
      box-shadow: none;
    }
  }

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      ${RadioLabelText} {
        color: ${cssVariables('content-disabled')};
      }
    `}
`;

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
  hasError?: boolean;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(({ hasError = false, label = '', ...rest }, ref) => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <RadioLabel $disabled={rest.disabled}>
      <Input
        type="radio"
        hasError={hasError}
        ref={(elementRef) => {
          if (elementRef) {
            if (ref) {
              typeof ref === 'function'
                ? ref(elementRef)
                : ((ref as MutableRefObject<HTMLInputElement>).current = elementRef);
            }
            inputRef.current = elementRef;
          }
        }}
        aria-label={typeof label === 'string' ? label : undefined}
        {...rest}
      />
      {label && <RadioLabelText>{label}</RadioLabelText>}
    </RadioLabel>
  );
});
