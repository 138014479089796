import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const IntegrationsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m20.754 4.708 2.23-2.23-1.414-1.414-2.148 2.148c-2.392-1.9-5.918-1.624-8.164.622L9.314 5.778l8.485 8.485 1.945-1.944c2.078-2.079 2.47-5.253 1.01-7.61zm-2.248.363c1.53 1.53 1.516 4.141-.177 5.834l-.53.53-5.657-5.657.53-.53c1.693-1.693 4.304-1.707 5.834-.177zM3.833 11.258c-2.078 2.079-2.47 5.254-1.01 7.611L.593 21.1l1.414 1.415 2.148-2.149c2.392 1.901 5.918 1.625 8.164-.621l1.944-1.945-1.414-1.414 2.121-2.122-1.414-1.414-2.121 2.121-2.828-2.828 2.121-2.121-1.414-1.414-2.122 2.121-1.414-1.414-1.945 1.944zm1.238 7.248c-1.53-1.53-1.516-4.14.177-5.834l.53-.53 5.657 5.657-.53.53c-1.693 1.693-4.304 1.707-5.834.177z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Integrations = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={IntegrationsSvg} {...props} />
));
Integrations.displayName = 'Integrations';
export default Integrations;
