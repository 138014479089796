import type { ReactNode } from 'react';

import type { PopperProps } from 'react-popper';
import type { SimpleInterpolation } from 'styled-components';

export enum TooltipVariant {
  Dark = 'dark',
  Light = 'light',
}

export enum TooltipTrigger {
  Hover = 'hover',
  Click = 'click',
  Manual = 'manual',
}

export type TooltipProps = {
  className?: string;
  variant?: TooltipVariant;
  content: ReactNode;
  children: ReactNode | ((props: { isOpen: boolean }) => ReactNode);

  placement?: PopperProps['placement'];
  trigger?: TooltipTrigger;
  disabled?: boolean;

  /**
   * Close the tooltip on click outside of the tooltip and the reference element.
   * Only effective when trigger is TooltipTrigger.Click.
   */
  closeOnClickOutside?: boolean;
  popperProps?: Partial<Omit<PopperProps, 'placement' | 'children'>>;
  portalId?: string;
  tooltipContentStyle?: SimpleInterpolation;
  /**
   * If true, a mouse pointer can hover over the tooltip.
   * If false, the tooltip will be hidden as soon as the cursor leaves the target.
   * (Default: true)
   */
  isTooltipHoverable?: boolean;
  /**
   * ID of the tooltip element.
   */
  tooltipId?: string;
  /**
   * If true, the tooltip will be unmounted when the mouse leaves the reference element.
   * If false, the tooltip will be visually hidden by CSS, but not unmounted. (Default: true)
   */
  unmountOnHide?: boolean;
};

export type TooltipRef = {
  show: () => void;
  hide: () => void;
};
