import { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';

export const shadow = {
  0: css`
    box-shadow: none;
  `,
  1: css`
    box-shadow: 0 0 2px 0 rgba(13, 13, 13, 0.32);
  `,
  2: css`
    box-shadow: 0 1px 5px 0 rgba(13, 13, 13, 0.12), 0 0 1px 0 ${cssVariables('bg-overlay-3')},
      0 2px 2px 0 ${cssVariables('border-overlay')};
  `,
  8: css`
    box-shadow: 0 3px 5px -3px ${cssVariables('bg-overlay-1')}, 0 3px 14px 2px ${cssVariables('border-overlay')},
      0 8px 10px 1px rgba(13, 13, 13, 0.12);
  `,
  12: css`
    box-shadow: 0 5px 8px -4px ${cssVariables('bg-overlay-1')}, 0 5px 22px 4px ${cssVariables('border-overlay')},
      0 12px 17px 2px rgba(13, 13, 13, 0.12);
  `,
  16: css`
    box-shadow: 0 6px 10px -5px ${cssVariables('bg-overlay-1')}, 0 6px 30px 5px ${cssVariables('border-overlay')},
      0 16px 24px 2px rgba(13, 13, 13, 0.12);
  `,
  24: css`
    box-shadow: 0 9px 15px -7px ${cssVariables('bg-overlay-1')}, 0 9px 46px 8px ${cssVariables('border-overlay')},
      0 24px 38px 3px rgba(13, 13, 13, 0.12);
  `,
};

export const elevation = {
  modal: shadow[24],
  navi: shadow[16],
  popover: shadow[8],
  card: shadow[2],
  flat: shadow[0],
};
