import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MagicWandSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1.5 3 3 1.5 4.5 3 3 4.5 1.5 3zM12 1.5 10.5 3 12 4.5 13.5 3 12 1.5zM3 10.5 1.5 12 3 13.5 4.5 12 3 10.5z" />
      <path
        fillRule="evenodd"
        d="m3.1 7.343 4.243-4.242L22.9 18.657l-4.243 4.242L3.1 7.344zm7.693 4.864 7.864 7.864 1.414-1.414-7.864-7.864-1.414 1.414zm-1.414-1.414 1.414-1.414-3.45-3.45L5.93 7.343l3.45 3.45z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MagicWand = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MagicWandSvg} {...props} />
));
MagicWand.displayName = 'MagicWand';
export default MagicWand;
