import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeveloperResourcesSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m14.064 3.649-6 16 1.873.702 6-16-1.873-.702zM6.293 5.793.086 12l6.207 6.207 1.414-1.414L2.914 12l4.793-4.793-1.414-1.414zM17.707 5.793 23.914 12l-6.207 6.207-1.414-1.414L21.086 12l-4.793-4.793 1.414-1.414z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeveloperResources = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeveloperResourcesSvg} {...props} />
));
DeveloperResources.displayName = 'DeveloperResources';
export default DeveloperResources;
