import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FileDeactivatedSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11 14V8h2v6h-2zM12 15a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12z" />
      <path fillRule="evenodd" d="M4 2h10.414L20 7.586V22H4V2zm2 2v16h12V8.414L13.586 4H6z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FileDeactivated = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FileDeactivatedSvg} {...props} />
));
FileDeactivated.displayName = 'FileDeactivated';
export default FileDeactivated;
