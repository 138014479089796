import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ProactiveChatFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M19.824 2.761 2 9l8.02 3.565 9.804-9.804zM11.435 13.979 15 22l6.238-17.824-9.803 9.803z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ProactiveChatFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ProactiveChatFilledSvg} {...props} />
));
ProactiveChatFilled.displayName = 'ProactiveChatFilled';
export default ProactiveChatFilled;
