import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GithubSquareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M22 0a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-5.44l.013-.014c-.56.052-.778-.303-.778-.627l.016-3.568c0-1.214-.416-2.008-.883-2.41l.348-.042.175-.025.348-.056.173-.031.345-.073.341-.084c2.262-.606 4.21-2.11 4.21-6.107 0-1.42-.505-2.58-1.338-3.49l.041-.108.05-.15.052-.193c.145-.6.252-1.668-.273-2.992l-.08-.016-.08-.01L19.136 4 19 4.003l-.16.014-.092.012-.204.037c-.583.123-1.476.456-2.718 1.298a12.456 12.456 0 0 0-3.257-.438 12.437 12.437 0 0 0-3.256.438c-1.242-.842-2.137-1.175-2.72-1.298l-.205-.037a2.959 2.959 0 0 0-.09-.012l-.162-.014-.19-.001-.093.007-.117.021c-.43 1.09-.434 2.007-.34 2.632l.045.25.051.211.051.172.065.178c-.832.91-1.338 2.07-1.338 3.49 0 4.287 2.246 5.713 4.711 6.237l.346.067.346.057.348.047c.058.008.115.015.173.021-.37.325-.707.898-.825 1.74l-.246.1-.194.067a3.99 3.99 0 0 1-.216.062l-.233.053c-.845.16-1.966.057-2.782-1.18l-.166-.268-.09-.131c-.263-.362-.89-1.058-1.85-1.125l-.143.006-.132.017a1.26 1.26 0 0 0-.046.01l-.09.025c-.23.08-.351.273.321.732l.079.045.096.064.126.097c.279.23.702.681 1.055 1.488l.143.353.077.16.073.13.043.07.102.148.122.157c.558.66 1.703 1.414 3.91.935l.017 2.212c0 .287-.18.596-.613.627l.015.014H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h20z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GithubSquare = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GithubSquareSvg} {...props} />
));
GithubSquare.displayName = 'GithubSquare';
export default GithubSquare;
