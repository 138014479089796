import type { CSSVariableKey } from '../types';
import colorSet from './colorSet';
import createThemeVariables from './utils/createThemeVariables';

type CSSVariable = (lookupKey: CSSVariableKey | (string | number)[]) => string;

const { cssVariables: createdCSSVariables } = createThemeVariables({
  target: colorSet,
  options: { excludedKeys: ['default'] },
});

/**
 * @param lookupKey A string value or an array [string, number] to query a CSS variable
 * @returns A CSS variable that can be interpolated within CSS declarations e.g. `var(--purple-3)`
 */
const cssVariables: CSSVariable = (lookupKey) => {
  const colorKey = Array.isArray(lookupKey) ? lookupKey.join('-') : lookupKey;

  if (createdCSSVariables[colorKey] === undefined) {
    throw new Error(`The color "${lookupKey}" does not exist in "cssVariables"`);
  }

  return createdCSSVariables[colorKey];
};

export default cssVariables;
