import { css } from 'styled-components';

const breakpoints = {
  sm: 672,
  md: 1320,
  lg: 1608,
  xl: 1816,
};

export const mediaQuery = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(literals, ...placeholders)};
    }
  `;

  return acc;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);
