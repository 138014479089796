import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ThemeDarkFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.983 11.475A4 4 0 1 1 18 4a9.98 9.98 0 0 0-2.602-1.408A9.982 9.982 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10c5.361 0 9.737-4.219 9.989-9.518a10.14 10.14 0 0 0-.295-2.946 4.016 4.016 0 0 1-1.71 1.939z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ThemeDarkFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ThemeDarkFilledSvg} {...props} />
));
ThemeDarkFilled.displayName = 'ThemeDarkFilled';
export default ThemeDarkFilled;
