import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const QuestionSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13.25 17.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM8.001 10.057C7.951 7.76 9.73 6 12.102 6 14.322 6 16 7.475 16 9.437c0 1.768-1.024 2.588-1.909 3.296-.666.533-1.253 1.003-1.26 1.767h-1.932c0-1.684.934-2.377 1.772-2.999.68-.504 1.295-.961 1.295-1.863 0-1.073-.78-1.727-1.898-1.727-1.288 0-2.067.922-2.067 2.146H8z" />
      <path
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Question = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={QuestionSvg} {...props} />
));
Question.displayName = 'Question';
export default Question;
