import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M18.826 14.039A3.018 3.018 0 0 1 21 16.95L20.981 21l-1.045-.046c-4.36-.194-8.569-1.876-11.791-5.099C4.922 12.633 3.24 8.423 3.047 4.063L3 3l4.098.047A3.017 3.017 0 0 1 9.957 5.21l.626 2.122a3.018 3.018 0 0 1-.962 3.173l-1.946 1.623a15.19 15.19 0 0 0 4.197 4.198l1.626-1.952a3.017 3.017 0 0 1 3.16-.966l2.168.631zm-5.184 3.304a15.988 15.988 0 0 0 5.338 1.522l.008-1.924a1.006 1.006 0 0 0-.724-.97l-2.167-.63a1.005 1.005 0 0 0-1.054.321l-1.4 1.681zm-6.985-6.986L8.334 8.96c.308-.257.434-.673.32-1.058L8.027 5.78a1.006 1.006 0 0 0-.952-.721l-1.938-.022c.2 1.855.706 3.655 1.52 5.32z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Call = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallSvg} {...props} />
));
Call.displayName = 'Call';
export default Call;
