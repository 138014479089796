import styled from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { IconButtonProps, SplitButtonProps } from '@feather/types';

import { transitionDefault } from '../../animation';
import { IconButton } from '../button';
import { Dropdown } from '../dropdown';

const StyledSplitButton = styled.div<{ size: IconButtonProps['size'] }>`
  display: flex;
  align-items: center;

  & > button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .splitButtonRight,
  .splitButtonDropdown {
    width: ${({ size }) => (size === 'small' ? '20px' : '16px')};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .splitButtonDropdown {
    height: ${({ size }) => (size === 'small' ? '32px' : '24px')};
    svg {
      transition: fill 0.2s ${transitionDefault};
    }
  }
`;

export const SplitButton = ({
  buttonType = 'secondary',
  size = 'small',
  left,
  right,
  dropdown = null,
}: SplitButtonProps) => {
  const renderToggle = ({ isOpen }) => {
    const iconProps = isOpen ? { color: cssVariables('purple-7') } : {};
    const RightIcon = right.icon;
    return <RightIcon size={size === 'small' ? 20 : 16} {...iconProps} />;
  };
  return (
    <StyledSplitButton size={size}>
      <IconButton buttonType={buttonType} size={size} {...left} />
      {dropdown ? (
        <Dropdown
          toggleRenderer={renderToggle}
          variant="inline"
          size="small"
          showArrow={false}
          {...dropdown}
          className={`splitButtonDropdown${dropdown.className ? ` ${dropdown.className}` : ''}`}
        />
      ) : (
        <IconButton
          buttonType={buttonType}
          size={size}
          {...right}
          className={`splitButtonRight${right.className ? ` ${right.className}` : ''}`}
        />
      )}
    </StyledSplitButton>
  );
};
