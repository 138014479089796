import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FlutterSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g opacity={0.95}>
        <path d="m14.014 0 .014.014h7.358L5.7 15.7 2 12 14.014 0zM14.028 11.072l-6.471 6.457.013.014L14.028 24h7.371l-6.46-6.468 6.46-6.46h-7.371z" />
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Flutter = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FlutterSvg} {...props} />
));
Flutter.displayName = 'Flutter';
export default Flutter;
