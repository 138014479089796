import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FileRecordingSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4 2h10.51L20 7.657V10h-2V8.468L13.665 4H6v15.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V18h2v1.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 19.5V2z" />
      <path
        fillRule="evenodd"
        d="M10.65 14.696 12.09 17h-1.64l-1.184-2.064H8.81V17H7.45v-5.6h2.2c.944 0 1.8.792 1.8 1.768 0 .696-.296 1.232-.8 1.528zm-1.144-1.992H8.81v1.072h.696c.32 0 .576-.24.576-.536 0-.304-.256-.536-.576-.536z"
        clipRule="evenodd"
      />
      <path d="M12.41 17v-5.6h3.52v1.296h-2.16v.8H15.7v1.296H13.77v.912h2.16V17h-3.52zM21.671 14.912c-.2 1.28-1.32 2.168-2.712 2.168-1.64 0-2.864-1.232-2.864-2.88s1.224-2.88 2.864-2.88c1.344 0 2.56.896 2.696 2.088H20.16c-.136-.448-.616-.768-1.2-.768-.84 0-1.472.672-1.472 1.56 0 .896.632 1.56 1.472 1.56.576 0 1.072-.352 1.2-.848h1.512z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FileRecording = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FileRecordingSvg} {...props} />
));
FileRecording.displayName = 'FileRecording';
export default FileRecording;
