import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LiveEventsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4 20V5H2v17h17v-2H4z" />
      <path d="m17 10-5-3v6l5-3z" />
      <path fillRule="evenodd" d="M6 2v16h16V4a2 2 0 0 0-2-2H6zm14 3a1 1 0 0 0-1-1H8v12h12V5z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LiveEvents = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LiveEventsSvg} {...props} />
));
LiveEvents.displayName = 'LiveEvents';
export default LiveEvents;
