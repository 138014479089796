import { useEffect, useRef } from 'react';

export const useOnChangeTrigger = <T>(value: T, onChange?: (value: T) => void) => {
  // set initial value null to skip the first run in useEffect to avoid triggering onChange with the initial value
  const onChangeRef = useRef<typeof onChange | null>(null);

  useEffect(() => {
    onChangeRef.current?.(value);
  }, [value]);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);
};
