import styled from 'styled-components';

import type { FeatherIconComponent, FeatherIconProps } from '@feather/components/icons/types';

import { defaultTransition } from './constants';

type DropdownToggleIconProps = { icon: FeatherIconComponent } & Pick<FeatherIconProps, 'size' | 'className'>;

/**
 * Use this component to update the icon according to dropdown state changes (e.g. hover, pressed, ...)
 */
export const DropdownToggleIcon = styled(({ icon: Icon, size, className }: DropdownToggleIconProps) => (
  <Icon size={size} className={className} />
))`
  transition: ${defaultTransition.css};
  transition-property: fill, transform;
  flex: none;
`;
