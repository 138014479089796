import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import * as Icons from '@feather/components/icons';
import { focusRing } from '@feather/mixins';
import cssVariables from '@feather/theme/cssVariables';

import * as hideOutlineEventListeners from '../button/hideOutlineEventListeners';

export enum TileType {
  ReadOnly = 'readOnly',
  Selectable = 'selectable',
  // Clickable = 'clickable',
}

type TileProps = {
  type?: TileType;
  selected?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Check = styled(Icons.SuccessFilled).attrs({
  size: 16,
  color: cssVariables('purple-7'),
})``;

const TileContainer = styled.div<TileProps>`
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${(props) => cssVariables(props.type === TileType.ReadOnly ? 'border-3' : 'border-2')};

  &:focus {
    outline: none;
  }

  &,
  & > * {
    transition: all 0.2s ${transitionDefault};
  }

  ${Check} {
    opacity: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 8px;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:focus-visible {
        ${focusRing};
      }
    `}

  ${({ disabled, type }) =>
    !disabled &&
    type !== TileType.ReadOnly &&
    css`
      cursor: pointer;

      &:hover {
        border: 1px solid ${cssVariables('border-1')};
      }
    `}

  ${({ disabled, selected }) =>
    !disabled &&
    selected &&
    css`
      border: 1px solid ${cssVariables('border-primary')};

      ${Check} {
        opacity: 1;
      }
    `};
`;

const TileStylable = forwardRef<HTMLDivElement, TileProps>(({ children, type = TileType.ReadOnly, ...props }, ref) => {
  return (
    <TileContainer tabIndex={0} data-test-id="Tile" type={type} {...hideOutlineEventListeners} {...props} ref={ref}>
      {type === TileType.Selectable && <Check />}
      {children}
    </TileContainer>
  );
});

export const Tile = styled(TileStylable)``;
