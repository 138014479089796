import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CurvedVectorArrowSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 201" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M2.017.791a1.5 1.5 0 0 0-.523 2.954L2.017.791zm88.16 42.064-.99 1.128.99-1.128zm45.219 92.246-1.5-.007 1.5.007zm-22.246 49.838.701 1.326-.701-1.326zm-20.555-14.035-1.492-.152 1.492.152zm28.328-30.982.454 1.43-.454-1.43zm42.723 8.08-.751 1.298.751-1.298zm56.307 50.591-.403 1.445 2.134.595-.24-2.202-1.491.162zm-28.126-9.404a1.5 1.5 0 1 0-.806 2.889l.806-2.889zm26.696-17.6a1.501 1.501 0 0 0-2.983.325l2.983-.325zM1.493 3.745c25.631 4.538 65.263 20.57 87.695 40.238l1.977-2.256C68.23 21.617 28.051 5.4 2.017.79l-.523 2.954zm87.695 40.238c26.636 23.352 44.88 55.52 44.708 91.111l3 .014c.178-36.664-18.612-69.605-45.73-93.381l-1.978 2.256zm44.708 91.111c-.004.875-.019 1.761-.046 2.655l2.999.089c.027-.919.042-1.83.047-2.73l-3-.014zm-.046 2.655c-.521 17.505-5.481 37.45-21.401 45.864l1.402 2.652c17.483-9.241 22.472-30.777 22.998-48.427l-2.999-.089zm-21.401 45.864c-4.056 2.143-8.851 1.35-12.609-1.266-3.765-2.622-6.205-6.879-5.753-11.29l-2.984-.305c-.588 5.739 2.584 10.966 7.023 14.057 4.447 3.096 10.449 4.244 15.725 1.456l-1.402-2.652zm-18.362-12.556c1.457-14.227 13.969-25.472 27.29-29.705l-.908-2.859c-14.028 4.457-27.747 16.448-29.366 32.259l2.984.305zm27.29-29.705c5.02-1.595 9.554-2.228 13.915-2.06l.115-2.997c-4.748-.183-9.628.511-14.938 2.198l.908 2.859zm13.915-2.06c8.832.341 17.155 3.968 27.603 10.008l1.501-2.597c-10.533-6.09-19.39-10.039-28.989-10.408l-.115 2.997zm27.603 10.008c20.831 12.044 42.059 29.161 55.804 50.116l2.508-1.646c-14.083-21.47-35.727-38.878-56.811-51.067l-1.501 2.597zm28.126 42.778 28.529 7.96.806-2.89-28.529-7.959-.806 2.889zm30.423 6.353-2.921-26.842-2.983.325 2.922 26.841 2.982-.324z" />
    </svg>
  )),
  { width: 222, height: 201 },
);
const CurvedVectorArrow = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CurvedVectorArrowSvg} {...props} />
));
CurvedVectorArrow.displayName = 'CurvedVectorArrow';
export default CurvedVectorArrow;
