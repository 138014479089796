import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ModerateFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m19.678 7.364-1.415 1.414L11.9 2.414 13.314 1l6.364 6.364zM8.364 18.678l1.414-1.414L3.414 10.9 2 12.314l6.364 6.364zM3 20.004h14v2H3v-2zM14.728 12.314l7.276 7.276-1.414 1.414-7.276-7.276-2.475 2.475-6.364-6.364 6.364-6.364 6.364 6.364-2.475 2.475z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ModerateFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ModerateFilledSvg} {...props} />
));
ModerateFilled.displayName = 'ModerateFilled';
export default ModerateFilled;
