import type { ReactNode, CSSProperties, MutableRefObject, ElementType } from 'react';
import { useRef, useEffect, forwardRef } from 'react';

import isEqual from 'lodash/isEqual';
import PerfectScrollbar from 'perfect-scrollbar';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { ScrollBarRef } from '@feather/types';
import { ZIndexes } from '@feather/zIndexes';

import * as ScrollFunctionFactory from './ScrollFunctionFactory';

type Props = {
  options?: PerfectScrollbar.Options;
  onScroll?: (event: CustomEvent) => void;
  style?: CSSProperties;
  className?: string;
  alwaysVisible?: boolean;
  theme?: 'light' | 'dark';
  children?: ReactNode;
  as?: ElementType;
};

const Container = styled.div<{ alwaysVisible?: boolean; $theme?: 'light' | 'dark' }>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;

  &.ps--active-x > .ps__rail-x,
  &.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
    z-index: ${ZIndexes.scrollBarRail};
  }

  /* Scrollbar rail styles */
  .ps__rail-x {
    display: none;
    opacity: 0;
    height: 6px;
    bottom: 0px;
    position: absolute;
  }

  .ps__rail-y {
    display: none;
    opacity: 0;
    width: 6px;
    right: 0;
    position: absolute;
  }

  ${({ alwaysVisible }) =>
    alwaysVisible &&
    css`
      .ps__rail-x {
        display: block;
        opacity: 0.32;
      }

      .ps__rail-y {
        display: block;
        opacity: 0.32;
      }
    `}

  ${({ $theme }) =>
    $theme === 'dark'
      ? css`
          .ps__thumb-x {
            background-color: ${cssVariables('white')};
          }

          .ps__thumb-y {
            background-color: ${cssVariables('white')};
          }
        `
      : css`
          .ps__thumb-x {
            background-color: ${cssVariables('content-1')};
          }

          .ps__thumb-y {
            background-color: ${cssVariables('content-1')};
          }
        `}

  &:hover > .ps__rail-x,
  &:hover > .ps__rail-y {
    display: block;
    background-color: transparent;
    opacity: 0.32;
  }

  &.ps--focus > .ps__rail-x,
  &.ps--focus > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x,
  &.ps--scrolling-y > .ps__rail-y {
    opacity: 0.32;
  }

  .ps__rail-x:hover,
  .ps__rail-y:hover,
  .ps__rail-x:focus,
  .ps__rail-y:focus,
  .ps__rail-x.ps--clicking,
  .ps__rail-y.ps--clicking {
    opacity: 0.52;
  }

  /* Scrollbar thumb styles */
  .ps__thumb-x {
    border-radius: 6px;
    transition: background-color 0.2s ${transitionDefault}, height 0.2s ${transitionDefault};
    -webkit-transition: background-color 0.2s ${transitionDefault}, height 0.2s ${transitionDefault};
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
  }

  .ps__thumb-y {
    border-radius: 6px;
    transition: background-color 0.2s ${transitionDefault}, width 0.2s ${transitionDefault};
    -webkit-transition: background-color 0.2s ${transitionDefault}, width 0.2s ${transitionDefault};
    width: 6px;
    /* there must be 'right' for ps__thumb-y */
    right: 2px;
    /* please don't change 'position' */
    position: absolute;
  }

  /* MS supports */
  @supports (-ms-overflow-style: none) {
    overflow: auto !important;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow: auto !important;
  }
`;

const useMemoizedOptions = (options: Props['options']) => {
  const ref = useRef<Props['options']>();
  const isEqualToPreviousValue = isEqual(ref.current, options);

  useEffect(() => {
    if (!isEqualToPreviousValue) {
      ref.current = options;
    }
  });

  return isEqualToPreviousValue ? ref.current : options;
};

export const PerfectScrollbarWrapper = forwardRef<ScrollBarRef | null, Props>(
  ({ children, className, theme, alwaysVisible, style, onScroll, options: optionsProp, as }, ref) => {
    const container = useRef<HTMLDivElement>(null);
    const ps = useRef<PerfectScrollbar>();
    const onScrollRef = useRef<typeof onScroll>();
    const options = useMemoizedOptions(optionsProp);

    const updateRefFactory = (ref) => (container: HTMLDivElement) => {
      const { clientHeight, scrollHeight, scrollTop } = container;

      const scrollTo = ScrollFunctionFactory.scrollTo(container);
      const scrollBy = ScrollFunctionFactory.scrollBy(container);

      const handler: ScrollBarRef = {
        clientHeight,
        scrollHeight,
        scrollTop,
        scrollTo,
        scrollToTop: (options) => scrollTo({ left: 0, top: 0, behavior: options?.behavior }),
        scrollToBottom: (options) => scrollTo({ left: 0, top: scrollHeight, behavior: options?.behavior }),
        scrollBy,
        update: () => {
          ps.current && ps.current.update();
        },
        node: container,
      };

      const handlerRef = ref as MutableRefObject<ScrollBarRef>;
      if (handlerRef) {
        if (typeof ref === 'function') {
          ref(handler);
        } else {
          handlerRef.current = handler;
        }
      }
    };

    const updateRef = useRef(updateRefFactory(ref));

    useEffect(() => {
      updateRef.current = updateRefFactory(ref);
    }, [ref]);

    useEffect(() => {
      onScrollRef.current = onScroll;
    }, [onScroll]);

    useEffect(() => {
      const currentContainer = container.current;
      const handleScroll = (event: CustomEvent) => {
        if (container.current) {
          updateRef.current(container.current);
        }
        onScrollRef.current?.(event);
      };

      if (!ps.current && currentContainer) {
        ps.current = new PerfectScrollbar(currentContainer, { ...options, minScrollbarLength: 40 });

        updateRef.current(currentContainer);
      }
      if (currentContainer) {
        currentContainer.addEventListener('ps-scroll-y', handleScroll as EventListener, false);
      }
      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener('ps-scroll-y', handleScroll as EventListener, false);
        }
      };
    }, [options]);

    useEffect(() => {
      if (ps.current) {
        ps.current.update();
      }
    });

    return (
      <Container
        ref={container}
        className={className}
        alwaysVisible={alwaysVisible}
        $theme={theme}
        style={{
          ...style,
          overflow: 'hidden',
        }}
        as={as}
      >
        {children}
      </Container>
    );
  },
);
