import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PlatformColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth={1}>
        <g fillRule="nonzero">
          <g transform="translate(2 1.5)">
            <path
              fill="#2876E8"
              d="M0 1.622C0 .726.73 0 1.631 0H18.37C19.269 0 20 .726 20 1.622v3.251c0 .896-.73 1.623-1.631 1.623H1.63C.731 6.496 0 5.769 0 4.873v-3.25zm0 14.616c0-.896.73-1.623 1.631-1.623H18.37c.9 0 1.631.727 1.631 1.623v3.25c0 .897-.73 1.623-1.631 1.623H1.63c-.9 0-1.631-.726-1.631-1.622v-3.251zM0 8.93c0-.896.73-1.622 1.631-1.622H18.37c.9 0 1.631.726 1.631 1.622v3.251c0 .896-.73 1.622-1.631 1.622H1.63c-.9 0-1.631-.726-1.631-1.622v-3.25z"
            />
            <path
              fill="#FFF"
              d="M16.667 4.603c-.614 0-1.111-.533-1.111-1.19 0-.658.497-1.19 1.11-1.19.614 0 1.112.532 1.112 1.19 0 .657-.498 1.19-1.111 1.19zm0 7.143c-.614 0-1.111-.533-1.111-1.19 0-.658.497-1.19 1.11-1.19.614 0 1.112.532 1.112 1.19 0 .657-.498 1.19-1.111 1.19zm0 7.143c-.614 0-1.111-.533-1.111-1.19 0-.658.497-1.191 1.11-1.191.614 0 1.112.533 1.112 1.19 0 .658-.498 1.19-1.111 1.19z"
            />
          </g>
        </g>
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const PlatformColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PlatformColoredSvg} {...props} />
));
PlatformColored.displayName = 'PlatformColored';
export default PlatformColored;
