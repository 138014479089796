import styled, { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { LozengeProps } from '@feather/types';
import { LozengeVariant } from '@feather/types';

const Container = styled.div<LozengeProps>`
  padding: 4px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: center;
  border-radius: 3px;
  text-transform: uppercase;
  white-space: nowrap;

  ${(props) => {
    if (props.variant === LozengeVariant.Dark) {
      let bgColor = cssVariables([props.color, 5]);
      if (props.color === 'neutral') bgColor = cssVariables('neutral-10');
      if (props.color === 'orange') bgColor = cssVariables('orange-6');
      return css`
        background-color: ${bgColor};
        color: ${props.color === 'yellow' ? cssVariables('neutral-10') : cssVariables('content-inverse-1')};
      `;
    }
    let color = cssVariables([props.color, 8]);
    if (props.color === 'neutral') color = cssVariables('content-2');
    if (props.color === 'red') color = cssVariables('red-6');
    return css`
      background-color: ${cssVariables([props.color, 2])};
      color: ${color};
    `;
  }}
`;

export const Lozenge = styled(
  ({ color, children, variant = LozengeVariant.Light, className, ...props }: LozengeProps) => (
    <Container className={className} color={color} variant={variant} {...props}>
      {children}
    </Container>
  ),
)``;
