import styled from 'styled-components';

import { elevation } from '@feather/elevation';
import { ZIndexes } from '@feather/zIndexes';

import { menuTransition } from './constants';

export const DropdownMenuItemList = styled.ul<{
  isOpen: boolean;
  isOpenTransitionEnded?: boolean;
  width: string | number;
}>`
  position: relative;
  margin: 0;
  padding: 0;
  min-width: 168px;
  width: ${({ width }) => {
    if (width) {
      if (typeof width === 'number') {
        return `${width}px`;
      }

      return width;
    }
    return null;
  }};
  border-radius: 4px;
  background-color: white;
  z-index: ${ZIndexes.dropdownMenu};
  opacity: ${({ isOpen, isOpenTransitionEnded = true }) => (isOpen && isOpenTransitionEnded ? 1 : 0)};
  transition: ${menuTransition.css};
  transition-property: opacity;
  ${elevation.popover}

  .ps {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
