import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrOpenPositionsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-op-a)" clipRule="evenodd">
        <path d="M10.503 19a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0-9.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm-4.5 5.5a4.5 4.5 0 1 1 9 0h-9z" />
        <path d="M20.46 21.5 17 18.038 18.038 17l3.461 3.461-1.038 1.039z" />
      </g>
      <defs>
        <clipPath id="br-op-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrOpenPositions = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrOpenPositionsSvg} {...props} />
));
BrOpenPositions.displayName = 'BrOpenPositions';
export default BrOpenPositions;
