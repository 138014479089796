import type { ReactNode, Ref, CSSProperties, MutableRefObject } from 'react';
import { useMemo, useCallback, useState, useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import type { UseSelectProps, UseSelectReturnValue } from 'downshift';
import { useSelect } from 'downshift';
import { Popper, Manager, Reference } from 'react-popper';
import type { PopperChildrenProps } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import { useVirtual } from 'react-virtual';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import * as Icons from '@feather/components/icons';
import { Tooltip, TooltipReference } from '@feather/components/tooltip';
import { shadow } from '@feather/elevation';
import cssVariables from '@feather/theme/cssVariables';
import type {
  DropdownItem,
  DropdownSize,
  DropdownVariant,
  DropdownProps,
  DropdownSection,
  DropdownToggleTheme,
  DropdownItemType,
  DropdownWidth,
} from '@feather/types';
import { Body, Typography } from '@feather/typography';
import type { Concrete } from '@feather/utils/types';
import { ZIndexes } from '@feather/zIndexes';

import { ErrorMessage } from './components';
import {
  defaultEmptyView,
  defaultItemToString,
  defaultTransition,
  menuTransition,
  MENU_SCROLLBAR_VERTICAL_PADDING,
} from './constants';
import { getToggleColors } from './styleGenerators';

const ChevronIcon = styled(Icons.InputArrowDown)`
  margin-left: 4px;
`;

const Placeholder = styled.div`
  white-space: nowrap;
  color: ${cssVariables('neutral-6')};
`;

const ToggleButtonContent = styled.div`
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const toggleButtonStyles: Record<DropdownSize, FlattenSimpleInterpolation> = {
  small: css`
    --height: 32px;
    --padding-left: 15px;
    --padding-right: 11px;
  `,
  medium: css`
    --height: 40px;
    --padding-left: 15px;
    --padding-right: 11px;
  `,
};

const ToggleButton = styled.button<{
  isOpen: boolean;
  $readOnly: boolean;
  $size: DropdownSize;
  variant: DropdownVariant;
  toggleColors: DropdownToggleTheme;
  hasError: boolean;
}>`
  ${(props) => toggleButtonStyles[props.$size]};
  align-items: center;
  background-color: ${(props) => props.toggleColors.bgColor};
  border-radius: 4px;
  border: 1px solid ${(props) => props.toggleColors.borderColor};
  color: ${(props) => props.toggleColors.contentColor};
  cursor: pointer;
  display: flex;
  height: var(--height);
  min-width: 80px;
  outline: 0;
  padding-left: 15px;
  padding-left: var(--padding-left);
  padding-right: 11px;
  padding-right: var(--padding-right);
  padding: 9px 11px 9px 15px;
  transition: ${defaultTransition.css};
  ${Body['body-short-01']};

  ${(props) =>
    props.variant === 'inline' &&
    css`
      border: 0;
      padding-left: calc(var(--padding-left) * 8 / 15);
      padding-right: calc(var(--padding-right) * 4 / 11);
    `}

  &:disabled {
    ${({ $readOnly, toggleColors }) =>
      $readOnly
        ? css`
            border: 0;
            background: ${toggleColors.readOnlyBgColor};
            cursor: text;
            color: ${toggleColors.readOnlyContentColor};
            user-select: all;
          `
        : css`
            border-color: ${toggleColors.disabledBorderColor};
            background: ${toggleColors.disabledBgColor};
            cursor: not-allowed;
            color: ${toggleColors.disabledContentColor};

            ${Placeholder} {
              color: ${toggleColors.disabledContentColor};
            }

            ${ChevronIcon} {
              fill: ${toggleColors.disabledContentColor};
            }
          `}
  }

  &:not(:disabled):active {
    ${({ toggleColors }) => css`
      border-color: ${toggleColors.activeBorderColor};
      background: ${toggleColors.activeBgColor};
      color: ${toggleColors.activeContentColor};
    `}
  }

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.toggleColors.focusOutlineColor};
  }

  ${Placeholder}, ${ChevronIcon} {
    transition: ${defaultTransition.css};
  }

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${cssVariables('border-negative')};
    `}

  ${({ isOpen, toggleColors }) =>
    isOpen
      ? css`
          border-color: ${toggleColors.pressedBorderColor};
          background: ${toggleColors.pressedBgColor};
          color: ${toggleColors.pressedContentColor};

          ${Placeholder} {
            color: inherit;
          }

          ${ChevronIcon} {
            fill: currentColor;
            transform: rotate(180deg);
          }
        `
      : css`
          &:not(:disabled):hover {
            border-color: ${toggleColors.hoverBorderColor};
            background: ${toggleColors.hoverBgColor};
            color: ${toggleColors.hoverContentColor};

            ${Placeholder} {
              color: ${toggleColors.hoverContentColor};
            }

            ${ChevronIcon} {
              fill: currentColor;
            }
          }
        `}
`;

const OptionList = styled.ul<{ isMenuScrollable: boolean }>`
  outline: 0;
  padding: 8px 0;
  min-width: 168px;
  list-style-type: none;

  ${(props) =>
    props.isMenuScrollable &&
    css`
      max-height: 320px;
      overflow-y: auto;
    `}
`;

const VirtualizedOptionList = styled.ul`
  position: relative;
  outline: 0;
  list-style-type: none;
`;

const CheckIcon = styled(Icons.Done)<{ $isVisible: boolean }>`
  transition: ${defaultTransition.css};

  ${(props) =>
    props.$isVisible
      ? css`
          width: 20px;
          margin-left: 8px;
        `
      : css`
          width: 0;
          margin-left: 0;
        `};
`;

const OptionListItemContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
`;

const OptionListSeparator = Object.assign(
  styled.li`
    background-image: linear-gradient(${cssVariables('neutral-3')}, ${cssVariables('neutral-3')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    height: 17px;
  `,
  { height: 17 },
);

const OptionListItem = styled.li<{ isHighlighted: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
  color: ${cssVariables('neutral-10')};
  ${Body['body-short-01']};

  &[disabled] {
    cursor: not-allowed;
    color: ${cssVariables('neutral-5')};
  }

  ${(props) =>
    props.isHighlighted &&
    css`
      background: ${cssVariables('neutral-1')};
    `}

  ${(props) =>
    props.isSelected &&
    css`
      color: ${cssVariables('purple-7')};
      transition: ${defaultTransition.css};
    `}
`;

const Popover = styled.div<{ showMenu: boolean }>`
  transition: ${menuTransition.css};
  transition-property: opacity, box-shadow;
  opacity: 0;
  border-radius: 4px;
  background: white;
  overflow: hidden;
  z-index: ${ZIndexes.dropdownMenu};

  ${(props) =>
    !props.showMenu &&
    css`
      height: 0;
    `};

  &.display-enter {
    opacity: 0;
  }

  &.display-enter-active,
  &.display-enter-done {
    ${shadow[8]};
    opacity: 1;
  }

  &.display-exit {
    opacity: 1;
  }

  &.display-exit-active {
    opacity: 0;
  }
`;

const Wrapper = styled.div<{ $width?: DropdownWidth }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  ${({ $width }) =>
    $width != null &&
    css`
      width: ${typeof $width === 'number' ? `${$width}px` : $width};

      & > ${ToggleButton}, & > ${Popover}, & > ${TooltipReference}, & > ${TooltipReference} > ${ToggleButton} {
        width: 100%;
      }
    `}
`;

const Label = styled.label<{ isDisabled: boolean; isHidden: boolean }>`
  display: block;
  margin-bottom: 6px;
  color: ${cssVariables('neutral-10')};
  ${Typography['label-03']};

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${cssVariables('neutral-5')};
    `}

  ${(props) =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

type DropdownSelectVirtualListOptions = { width: number; itemHeight: number };

export type DropdownSelectProps<T> = Omit<
  DropdownProps<T>,
  | 'useSearch'
  | 'searchPlaceholder'
  | 'onSearchChange'
  | 'onSearchKeyDown'
  | 'onItemSelected'
  | 'stateReducer'
  | 'onStateChange'
  | 'ref'
  | 'header'
  | 'footer'
  | 'listWidth'
  | 'itemHeight'
> & {
  toggleButtonRef?: Ref<HTMLButtonElement>;
  label?: ReactNode;
  isLabelHidden?: boolean;
  stateReducer?: UseSelectProps<T>['stateReducer'];
  onStateChange?: UseSelectProps<T>['onStateChange'];
  virtualListOptions?: DropdownSelectVirtualListOptions;
};

type ListItem<T> =
  | { type: 'item'; data: T; key: string; index: number }
  | { type: 'separator'; key: string; index: number };

type DropdownSelectPropsWithDefaultPropValue =
  | 'disabled'
  | 'emptyView'
  | 'isLabelHidden'
  | 'isMenuScrollable'
  | 'itemToString'
  | 'itemToElement'
  | 'itemsType'
  | 'readOnly'
  | 'showArrow'
  | 'size'
  | 'variant'
  | 'toggleRenderer'
  | 'placement'
  | 'positionFixed';

type DropdownSelectBaseProps<T> = Omit<DropdownSelectProps<T>, DropdownSelectPropsWithDefaultPropValue> &
  Concrete<Pick<DropdownSelectProps<T>, DropdownSelectPropsWithDefaultPropValue>>;

type DropdownSelectVirtualizedProps<T> = Omit<
  DropdownSelectProps<T>,
  DropdownSelectPropsWithDefaultPropValue | 'virtualListOptions'
> &
  Concrete<Pick<DropdownSelectProps<T>, DropdownSelectPropsWithDefaultPropValue | 'virtualListOptions'>>;

const useRenderLabel = <T,>({
  getLabelProps,
  label,
  isHidden,
  disabled,
}: {
  getLabelProps: UseSelectReturnValue<T>['getLabelProps'];
  label?: DropdownSelectProps<T>['label'];
  isHidden?: boolean;
  disabled?: boolean;
}) =>
  useCallback(
    () =>
      label && (
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <Label isDisabled={disabled} isHidden={isHidden} {...getLabelProps()}>
            {label}
          </Label>
        </>
      ),
    [disabled, getLabelProps, isHidden, label],
  );

const useRenderToggleButton =
  <T,>({
    disabled,
    getToggleButtonProps,
    hasError,
    isOpen,
    readOnly,
    selectedItem,
    showArrow,
    size,
    toggleButtonProps,
    toggleButtonRef,
    toggleRenderer,
    toggleTheme,
    tooltipProps,
    variant,
  }: Pick<UseSelectReturnValue<T>, 'getToggleButtonProps' | 'isOpen' | 'selectedItem'> &
    Pick<
      DropdownSelectBaseProps<T>,
      | 'toggleButtonProps'
      | 'toggleButtonRef'
      | 'toggleTheme'
      | 'tooltipProps'
      | 'disabled'
      | 'readOnly'
      | 'showArrow'
      | 'size'
      | 'toggleRenderer'
      | 'variant'
    > & { hasError: boolean }) =>
  () => {
    const toggleColors = getToggleColors(variant, toggleTheme);

    const { ref: downshiftButtonRef, ...buttonProps } = getToggleButtonProps({
      ...toggleButtonProps,
      ref: toggleButtonRef,
      disabled: disabled || readOnly,
      onMouseDown: (event) => {
        event.currentTarget.style.boxShadow = '';
        toggleButtonProps?.onMouseDown?.(event);
      },
      onFocus: (event) => {
        event.currentTarget.style.boxShadow = '';
        toggleButtonProps?.onFocus?.(event);
      },
      onMouseUp: (event) => {
        event.currentTarget.style.boxShadow = 'none';
        toggleButtonProps?.onMouseUp?.(event);
      },
    });

    const button = (
      <Reference innerRef={downshiftButtonRef}>
        {({ ref }) => (
          <ToggleButton
            ref={ref}
            type="button"
            isOpen={isOpen}
            $size={size}
            variant={variant}
            $readOnly={readOnly}
            toggleColors={toggleColors}
            hasError={hasError}
            {...buttonProps}
          >
            <ToggleButtonContent>{toggleRenderer({ selectedItem, isOpen, hasError })}</ToggleButtonContent>
            {showArrow && <ChevronIcon size={20} color={toggleColors.readOnlyArrowColor} data-test-id="Arrow" />}
          </ToggleButton>
        )}
      </Reference>
    );

    return tooltipProps ? <Tooltip {...tooltipProps}>{button}</Tooltip> : button;
  };

const useRenderPopover =
  <T,>({
    isOpen,
    positionFixed,
    placement,
    portalId,
    onEnter,
    onExited,
    showMenu,
    scheduleUpdateRef,
  }: Pick<UseSelectReturnValue<T>, 'isOpen'> &
    Pick<DropdownSelectBaseProps<T>, 'portalId' | 'positionFixed' | 'placement'> & {
      onEnter: () => void;
      onExited: () => void;
      showMenu: boolean;
      scheduleUpdateRef: MutableRefObject<PopperChildrenProps['scheduleUpdate'] | undefined>;
    }) =>
  (children: ReactNode) => {
    const popper = (
      <Popper positionFixed={positionFixed} placement={placement}>
        {({ ref, style, scheduleUpdate }) => {
          scheduleUpdateRef.current = scheduleUpdate;
          return (
            <CSSTransition
              in={isOpen}
              timeout={menuTransition.duration.milliseconds}
              classNames="display"
              onEnter={onEnter}
              onExited={onExited}
            >
              <Popover showMenu={showMenu} ref={ref} style={style}>
                {children}
              </Popover>
            </CSSTransition>
          );
        }}
      </Popper>
    );

    const portalContainer = portalId && document.getElementById(portalId);
    if (portalContainer) {
      return createPortal(popper, portalContainer);
    }
    return popper;
  };

const useRenderMenuItem = <T,>({
  getItemProps,
  isItemDisabled,
  itemToElement,
}: Pick<UseSelectReturnValue<T>, 'getItemProps'> &
  Pick<DropdownSelectBaseProps<T>, 'isItemDisabled' | 'itemToElement'>) =>
  useCallback(
    ({
      item,
      isHighlighted,
      isSelected,
      key,
      index,
      style,
    }: {
      item: T;
      isHighlighted: boolean;
      isSelected: boolean;
      key: string;
      index: number;
      style?: CSSProperties;
    }) => {
      return (
        <OptionListItem
          {...getItemProps({ key, item, index, disabled: isItemDisabled?.(item), style })}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
        >
          <OptionListItemContent>{itemToElement(item, isSelected)}</OptionListItemContent>
          <CheckIcon size={20} color={cssVariables('purple-7')} $isVisible={isSelected} />
        </OptionListItem>
      );
    },
    [getItemProps, isItemDisabled, itemToElement],
  );

const useFlattenedItems = <T,>({
  items,
  itemsType,
}: {
  items: T[] | DropdownSection<T>[];
  itemsType: DropdownItemType;
}) =>
  useMemo(() => {
    if (itemsType === 'section') {
      return (items as DropdownSection<T>[]).flatMap((section) => section.items);
    }
    return items as T[];
  }, [items, itemsType]);

const useListItemElements = <T,>({
  items,
  itemsType,
}: {
  items: T[] | DropdownSection<T>[];
  itemsType: DropdownItemType;
}) =>
  useMemo<ListItem<T>[]>(() => {
    if (itemsType === 'section') {
      const { result } = (items as DropdownSection<T>[]).reduce<{ result: ListItem<T>[]; itemIndex: number }>(
        ({ result, itemIndex }, section, sectionIndex, sections) => {
          result.push(
            ...section.items.map((item, index) => ({
              type: 'item' as const,
              data: item,
              key: `${sectionIndex}-${item}${index}`,
              index: itemIndex++,
            })),
          );
          if (sectionIndex < sections.length - 1) {
            result.push({ type: 'separator' as const, key: `${sectionIndex}-separator`, index: -1 });
          }
          return { result, itemIndex };
        },
        { result: [], itemIndex: 0 },
      );
      return result;
    }
    return items.map((item, index) => ({ data: item, key: `${item}${index}`, type: 'item' as const, index }));
  }, [items, itemsType]);

const useMenuVisibility = () => {
  const [showMenu, setShowMenu] = useState(false);
  const onMenuTransitionEnter = () => setShowMenu(true);
  const onMenuTransitionExited = () => setShowMenu(false);

  return { showMenu, setShowMenu, onMenuTransitionEnter, onMenuTransitionExited };
};

const renderError = (error?: DropdownSelectProps<any>['error']) =>
  typeof error === 'string' && !!error ? <ErrorMessage>{error}</ErrorMessage> : null;

const DropdownSelectBase = <T extends DropdownItem>({
  className,
  disabled,
  emptyView,
  error,
  initialSelectedItem,
  isItemDisabled,
  isLabelHidden,
  isMenuScrollable,
  itemToString,
  itemToElement,
  items,
  itemsType,
  label,
  onChange,
  readOnly,
  selectedItem: selectedItemProp,
  showArrow,
  size,
  variant,
  toggleTheme,
  toggleButtonRef,
  toggleRenderer,
  toggleButtonProps,
  width,
  placement,
  positionFixed,
  portalId,
  tooltipProps,
  stateReducer,
  onStateChange,
}: DropdownSelectBaseProps<T>) => {
  const flattenedItems = useFlattenedItems({ items, itemsType });
  const listItemElements = useListItemElements({ items, itemsType });
  const { showMenu, onMenuTransitionEnter, onMenuTransitionExited } = useMenuVisibility();

  const useSelectReturnValue = useSelect({
    items: flattenedItems,
    initialSelectedItem,
    itemToString: (item) => (item == null ? '' : itemToString(item)),
    onSelectedItemChange: ({ selectedItem }) => onChange?.(selectedItem || null),
    ...(selectedItemProp !== undefined && { selectedItem: selectedItemProp }),
    ...(stateReducer && { stateReducer }),
    ...(onStateChange && { onStateChange }),
  });

  const renderLabel = useRenderLabel<T>({ ...useSelectReturnValue, label, isHidden: isLabelHidden, disabled });
  const renderToggleButton = useRenderToggleButton<T>({
    ...useSelectReturnValue,
    disabled,
    hasError: !!error,
    readOnly,
    showArrow,
    size,
    toggleButtonProps,
    toggleButtonRef,
    toggleRenderer,
    toggleTheme,
    tooltipProps,
    variant,
  });

  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();
  const renderPopover = useRenderPopover<T>({
    ...useSelectReturnValue,
    positionFixed,
    placement,
    portalId,
    onEnter: onMenuTransitionEnter,
    onExited: onMenuTransitionExited,
    showMenu,
    scheduleUpdateRef,
  });

  const { getItemProps, getMenuProps, highlightedIndex, selectedItem, isOpen } = useSelectReturnValue;
  const renderMenuItem = useRenderMenuItem<T>({ getItemProps, isItemDisabled, itemToElement });

  useLayoutEffect(() => {
    if (isOpen) scheduleUpdateRef.current?.();
  }, [isOpen]);

  return (
    <Wrapper className={className} $width={width}>
      <Manager>
        {renderLabel()}
        {renderToggleButton()}
        {renderError(error)}
        {renderPopover(
          <OptionList {...getMenuProps()} isMenuScrollable={isMenuScrollable}>
            {showMenu &&
              (listItemElements.length > 0 ? (
                listItemElements.map((item) => {
                  if (item.type === 'separator') {
                    return <OptionListSeparator key={item.key} role="presentation" />;
                  }
                  const { data, key, index } = item;
                  return renderMenuItem({
                    isHighlighted: highlightedIndex === index,
                    isSelected: selectedItem === data,
                    item: data,
                    key,
                    index,
                  });
                })
              ) : (
                <li role="presentation">{emptyView}</li>
              ))}
          </OptionList>,
        )}
      </Manager>
    </Wrapper>
  );
};

const DropdownSelectVirtualized = <T extends DropdownItem>({
  className,
  disabled,
  emptyView,
  error,
  initialSelectedItem,
  isItemDisabled,
  isLabelHidden,
  itemToString,
  itemToElement,
  items,
  itemsType,
  label,
  onChange,
  readOnly,
  selectedItem: selectedItemProp,
  showArrow,
  size,
  variant,
  toggleTheme,
  toggleButtonRef,
  toggleRenderer,
  toggleButtonProps,
  width,
  placement,
  positionFixed,
  portalId,
  tooltipProps,
  virtualListOptions: { width: listWidth, itemHeight },
  stateReducer,
  onStateChange,
}: DropdownSelectVirtualizedProps<T>) => {
  const flattenedItems = useFlattenedItems({ items, itemsType });
  const listItemElements = useListItemElements({ items, itemsType });
  const parentRef = useRef<HTMLUListElement>(null);
  const { showMenu, onMenuTransitionEnter, onMenuTransitionExited } = useMenuVisibility();

  const getListItemHeight = useCallback(
    ({ type }: ListItem<T>) => (type === 'separator' ? OptionListSeparator.height : itemHeight),
    [itemHeight],
  );

  const rowVirtualizer = useVirtual({
    size: listItemElements.length,
    parentRef,
    estimateSize: useCallback(
      (index) => getListItemHeight(listItemElements[index]),
      [getListItemHeight, listItemElements],
    ),
    overscan: 5,
    paddingStart: MENU_SCROLLBAR_VERTICAL_PADDING,
    paddingEnd: MENU_SCROLLBAR_VERTICAL_PADDING,
  });

  const useSelectReturnValue = useSelect({
    items: flattenedItems,
    initialSelectedItem,
    itemToString: (item) => (item == null ? '' : itemToString(item)),
    onSelectedItemChange: ({ selectedItem }) => onChange?.(selectedItem || null),
    onHighlightedIndexChange: ({ highlightedIndex }) => {
      highlightedIndex != null && highlightedIndex >= 0 && rowVirtualizer.scrollToIndex(highlightedIndex);
    },
    ...(selectedItemProp != null && { selectedItem: selectedItemProp }),
    ...(stateReducer && { stateReducer }),
    ...(onStateChange && { onStateChange }),
  });

  const renderLabel = useRenderLabel({ ...useSelectReturnValue, label, isHidden: isLabelHidden, disabled });
  const renderToggleButton = useRenderToggleButton<T>({
    ...useSelectReturnValue,
    disabled,
    hasError: !!error,
    readOnly,
    showArrow,
    size,
    toggleButtonProps,
    toggleButtonRef,
    toggleRenderer,
    toggleTheme,
    tooltipProps,
    variant,
  });

  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();
  const renderPopover = useRenderPopover<T>({
    ...useSelectReturnValue,
    positionFixed,
    placement,
    portalId,
    onEnter: onMenuTransitionEnter,
    onExited: onMenuTransitionExited,
    showMenu,
    scheduleUpdateRef,
  });

  const listHeight = rowVirtualizer.totalSize;

  const { getItemProps, getMenuProps, highlightedIndex, selectedItem } = useSelectReturnValue;
  const renderMenuItem = useRenderMenuItem<T>({ getItemProps, isItemDisabled, itemToElement });

  return (
    <Wrapper className={className} $width={width}>
      <Manager>
        {renderLabel()}
        {renderToggleButton()}
        {renderError(error)}
        {renderPopover(
          <VirtualizedOptionList
            {...getMenuProps({ ref: parentRef })}
            css={`
              width: ${listWidth}px;
              ${listItemElements.length > 0 &&
              css`
                height: ${Math.min(320, listHeight)}px;
                overflow: auto;
              `}
            `}
          >
            {showMenu &&
              (listItemElements.length > 0 ? (
                <>
                  <li key="total-size" style={{ height: listHeight }} role="presentation" />
                  {rowVirtualizer.virtualItems.map((virtualRow) => {
                    const item = listItemElements[virtualRow.index];
                    const style: CSSProperties = {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    };

                    if (item.type === 'separator') {
                      return <OptionListSeparator key={item.key} role="presentation" style={style} />;
                    }
                    const { data, key, index } = item;
                    return renderMenuItem({
                      isHighlighted: highlightedIndex === index,
                      isSelected: selectedItem === data,
                      item: data,
                      key,
                      index,
                      style,
                    });
                  })}
                </>
              ) : (
                <li role="presentation">{emptyView}</li>
              ))}
          </VirtualizedOptionList>,
        )}
      </Manager>
    </Wrapper>
  );
};

const DropdownSelect = <T,>({
  disabled = false,
  emptyView = defaultEmptyView,
  isLabelHidden = false,
  isMenuScrollable = true,
  itemToString = defaultItemToString,
  itemToElement = itemToString,
  itemsType = 'array',
  readOnly = false,
  showArrow = true,
  size = 'medium',
  variant = 'default',
  placeholder,
  toggleRenderer = ({ selectedItem }) =>
    selectedItem == null ? <Placeholder>{placeholder}</Placeholder> : itemToString(selectedItem),
  placement = 'bottom-start',
  positionFixed = false,
  virtualListOptions,
  ...props
}: DropdownSelectProps<T>) => {
  const propsWithDefaultPropValues = {
    disabled,
    emptyView,
    isLabelHidden,
    isMenuScrollable,
    itemToString,
    itemToElement,
    itemsType,
    readOnly,
    showArrow,
    size,
    variant,
    toggleRenderer,
    placement,
    positionFixed,
  };

  return virtualListOptions ? (
    <DropdownSelectVirtualized virtualListOptions={virtualListOptions} {...propsWithDefaultPropValues} {...props} />
  ) : (
    <DropdownSelectBase {...propsWithDefaultPropValues} {...props} />
  );
};

DropdownSelect.stateChangeTypes = useSelect.stateChangeTypes;

export default DropdownSelect;
