import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PostmanSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#postm-a)">
        <path d="M12 23.102c6.131 0 11.102-4.97 11.102-11.102C23.102 5.868 18.132.898 12 .898 5.868.898.898 5.868.898 12c0 6.131 4.97 11.102 11.102 11.102zM12 24C5.372 24 0 18.628 0 12S5.372 0 12 0s12 5.372 12 12-5.372 12-12 12z" />
        <path
          fillRule="evenodd"
          d="M19.998 5.92c-3.375-4.368-9.65-5.173-14.02-1.798C1.612 7.497.807 13.772 4.182 18.14c3.374 4.369 9.65 5.175 14.018 1.8 4.369-3.374 5.174-9.652 1.8-14.02z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M14.054 8.658c-.72.328-1.892 1.322-3.793 3.215l.54.54.181.181 3.674-3.673a.745.745 0 0 1 .41-.199c-.273-.204-.535-.282-1.012-.064zm1.62 1.402-3.845 3.378c.342.343.53.53.626.628 1.524-1.446 2.973-2.92 3.22-4.006zm-6.568 2.985c.006.01.012.009.018.008l1.557-.336-.63-.63-.943.941c-.003.002-.008.006-.002.017zm7.063-5.22 1.304-1.304a1.461 1.461 0 0 0-.9-.307 1.46 1.46 0 0 0-1.467 1.465 1.465 1.465 0 0 0 2.053 1.346l-.989-.988a.15.15 0 0 1 0-.213z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M17.569 8.165a.116.116 0 0 1-.089-.19.343.343 0 0 0 .042-.376.117.117 0 0 1 .052-.155.117.117 0 0 1 .155.052.576.576 0 0 1-.072.627.112.112 0 0 1-.088.042zm.123-1.438L16.49 7.932l.94.94a1.459 1.459 0 0 0 .614-1.193 1.46 1.46 0 0 0-.35-.952zM14.87 9.132l-3.663 3.664.421.421 3.88-3.406a.466.466 0 1 0-.637-.678zm-6.834 7.815h.001c1.747-.877 3.159-1.778 4.197-2.68l-.592-.59-1.557.24L7 17.002l.986.987c.024.023.049.017.065.01.016-.01.034-.028.027-.06l-.169-.722a.24.24 0 0 1 .125-.269zm-.358 1.154-.888-.888-.754.753c-.003.002-.005.006-.003.014.003.008.012.01.012.01l1.633.111zm2.806-4.581H10.497l.876-.113-.387-.389-.503.502z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M17.73 7.495a.116.116 0 0 0-.209.104.345.345 0 0 1-.042.375.115.115 0 0 0 .015.163.117.117 0 0 0 .075.027.117.117 0 0 0 .088-.042.576.576 0 0 0 .073-.627z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="postm-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Postman = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PostmanSvg} {...props} />
));
Postman.displayName = 'Postman';
export default Postman;
