import defaultColorSet from '@feather/theme/colorSet';
import type { FeatherThemeConfig } from '@feather/types';

import createThemeVariables from './createThemeVariables';

const getThemeVariables = ({ excludedKeys = ['default'], cssVariablePrefix, colorSet }: FeatherThemeConfig = {}) =>
  createThemeVariables({
    target: {
      ...defaultColorSet,
      ...colorSet,
    },
    options: { cssVariablePrefix, excludedKeys },
  });

export default getThemeVariables;
