import styled from 'styled-components';

import randomAvatarSvg from '@feather/generated/randomAvatarSvg';

import { AvatarType } from './AvatarType';

type Props = {
  size: number;
  type: AvatarType;
  hashcode: number;
};

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

const getListItemFromHashcode = (list: string[], hashcode: number) => {
  return list[mod(hashcode, list.length)];
};

const Img = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const RandomAvatar = ({ type, size, hashcode }: Props) => {
  switch (type) {
    case AvatarType.User: {
      const templateCount = Object.keys(randomAvatarSvg).filter((key) => key.startsWith('user')).length;
      const templateIndex = mod(hashcode, templateCount);
      return (
        <Img size={size} src={getListItemFromHashcode(randomAvatarSvg[`user${templateIndex}`], hashcode)} alt="" />
      );
    }

    default:
      if (randomAvatarSvg[type] == null) {
        return null;
      }
      return <Img size={size} src={getListItemFromHashcode(randomAvatarSvg[type], hashcode)} alt="" />;
  }
};
