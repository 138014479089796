import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NotionColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0D0D0D"
        fillRule="evenodd"
        d="m15.242.055-13.296.98C.874 1.128.5 1.828.5 2.667v14.558c0 .654.233 1.213.793 1.96l3.126 4.06c.513.654.98.794 1.96.747l15.441-.934c1.306-.093 1.68-.7 1.68-1.726V4.954c0-.53-.21-.684-.828-1.136l-.106-.077L18.322.755c-1.027-.747-1.447-.84-3.08-.7zM6.73 4.685c-1.26.086-1.547.105-2.263-.477L2.646 2.76c-.186-.187-.093-.42.373-.466l12.783-.934c1.073-.093 1.633.28 2.053.607l2.192 1.587c.094.046.327.326.047.326l-13.202.793-.163.011zM5.26 21.192V7.287c0-.606.186-.886.746-.933l15.162-.887c.514-.046.747.28.747.887v13.81c0 .608-.094 1.122-.934 1.168l-14.509.84c-.84.047-1.212-.233-1.212-.98zM19.582 8.033c.093.42 0 .84-.421.888l-.7.14v10.264c-.606.327-1.166.514-1.632.514-.747 0-.934-.234-1.493-.933l-4.573-7.186v6.952L12.21 19s0 .84-1.167.84l-3.218.187c-.094-.187 0-.654.326-.747l.84-.233V9.854L7.825 9.76c-.094-.42.14-1.026.793-1.073l3.452-.233 4.759 7.279v-6.44l-1.213-.139c-.094-.514.28-.887.746-.933l3.22-.188z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NotionColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NotionColoredSvg} {...props} />
));
NotionColored.displayName = 'NotionColored';
export default NotionColored;
