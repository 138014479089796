import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MagicWandFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 1.5 1.5 3 3 4.5 4.5 3 3 1.5zM10.5 3 12 1.5 13.5 3 12 4.5 10.5 3zM1.5 12 3 10.5 4.5 12 3 13.5 1.5 12z" />
      <path
        fillRule="evenodd"
        d="M7.343 3.1 3.101 7.344 18.657 22.9l4.243-4.242L7.343 3.1zm3.45 6.279-3.45-3.45L5.93 7.343l3.45 3.45 1.414-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MagicWandFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MagicWandFilledSvg} {...props} />
));
MagicWandFilled.displayName = 'MagicWandFilled';
export default MagicWandFilled;
