import styled from 'styled-components';

import { Tag, TagVariant } from '@feather/components/Tag';
import { Tooltip, TooltipVariant } from '@feather/components/tooltip';
import type { TreeData } from '@feather/components/tree';

type Props = {
  selectedNodes: TreeData[];
  onRemove: (node: TreeData) => void;
  tagMaxWidth?: number;
  disabled?: boolean;
};

const SelectedNodesWrapper = styled.div`
  min-height: 30px;
  padding: 3px 0;
  overflow: hidden;
  text-align: left;
  line-height: 0; // avoid extra vertical spacing around inline-flex items

  && > * {
    // spacing between tags, overriding default spacing between tags
    margin: 2px;
  }
`;

const SelectedNode = styled(Tag).attrs({ variant: TagVariant.Dark })``;
const InlineTooltip = styled(Tooltip)`
  display: inline-block;
`;

export const SelectedNodes = ({ selectedNodes, onRemove, tagMaxWidth, disabled }: Props) => {
  return (
    <SelectedNodesWrapper>
      {selectedNodes.map((node, index) => {
        const key = node.key || `${index}-${node.label}`;
        const tag = (
          <SelectedNode
            key={key}
            icon={node.icon}
            onRemove={
              disabled
                ? undefined
                : (event) => {
                    // prevent the dropdown from opening by click event
                    event.stopPropagation();
                    onRemove(node);
                  }
            }
            maxWidth={typeof tagMaxWidth === 'number' ? tagMaxWidth : undefined}
            disableTooltip={true}
          >
            {node.label}
          </SelectedNode>
        );

        return node.tooltipContent ? (
          <InlineTooltip key={`${key}__tooltip`} variant={TooltipVariant.Dark} content={node.tooltipContent}>
            {tag}
          </InlineTooltip>
        ) : (
          tag
        );
      })}
    </SelectedNodesWrapper>
  );
};
