import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NotificationsColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#6210CC" d="M21 6.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
      <path fill="#6210CC" d="M13.169 4H3.5v14a3 3 0 0 0 3 3h11a3 3 0 0 0 3-3v-7.5A5 5 0 0 1 13.169 4z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NotificationsColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NotificationsColoredSvg} {...props} />
));
NotificationsColored.displayName = 'NotificationsColored';
export default NotificationsColored;
