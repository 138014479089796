import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const RollbackSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.707 4.707 7.293 3.293 2.586 8l4.707 4.707 1.414-1.414L6.414 9H15a5 5 0 1 1 0 10H7v2h8a7 7 0 1 0 0-14H6.414l2.293-2.293z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Rollback = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={RollbackSvg} {...props} />
));
Rollback.displayName = 'Rollback';
export default Rollback;
