import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrNewsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-ne-a)" clipRule="evenodd">
        <path d="M21 7v11.25A3.75 3.75 0 0 1 17.25 22H8v-1.5h9.25a2.25 2.25 0 0 0 2.25-2.25V7H21z" />
        <path d="M16 19H5a2 2 0 0 1-2-2V2h13a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2zM7 7.5h7V6H7v1.5zm7 4H7V10h7v1.5zm-7 4h7V14H7v1.5z" />
      </g>
      <defs>
        <clipPath id="br-ne-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrNews = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrNewsSvg} {...props} />
));
BrNews.displayName = 'BrNews';
export default BrNews;
