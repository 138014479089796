import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NotificationOffSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m6.414 7.828-5.41-5.41 1.414-1.414L23 21.586 21.586 23l-5.5-5.5H3.243l1.315-2.215a10.356 10.356 0 0 0 1.453-5.29c0-.763.143-1.494.403-2.167zm1.633 1.633a4.045 4.045 0 0 0-.036.535c0 1.914-.444 3.798-1.293 5.504h7.368L8.046 9.46z"
        clipRule="evenodd"
      />
      <path d="M11 19a1 1 0 1 0 2 0h2a3 3 0 1 1-6 0h2zM17.978 9.996c0 1.795.47 3.558 1.359 5.114l-3.21-3.21a12.303 12.303 0 0 1-.149-1.904A3.99 3.99 0 0 0 11.995 6c-.524 0-1.024.101-1.482.286L9.018 4.793a5.934 5.934 0 0 1 1.98-.71V3a1 1 0 1 1 2 0v1.084a5.994 5.994 0 0 1 4.98 5.912z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NotificationOff = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NotificationOffSvg} {...props} />
));
NotificationOff.displayName = 'NotificationOff';
export default NotificationOff;
