import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallsStudioSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.95 13.137 9.947 21H4.3C2.477 21 1 19.535 1 17.727V3h12.65c1.822 0 3.3 1.465 3.3 3.273v6.864zM18.768 10.802 23 15.562V7.636a4.397 4.397 0 0 0-4.232 3.166zM17.125 20.017H13.5L18.86 14l1.28 1.439-2.265 2.544H21.5L16.14 24l-1.28-1.439 2.265-2.544z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallsStudio = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallsStudioSvg} {...props} />
));
CallsStudio.displayName = 'CallsStudio';
export default CallsStudio;
