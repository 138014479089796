import styled from 'styled-components';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from 'styled-components/cssprop';

import cssVariables from '@feather/theme/cssVariables';
import type { DropdownProps } from '@feather/types';

import { Dropdown } from '../dropdown';

const DropdownWrapper = styled.div`
  flex-shrink: 1;
  min-width: 0;

  > div > button {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

export const GNBDropdown = <T extends {}>(props: Omit<DropdownProps<T>, 'size' | 'variant' | 'toggleTheme'>) => {
  return (
    <DropdownWrapper>
      <Dropdown<T>
        {...props}
        variant="inline"
        size="small"
        toggleTheme={{
          contentColor: 'white',
          hoverContentColor: 'white',
          pressedContentColor: 'white',
          activeContentColor: 'white',
          hoverBgColor: cssVariables('purple-8'),
          activeBgColor: cssVariables('purple-9'),
          pressedBgColor: cssVariables('purple-8'),
          focusOutlineColor: 'white',
        }}
        css={`
          height: 40px;
          font-weight: 500;
          color: white;
          padding-left: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      />
    </DropdownWrapper>
  );
};
