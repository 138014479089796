export function getInterpolatedYValue(descOrderedPoints: readonly (readonly [number, number])[], targetX: number) {
  for (let i = 0; i < descOrderedPoints.length; i++) {
    const [currentX, currentY] = descOrderedPoints[i];

    if (i === 0) {
      if (targetX >= currentX) {
        return targetX * (currentY / currentX);
      }
    } else {
      if (targetX >= currentX) {
        const [previousX, previousY] = descOrderedPoints[i - 1];
        return currentY + ((targetX - currentX) * (previousY - currentY)) / (previousX - currentX);
      }
    }
  }

  const [lastX, lastY] = descOrderedPoints[descOrderedPoints.length - 1];
  return targetX * (lastY / lastX);
}
