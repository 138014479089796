import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { IconButtonProps } from '@feather/types';

import { IconButton, generateButtonContainerStyle } from '../button';

const StyleOverriddenIconButton = styled(IconButton)<{ isNotificationDotVisible?: boolean }>`
  flex: none;
  width: 40px;
  height: 40px;

  ${generateButtonContainerStyle({
    contentColor: 'white',
    disabledContentColor: 'white',
    bgColor: 'transparent',
    hoverBgColor: cssVariables('purple-8'),
    activeBgColor: cssVariables('purple-9'),
    pressedBgColor: cssVariables('purple-8'),
    disabledBgColor: 'transparent',
    borderColor: 'transparent',
    disabledBorderColor: 'transparent',
    focusOutlineColor: 'white',
  })}

  svg {
    fill: white !important;
  }

  :after {
    content: '';
    position: absolute;
    display: block;
    top: 4px;
    right: 4px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${cssVariables('red-5')};
    transition: 0.2s transform ${transitionDefault};
    transform: scale(0);

    ${(props) =>
      props.isNotificationDotVisible &&
      css`
        transform: scale(1);
      `}
  }
`;

const GNBIconButtonStyleable = (
  props: Omit<IconButtonProps, 'size' | 'buttonType'> & { isNotificationDotVisible?: boolean },
) => {
  return <StyleOverriddenIconButton buttonType="primary" size="small" {...props} />;
};

export const GNBIconButton = styled(GNBIconButtonStyleable)``;
