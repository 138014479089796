import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UsageSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 2h-1v11h11v-1c0-5.523-4.477-10-10-10zm1 9V4.062A8.004 8.004 0 0 1 19.938 11H13z"
        clipRule="evenodd"
      />
      <path d="M4 12a8.003 8.003 0 0 1 5-7.418V2.458C4.943 3.732 2 7.522 2 12c0 5.523 4.477 10 10 10 4.478 0 8.268-2.943 9.542-7H19.42A8.003 8.003 0 0 1 4 12z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Usage = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UsageSvg} {...props} />
));
Usage.displayName = 'Usage';
export default Usage;
