import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeactivateSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm2-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
        clipRule="evenodd"
      />
      <path d="M3 20.5a6 6 0 0 1 12 0v.5h2v-.5a8 8 0 1 0-16 0H.996v.5h2v-.5H3zM17.6 10l-2.1 2.1 1.4 1.4 2.1-2.1 2.1 2.1 1.4-1.4-2.1-2.1 2.1-2.1-1.4-1.4L19 8.6l-2.1-2.1-1.4 1.4 2.1 2.1z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Deactivate = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeactivateSvg} {...props} />
));
Deactivate.displayName = 'Deactivate';
export default Deactivate;
