import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ListedByTimeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 5V3h20v2H2zM2 10V8h7v2H2zM2 15v-2h6v2H2zM2 20v-2h7v2H2zM17 19a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm7-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0z"
        clipRule="evenodd"
      />
      <path fillRule="evenodd" d="M15.745 10.5h2v3.338l1.749 1.26-1.17 1.622-2.579-1.858V10.5z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ListedByTime = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ListedByTimeSvg} {...props} />
));
ListedByTime.displayName = 'ListedByTime';
export default ListedByTime;
