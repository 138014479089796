import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VerticalAlignRightSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 3h-2v18h2V3zM17 8H3v8h12a2 2 0 0 0 2-2V8z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const VerticalAlignRight = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VerticalAlignRightSvg} {...props} />
));
VerticalAlignRight.displayName = 'VerticalAlignRight';
export default VerticalAlignRight;
