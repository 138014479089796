import styled, { keyframes, css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { SpinnerProps } from '@feather/types';
import { getInterpolatedYValue } from '@feather/utils/getInterpolatedYValue';

const strokeWidthMap = [
  [64, 6],
  [24, 3],
  [20, 2.5],
  [16, 2],
] as const;

const svgSizeMap = [
  [64, 62],
  [24, 22],
  [20, 18.3],
  [16, 14.7],
] as const;

const getSizeMap = (size: number) => {
  const svg = getInterpolatedYValue(svgSizeMap, size);
  const strokeWidth = getInterpolatedYValue(strokeWidthMap, size);
  return { viewBox: size, svg, strokeWidth };
};

const rotator = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = (circumference: number) => keyframes`
  0% {
    stroke-dasharray: 1px ${Math.round(circumference)}px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: ${Math.round(circumference / 1.65)}px ${Math.round(circumference)}px;
    stroke-dashoffset: -${Math.round(circumference / 11.5)}px;
  }
  100% {
    stroke-dasharray: ${Math.round(circumference / 1.65)}px ${Math.round(circumference)}px;
    stroke-dashoffset: -${Math.round(circumference / 1.2)}px;
  }
`;

type StyledSpinnerProps = { viewBoxSize: number; svgSize: number; circumference: number };

const StyledSpinner = styled.div<StyledSpinnerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ viewBoxSize }) => viewBoxSize}px;
  height: ${({ viewBoxSize }) => viewBoxSize}px;
  ${({ svgSize, circumference }) => css`
    svg {
      width: ${svgSize}px;
      height: ${svgSize}px;
      animation: ${rotator} 1.4s linear infinite;
      circle {
        fill: none;
        stroke-dasharray: ${Math.round(circumference / 4)}px ${Math.round(circumference + circumference / 6.5)}px;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: ${dash(circumference + circumference / 6.5)} 1.4s ease-in-out infinite;
      }
    }
  `}
`;

const SpinnerDefault = ({ className, size = 24, stroke = cssVariables('neutral-9') }: SpinnerProps) => {
  const { viewBox, strokeWidth, svg } = getSizeMap(size);
  const circumference = 2 * Math.PI * ((viewBox - strokeWidth) / 2);
  return (
    <StyledSpinner
      className={className}
      viewBoxSize={viewBox}
      svgSize={svg}
      role="progressbar"
      circumference={circumference}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`${viewBox / 2} ${viewBox / 2} ${viewBox} ${viewBox}`}>
        <circle
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          stroke={stroke}
          cx={viewBox}
          cy={viewBox}
          r={(viewBox - strokeWidth) / 2}
        />
      </svg>
    </StyledSpinner>
  );
};

export default SpinnerDefault;
