import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ClearFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 10h3.5v2h-9v-2H10V2h2v8zM22 12h-5v-2h5v2zM18.5 16H22v-2h-3.5v2zM22 20h-4v-2h4v2zM1.998 22h4.28l1.229-5H9.45l-1.129 5h8.021l.643-5.721A2.5 2.5 0 0 0 14.5 13.5h-8.37a2.5 2.5 0 0 0-2.42 1.874L1.998 22z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ClearFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ClearFilledSvg} {...props} />
));
ClearFilled.displayName = 'ClearFilled';
export default ClearFilled;
