import type { ReactNode, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';

import { useCombobox } from 'downshift';
import identity from 'lodash/identity';
import type { PopperProps } from 'react-popper';
import { Manager, Reference, Popper } from 'react-popper';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import { ScrollBar } from '@feather/components/ScrollBar';
import { DropdownMenuItemList } from '@feather/components/dropdown/DropdownMenuItemList';
import { DropdownMenuItem } from '@feather/components/dropdown/dropdownMenuItem';
import { defaultDropdownSizeStyleMap } from '@feather/components/dropdown/styleGenerators';
import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import { Body } from '@feather/typography';

type SearchSelectionDropdownSize = 'small' | 'medium';
type SearchSelectionDropdownProps = {
  size?: SearchSelectionDropdownSize;
  label?: ReactNode;
  placeholder?: string;
  initialSelectedItem?: string;
  items: string[];
  error?: string;
  noResultsText?: string;
  onChange?: (selectedItem: string | null) => void;
  itemToElement?: (item: string) => ReactNode;
  newItemToElement?: (inputValue: string) => ReactNode;
  isCreatable?: boolean;
  className?: string;
  popperProps?: Partial<PopperProps>;
  disableNoResultsView?: boolean;
};

const Container = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: block;
  height: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: ${cssVariables('neutral-10')};
  margin-bottom: 6px;
`;

const ToggleButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  width: 36px;
  height: 100%;
  border: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
`;

const Input = styled.input`
  flex: 1;
  color: ${cssVariables('neutral-10')};
  height: 100%;
  border: 0;
  outline: 0;
  padding: 6px 0;
  margin: 0;
  text-overflow: ellipsis;
  ${Body['body-short-01']};

  &::placeholder {
    color: ${cssVariables('neutral-6')};
  }
`;

const InputWrapper = styled.div<{ hasError: boolean; isOpen: boolean; size: SearchSelectionDropdownSize }>`
  display: flex;
  flex-direction: row;
  height: ${({ size }) => defaultDropdownSizeStyleMap[size].height}px;
  position: relative;
  padding: 0 36px 0 16px;
  border-radius: 4px;
  border: 1px solid ${cssVariables('neutral-4')};

  &:hover {
    border-color: ${cssVariables('purple-7')};

    ${Input} {
      color: ${cssVariables('purple-7')};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${ToggleButton} {
        transform: rotate(180deg);
      }
    `}

  &:focus {
    box-shadow: 0 0 0 2px ${cssVariables('purple-7')};
  }

  &:focus-within {
    border-color: ${cssVariables('purple-7')};

    ${Input} {
      color: ${cssVariables('neutral-10')};
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${cssVariables('red-5')};
    `}
`;

const ItemList = styled(DropdownMenuItemList)<{ isOpen: boolean }>`
  min-width: 100%;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.1s ${transitionDefault};
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  color: ${cssVariables('neutral-5')};
  text-align: center;
  ${Body['body-short-01']};
`;

const Error = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${cssVariables('red-5')};
  margin-top: 4px;
`;

const isItemStartsWithInputValue = (inputValue: string) => (item: string) =>
  item.toLowerCase().startsWith(inputValue.toLowerCase());

const defaultNewItemToElement = (item: string) => `${item} (new)`;

export const SearchSelectionDropdown = ({
  label,
  placeholder,
  initialSelectedItem,
  items,
  error,
  noResultsText = 'No results',
  onChange,
  itemToElement: itemToElementProp,
  newItemToElement = defaultNewItemToElement,
  size = 'medium',
  isCreatable = false,
  className,
  popperProps,
  disableNoResultsView = false,
}: SearchSelectionDropdownProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputItems, setInputItems] = useState(items);
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    setHighlightedIndex,
    openMenu,
    toggleMenu,
  } = useCombobox({
    items: inputItems,
    initialSelectedItem,
    onInputValueChange: ({ inputValue = '' }) => {
      const filteredItems = items.filter(isItemStartsWithInputValue(inputValue));
      setInputItems(
        isCreatable
          ? [...filteredItems, ...(!inputValue || filteredItems.includes(inputValue) ? [] : [inputValue])]
          : filteredItems,
      );
      setHighlightedIndex(0);
    },
    onSelectedItemChange: (changes) => {
      onChange?.(changes.selectedItem ?? null);
    },
    stateReducer: (state, { type, changes }) => {
      if (!state.isOpen && changes.isOpen) {
        // If it's being opened, show all items. If it's creatable, include selected item in the list.
        setInputItems([
          ...items,
          ...(state.selectedItem && !items.includes(state.selectedItem) ? [state.selectedItem] : []),
        ]);
      }
      if (
        type === useCombobox.stateChangeTypes.FunctionOpenMenu ||
        type === useCombobox.stateChangeTypes.FunctionToggleMenu
      ) {
        // prevent calling openMenu or toggleMenu from updating highlightedIndex
        return { ...changes, highlightedIndex: state.highlightedIndex };
      }
      if (type === useCombobox.stateChangeTypes.InputBlur) {
        // when input is blurred, reset the input value to selected item if possible.
        return { ...changes, inputValue: state.selectedItem || '' };
      }
      if (type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
        // when user selects an item by enter key, blur the input.
        inputRef.current?.blur();
      }
      return changes;
    },
  });

  useEffect(() => {
    // if items prop is updated, update the inputItems state.
    if (!isCreatable || inputRef.current == null || items.includes(inputRef.current.value)) {
      setInputItems(items);
      return;
    }
    const inputValue = inputRef.current.value;
    setInputItems([...items, inputValue]);
  }, [isCreatable, items]);

  const isItemNew = useCallback(
    (item: string) => {
      return !items.includes(item);
    },
    [items],
  );

  const itemToElement = useMemo(() => {
    if (itemToElementProp == null && newItemToElement == null) {
      return undefined;
    }

    return (item: string) => {
      const renderer = isItemNew(item) && selectedItem !== item ? newItemToElement : itemToElementProp;
      return (renderer ?? identity)(item);
    };
  }, [isItemNew, itemToElementProp, newItemToElement, selectedItem]);

  const handleContainerKeyEvent: KeyboardEventHandler<HTMLDivElement> = (event) => {
    inputRef.current?.focus();
    const inputKeyboardEvent = new KeyboardEvent(event.type, event as unknown as KeyboardEventInit);

    // prevent the event being propagated to the container back
    inputKeyboardEvent.stopPropagation();

    inputRef.current?.dispatchEvent(inputKeyboardEvent);
  };

  const handleInputFocus = () => {
    setHighlightedIndex(selectedItem ? -1 : 0);
    openMenu();
  };

  const handleToggleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setHighlightedIndex(-1);
    toggleMenu();
  };

  const { ref: comboboxPropsRef, ...comboboxProps } = getComboboxProps();

  const { ref: menuPropsRef, ...menuProps } = getMenuProps();
  return (
    <Manager>
      <Container className={className}>
        {label && <Label {...getLabelProps()}>{label}</Label>}
        <Reference innerRef={comboboxPropsRef}>
          {({ ref }) => {
            const inputRefCallback = (node: HTMLInputElement | null) => {
              getInputProps().ref(node);
              inputRef.current = node;
            };

            return (
              <InputWrapper
                {...comboboxProps}
                size={size}
                hasError={!!error}
                isOpen={isOpen}
                ref={ref}
                tabIndex={0}
                onFocus={() => openMenu()}
                onKeyPress={handleContainerKeyEvent}
                onKeyDown={handleContainerKeyEvent}
                onMouseLeave={() => {
                  setHighlightedIndex(-1);
                }}
                data-test-id="InputWrapper"
              >
                <Input
                  {...getInputProps()}
                  ref={inputRefCallback}
                  placeholder={placeholder}
                  data-test-id="Input"
                  onFocus={handleInputFocus}
                />
                <ToggleButton
                  {...getToggleButtonProps()}
                  type="button"
                  onClick={handleToggleButtonClick}
                  aria-label="toggle menu"
                  data-test-id="ToggleButton"
                >
                  <Icons.InputArrowDown size={20} color={cssVariables('neutral-9')} />
                </ToggleButton>
              </InputWrapper>
            );
          }}
        </Reference>
        <Popper placement="bottom-start" {...popperProps} innerRef={menuPropsRef}>
          {({ ref, style, placement }) => {
            return (
              <ItemList
                {...menuProps}
                ref={ref}
                isOpen={isOpen}
                style={style}
                data-placement={placement}
                data-test-id="ItemList"
              >
                {isOpen && (
                  <ScrollBar style={{ maxHeight: 240, padding: '8px 0' }}>
                    {inputItems.length > 0
                      ? inputItems.map((item, index) => (
                          <DropdownMenuItem
                            key={`${item}${index}`}
                            item={item}
                            index={index}
                            selectedItem={selectedItem}
                            highlightedIndex={highlightedIndex}
                            itemToElement={itemToElement}
                            itemProps={getItemProps({
                              item,
                              index,
                              'aria-selected': selectedItem === item,
                              'data-test-id': 'Item',
                              'data-is-highlighted': `${index === highlightedIndex}`,
                            } as any)}
                          />
                        ))
                      : !disableNoResultsView && <NoResults>{noResultsText}</NoResults>}
                  </ScrollBar>
                )}
              </ItemList>
            );
          }}
        </Popper>
        <Error data-test-id="Error">{error}</Error>
      </Container>
    </Manager>
  );
};
