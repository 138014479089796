import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DataExportFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M19.5 3H2v12.5A2.5 2.5 0 0 0 4.5 18h6.496V9.829l-1.83 1.828-1.416-1.414L11.998 6l.002.002.002-.002 4.248 4.243-1.416 1.414-1.835-1.833V18H19.5a2.5 2.5 0 0 0 2.5-2.5v-10A2.5 2.5 0 0 0 19.5 3zM22 19.998H2V22h20v-2.002z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DataExportFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DataExportFilledSvg} {...props} />
));
DataExportFilled.displayName = 'DataExportFilled';
export default DataExportFilled;
