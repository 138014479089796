import type { Ref, MutableRefObject } from 'react';
import { forwardRef } from 'react';

import type { ScrollBarRef, ScrollBarProps } from '@feather/types';

import { PerfectScrollbarWrapper } from './PerfectScrollbarWrapper';
import * as ScrollFunctionFactory from './ScrollFunctionFactory';
import { WebkitScrollbar } from './WebkitScrollbar';

const updateRef = <T,>(ref: Ref<T> | undefined, value: T) => {
  if (ref == null) {
    return;
  }
  if (typeof ref === 'function') {
    ref(value);
    return;
  }
  (ref as MutableRefObject<T>).current = value;
};

export const ScrollBar = forwardRef<ScrollBarRef | null, ScrollBarProps>(
  ({ useWebkitScrollbar = false, ...props }, ref) => {
    if (useWebkitScrollbar) {
      // exclude perfect-scrollbar options prop
      const { options, ...restProps } = props;
      return (
        <WebkitScrollbar
          {...restProps}
          ref={(node) => {
            if (node == null) {
              updateRef(ref, null);
              return;
            }

            const scrollTo = ScrollFunctionFactory.scrollTo(node);
            const scrollBy = ScrollFunctionFactory.scrollBy(node);

            const { clientHeight, scrollHeight, scrollTop } = node;

            updateRef(ref, {
              clientHeight,
              scrollTop,
              scrollHeight,
              scrollTo,
              scrollToTop: (options) => scrollTo({ left: 0, top: 0, behavior: options?.behavior }),
              scrollToBottom: (options) => scrollTo({ left: 0, top: scrollHeight, behavior: options?.behavior }),
              scrollBy,
              update: () => {
                // do nothing
              },
              node,
            });
          }}
        />
      );
    }
    return <PerfectScrollbarWrapper {...props} ref={ref} />;
  },
);

export * from './WebkitScrollbar';
export { default as scrollbarStyle } from './scrollbarStyle';
