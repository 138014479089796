import rgba from 'polished/lib/color/rgba';
import { css } from 'styled-components';

const scrollbarStyle = css`
  overflow: auto;

  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => `${rgba(theme.cssColors['neutral-3'], 0.6)} transparent`};

  // https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 5px;
    background-clip: padding-box;
    background-color: ${({ theme }) => rgba(theme.cssColors['neutral-3'], 0.6)};

    &:hover {
      background-color: ${({ theme }) => rgba(theme.cssColors['neutral-6'], 0.6)};
    }
  }

  &:not(:hover)::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export default scrollbarStyle;
