import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VideoSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m12.5 12.06-5 3v-6l5 3z" />
      <path
        fillRule="evenodd"
        d="M14.5 4H2v13.5A2.5 2.5 0 0 0 4.5 20h10a2.5 2.5 0 0 0 2.5-2.5v-2.504l5 4.125V4.88l-5 4.125V6.5A2.5 2.5 0 0 0 14.5 4zM4 17.5V6h10.5a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5zm16-2.621L16.51 12 20 9.121v5.758z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Video = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VideoSvg} {...props} />
));
Video.displayName = 'Video';
export default Video;
