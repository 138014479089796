import { memo } from 'react';

import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { animationBounceDelay } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { StylesProps } from '@feather/types';

type SpinnerProps = {
  spinnerBackground: string;
};

const Spinner = styled.div<StylesProps & SpinnerProps>`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${(props) => props.spinnerBackground};
  ${(props) => props.styles};
`;

type SpinnerInnerDotProps = {
  dotColor: string;
  dotSize?: {
    width: number;
    height: number;
  };
  dotStyles: SimpleInterpolation;
  delay?: string;
};

const SpinnerInnerDot = styled.div<SpinnerInnerDotProps>`
  width: 6px;
  height: 6px;
  margin: 0 1.5px;
  background: ${(props) => (props.dotColor ? props.dotColor : cssVariables('neutral-3'))};
  opacity: 0.6;
  border-radius: 50%;
  display: inline-block;
  animation: ${animationBounceDelay} 1.4s infinite ease-in-out both;
  animation-delay: ${(props) => (props.delay ? props.delay : '0s')};
  ${(props) =>
    props.dotSize &&
    css`
      width: ${props.dotSize.width}px;
      height: ${props.dotSize.height}px;
    `}
  ${(props) => props.dotStyles};
`;

const StyledSpinnerInner = styled.div`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
`;

type SpinnerInnerProps = {
  isFetching?: boolean;
  spinnerBackground?: string;
  spinnerStyles?: SimpleInterpolation;
  dotColor?: string;
  dotSize?: {
    width: number;
    height: number;
  };
  dotStyles?: SimpleInterpolation;
};

export const SpinnerInner = memo(
  ({
    isFetching,
    spinnerStyles = css``,
    spinnerBackground = 'transparent',
    dotColor = cssVariables('neutral-3'),
    dotSize = {
      width: 6,
      height: 6,
    },
    dotStyles,
    ...attrs
  }: SpinnerInnerProps) => {
    if (isFetching) {
      return (
        <StyledSpinnerInner role="progressbar" {...attrs}>
          <Spinner spinnerBackground={spinnerBackground} styles={spinnerStyles}>
            <SpinnerInnerDot delay="-0.32s" dotColor={dotColor} dotSize={dotSize} dotStyles={dotStyles} />
            <SpinnerInnerDot delay="-0.16s" dotColor={dotColor} dotSize={dotSize} dotStyles={dotStyles} />
            <SpinnerInnerDot dotColor={dotColor} dotSize={dotSize} dotStyles={dotStyles} />
          </Spinner>
        </StyledSpinnerInner>
      );
    }
    return null;
  },
);
