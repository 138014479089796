import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LightbulbSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4.5 9.474C4.5 5.342 7.862 2 12 2s7.5 3.342 7.5 7.474c0 2.238-.88 4.293-2.5 5.685v3.338H7.008V15.16C5.389 13.77 4.5 11.715 4.5 9.474zM7 20v2h10v-2H7z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Lightbulb = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LightbulbSvg} {...props} />
));
Lightbulb.displayName = 'Lightbulb';
export default Lightbulb;
