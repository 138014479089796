import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrOperationsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-op-a)" clipRule="evenodd">
        <path d="M10.885 4.224 11.233 2h3.526l.35 2.26c.06.381.322.692.678.842.36.15.779.126 1.096-.1L18.8 3.628l2.477 2.59-1.34 1.862c-.231.322-.248.755-.09 1.118.158.361.471.629.86.688l2.29.35v3.504l-2.352.424a1.095 1.095 0 0 0-.827.698c-.141.371-.115.8.116 1.123l1.343 1.884-2.5 2.5-1.847-1.346c-.33-.241-.777-.26-1.154-.1a1.155 1.155 0 0 0-.706.852L14.67 22h-3.504l-.348-2.242a1.123 1.123 0 0 0-.697-.862c-.363-.148-.786-.128-1.11.093l-1.885 1.292-2.5-2.5L5.962 16c.247-.33.268-.78.108-1.16a1.142 1.142 0 0 0-.844-.698l-2.23-.4v-3.483l2.254-.42c.387-.071.697-.342.846-.706.151-.368.13-.797-.094-1.126L4.715 6.13l2.5-2.5 1.862 1.358c.322.234.757.253 1.122.093.36-.157.626-.468.686-.856zm2.118 12.026c2.347 0 4.243-1.96 4.243-4.332 0-2.37-1.896-4.168-4.243-4.168-2.347 0-4.257 1.798-4.257 4.168 0 2.371 1.91 4.332 4.257 4.332z" />
        <path d="M12.996 13.25c.656 0 1.246-.563 1.246-1.303 0-.665-.514-1.197-1.246-1.197-.737 0-1.254.538-1.254 1.197 0 .734.592 1.302 1.254 1.302zm0 1.5c1.519 0 2.746-1.27 2.746-2.803 0-1.534-1.227-2.697-2.746-2.697-1.518 0-2.754 1.163-2.754 2.697s1.236 2.802 2.754 2.802z" />
      </g>
      <defs>
        <clipPath id="br-op-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrOperations = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrOperationsSvg} {...props} />
));
BrOperations.displayName = 'BrOperations';
export default BrOperations;
