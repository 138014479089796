import { keyframes } from 'styled-components';

import type { Transitions } from './types/animation';

const animationBounceDelay = () => keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const animationFadeInOut = () => keyframes`
  0% {
    opacity: 0;
  } 50% {
    opacity: 1;
  } 100% {
    opacity: 0;
  }
`;

const animationFadeIn = () => keyframes`
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
`;

const transitionDefault = 'cubic-bezier(.4, 0, .2, 1)';

const transitions: Transitions = ({ duration, properties }) =>
  properties.map((property) => `${property} ${duration}s ${transitionDefault}`).join(', ');

export { animationBounceDelay, animationFadeInOut, animationFadeIn, transitionDefault, transitions };
