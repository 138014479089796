import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DataExportSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M2 3v12.5A2.5 2.5 0 0 0 4.5 18H9v-2H4.5a.5.5 0 0 1-.5-.5V5h15.5a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H15v2h4.5a2.5 2.5 0 0 0 2.5-2.5v-10A2.5 2.5 0 0 0 19.5 3H2z" />
      <path d="M10.996 19.998H2V22h20v-2.002h-9.001V9.824l1.835 1.833 1.416-1.414L12.002 6 12 6.002 11.998 6 7.75 10.243l1.416 1.414 1.83-1.828v10.169z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DataExport = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DataExportSvg} {...props} />
));
DataExport.displayName = 'DataExport';
export default DataExport;
