import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { ColorMode } from '@feather/types';

const COLOR_MODE_STORAGE_KEY = '@featherColorMode';

type ColorModeContextType = {
  colorMode: ColorMode;
  toggleColorMode: () => void;
  setColorMode: (value: ColorMode) => void;
};

const ColorModeLocalStorage = {
  set: (colorMode: ColorMode) => {
    try {
      if (typeof localStorage === 'undefined') {
        throw new Error('There is no local storage on your environment');
      }

      localStorage.setItem(COLOR_MODE_STORAGE_KEY, colorMode);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
  get: () => {
    try {
      if (typeof localStorage === 'undefined') {
        throw new Error('There is no local storage on your environment');
      }

      return localStorage.getItem(COLOR_MODE_STORAGE_KEY) as ColorMode | null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return undefined;
    }
  },
};

const ColorModeContext = createContext({} as ColorModeContextType);

export const useColorMode = () => {
  const context = useContext(ColorModeContext);
  if (context === undefined) {
    throw new Error('useColorMode must be used within a ColorModeProvider');
  }
  return context;
};

const ColorModeProvider = ({ children }: { children: ReactNode }) => {
  const [colorMode, setColorMode] = useState(ColorModeLocalStorage.get() ?? 'light');

  const toggleColorMode = useCallback(() => {
    const toggledColorMode = colorMode === 'light' ? 'dark' : 'light';
    setColorMode(toggledColorMode);
  }, [colorMode]);

  const updateColorMode = useCallback((mode) => {
    setColorMode(mode);
  }, []);

  useEffect(() => {
    ColorModeLocalStorage.set(colorMode);
  }, [colorMode]);

  useEffect(() => {
    if (ColorModeLocalStorage.get() == null) {
      updateColorMode(colorMode);
    }
  }, [colorMode, updateColorMode]);

  const context = useMemo(
    () => ({
      colorMode,
      toggleColorMode,
      setColorMode: updateColorMode,
    }),
    [colorMode, toggleColorMode, updateColorMode],
  );

  return <ColorModeContext.Provider value={context}>{children}</ColorModeContext.Provider>;
};

export default ColorModeProvider;
