import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrLibrarySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-li-a)">
        <path d="M20 7.5H4V6h16v1.5zM18 4.5H6V3h12v1.5z" />
        <path
          fillRule="evenodd"
          d="M2 9h20v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9zm5.5 3.5h9V11h-9v1.5z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-li-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrLibrary = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrLibrarySvg} {...props} />
));
BrLibrary.displayName = 'BrLibrary';
export default BrLibrary;
