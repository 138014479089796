import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ReplyLinkFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g>
          <path
            fillRule="evenodd"
            d="M3 1.333h10c.92 0 1.666.746 1.666 1.667v4.666H10v-.001H4.666v1.333h4.667V12H4l-2.667 2.666V3c0-.92.746-1.667 1.667-1.667zm1.666 4.332h6.667V4.33H4.666v1.334z"
            clipRule="evenodd"
          />
          <path d="M10.666 10.333h2.39l-3.528 3.529.943.942L14 11.276v2.39h1.333V9h-4.667v1.333z" />
        </g>
      </g>
    </svg>
  )),
  { width: 16, height: 16 },
);
const ReplyLinkFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ReplyLinkFilledSvg} {...props} />
));
ReplyLinkFilled.displayName = 'ReplyLinkFilled';
export default ReplyLinkFilled;
