import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Badge } from '@feather/components/badge';
import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import { Typography } from '@feather/typography';

import { transitionDefault } from '../../animation';

type MenuGroupStyleProp = {
  $isHighlighted: boolean;
  $isLNBCollapsed: boolean;
  $isHovered: boolean;
  $isCollapsed: boolean;
};

type MenuItemStyleProp = {
  $shouldBoldLabel?: boolean;
  $isActive?: boolean;
  $isDisabled?: boolean;
};

const ITEM_STATE_TRANSITION_DURATION_SECOND = 0.1;
const COLLAPSED_ANIMATION_DURATION_SECOND = 0.15;
const COLLAPSE_THROTTLE_HOVER_TIME_MS = 300;

const menuIconCSS = css`
  flex: none;
  margin-right: 12px;
  transition: ${ITEM_STATE_TRANSITION_DURATION_SECOND}s fill ${transitionDefault};
`;

const MenuIconPlaceholder = styled.div`
  flex: none;
  position: relative;
  line-height: 0;
  margin-left: 2px;
  width: 20px;
  height: 20px;
  > svg:first-child {
    ${menuIconCSS}
  }
`;

const MenuBadge = styled(Badge)`
  position: absolute;
  left: 8px;
  top: -8px;
  padding: 0 4px;
`;

const SubMenuBadge = styled(Badge)`
  position: absolute;
  padding: 0 4px;
  left: 10px;
`;

const SubMenuBadgeArea = styled.div`
  flex: none;
  position: relative;
  width: 20px;
  height: 20px;
`;

const SubMenuItemLabel = styled.div<{ $isActive: boolean }>`
  white-space: pre-line;
  border-left: 1px solid ${cssVariables('border-3')};
  padding-left: 12px;
  min-height: 32px;
  height: 100%;
  line-height: 32px;
`;

const activeMenuItemCSS = css`
  color: ${cssVariables('purple-7')} !important;

  // this will be overridden by :hover selector style
  background-color: ${cssVariables('bg-primary-light')};

  svg {
    fill: ${cssVariables('purple-7')};
  }
  &:hover {
    background-color: ${cssVariables('bg-primary-light')};
  }

  ${SubMenuItemLabel} {
    border-left: 1px solid ${cssVariables('purple-7')};
  }
`;

const disabledMenuItemCSS = css`
  &:active,
  &:hover {
    color: ${cssVariables('neutral-8')};
    svg {
      fill: ${cssVariables('neutral-8')};
    }
  }
  &:active {
    background-color: ${cssVariables('bg-overlay-1')};
  }
`;

const menuItemCSS = (props: MenuItemStyleProp) => css`
  position: relative;
  flex: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 36px;
  padding-left: 6px;
  padding-right: 4px;
  margin: 0 8px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  font-weight: ${props.$shouldBoldLabel ? 400 : 500};
  text-decoration: none !important;
  white-space: nowrap;
  color: ${cssVariables('neutral-8')};
  user-select: none;
  outline: none;
  transition: ${ITEM_STATE_TRANSITION_DURATION_SECOND}s ${transitionDefault};
  transition-property: color, background-color;
  cursor: pointer;

  &:hover {
    color: ${cssVariables('neutral-8')};
    background-color: ${cssVariables('bg-overlay-1')};
  }

  &:active {
    color: ${cssVariables('content-primary')};
    background-color: ${cssVariables('bg-primary-light')};
    svg {
      fill: ${cssVariables('primary')};
    }
  }
  ${props.$isActive && activeMenuItemCSS}
`;

const MenuItem = styled.div<MenuItemStyleProp>`
  ${menuItemCSS}
`;

const MenuItemLink = styled.a<MenuItemStyleProp>`
  ${menuItemCSS}
`;

const highlightedMenuGroupCSS = css`
  background-color: ${cssVariables('bg-primary-light')};
  color: ${cssVariables('primary')};
  svg {
    fill: ${cssVariables('primary')} !important;
  }
  &:hover {
    color: ${cssVariables('primary')};
    background-color: ${cssVariables('bg-primary-light')};
  }
`;

const MenuGroupExpander = styled.button<MenuGroupStyleProp & MenuItemStyleProp>`
  ${menuItemCSS}
  display: flex;
  place-items: center;
  font-weight: 500;
  width: ${(props) => ((props.$isLNBCollapsed && props.$isHovered) || !props.$isLNBCollapsed ? '200px' : '36px')};
  border: none;
  background-color: ${cssVariables('bg-2')};
  ${(props) => props.$isHighlighted && highlightedMenuGroupCSS}
`;

const MenuItemReactRouterLink = styled(Link)<MenuItemStyleProp>`
  ${menuItemCSS}
  ${(props) => props.$isDisabled && disabledMenuItemCSS}
`;

const subMenuItemCSS = css`
  ${menuItemCSS};
  width: 200px;
  min-height: 32px;
  height: auto;
  padding-left: 48px;
  margin: 0 8px;
  border-radius: 8px;
`;
const SubMenuItem = styled.div<MenuItemStyleProp>`
  ${subMenuItemCSS}
`;

const SubMenuItemLink = styled.a<MenuItemStyleProp>`
  ${subMenuItemCSS}
`;

const SubMenuItemReactRouterLink = styled(Link)<MenuItemStyleProp>`
  ${subMenuItemCSS}
`;

const MenuExpanderIcon = styled(Icons.ChevronDown)<{
  $isCollapsed: boolean;
}>`
  transition: 0.3s transform ${transitionDefault};
  transform: rotate(0deg);
  margin-left: auto;

  ${(props) =>
    props.$isCollapsed &&
    css`
      transform: rotate(-90deg);
    `};
`;

const LabelItem = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  transition: ${COLLAPSED_ANIMATION_DURATION_SECOND}s ${transitionDefault};
  transition-property: color, height, padding;
  padding: 8px 16px 8px 20px;
  height: 36px;
  white-space: nowrap;
  color: ${cssVariables('content-3')};
  user-select: none;
  ${Typography['label-01']};
`;

const MenuItemLabel = styled.p<{ $isLNBCollapsed: boolean; $isLNBHovered: boolean }>`
  margin-left: 12px;
  ${(props) =>
    props.$isLNBCollapsed &&
    !props.$isLNBHovered &&
    css`
      color: transparent;
    `};
`;

const DividerItem = styled.div`
  position: relative;
  height: 16px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid ${cssVariables('neutral-3')};
  }
`;

export {
  COLLAPSED_ANIMATION_DURATION_SECOND,
  COLLAPSE_THROTTLE_HOVER_TIME_MS,
  MenuIconPlaceholder,
  MenuBadge,
  MenuItem,
  MenuItemLink,
  MenuGroupExpander,
  MenuItemReactRouterLink,
  SubMenuBadge,
  SubMenuBadgeArea,
  SubMenuItemLabel,
  SubMenuItem,
  SubMenuItemLink,
  SubMenuItemReactRouterLink,
  LabelItem,
  MenuExpanderIcon,
  MenuItemLabel,
  DividerItem,
};
