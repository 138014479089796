import type { ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { mediaQuery } from '@feather/media';

interface GridStyledProps {
  displayType?: 'grid' | 'inline-grid';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  templateColumn?: number;
  gap?: string[] | number;
}

interface GridItemBasicProps {
  justifySelf?: 'start' | 'end' | 'center' | 'stretch';
  alignSelf?: 'start' | 'end' | 'center' | 'stretch';
  colStart?: number;
  rowStart?: number;
  colSpan?: number;
  rowSpan?: number;
}

interface GridItemStyledProps extends GridItemBasicProps {
  sm?: GridItemBasicProps;
  md?: GridItemBasicProps;
  lg?: GridItemBasicProps;
  xl?: GridItemBasicProps;
}

export interface GridProps extends GridStyledProps {
  className?: string;
  children?: ReactNode;
}

export interface GridItemProps extends GridItemStyledProps {
  className?: string;
}

const generateMediaGridItem = (mediaProps: GridItemStyledProps) => {
  const validMediaKeys: string[] = [];

  if (mediaProps.xl) {
    validMediaKeys.push('xl');
  }

  if (mediaProps.lg) {
    validMediaKeys.push('lg');
  }

  if (mediaProps.md) {
    validMediaKeys.push('md');
  }

  if (mediaProps.sm) {
    validMediaKeys.push('sm');
  }

  return validMediaKeys
    .map((key) => {
      const media = mediaProps[key];
      return css`
        ${mediaQuery[key]`
          justify-self: ${media.justifySelf || 'stretch'};
          align-self: ${media.alignSelf ? media.alignSelf : 'stretch'};
          grid-column-start: ${media.colStart || 'auto'};
          grid-column-end: ${media.colSpan ? `span ${media.colSpan}` : null};
          grid-row-start: ${media.rowStart || 'auto'};
          grid-row-end: span ${media.rowSpan ? media.rowSpan : 1};
        `};
      `;
    })
    .reduce(
      (acc, curr) => css`
        ${acc}
        ${curr}
      `,
      css``,
    );
};

export const Grid = styled.div<GridStyledProps>`
  display: ${(props) => props.displayType || 'grid'};
  grid-template-columns: repeat(${(props) => props.templateColumn || 12}, 1fr);
  grid-gap: ${(props) => {
    if (props.gap && typeof props.gap !== 'number' && props.gap.length === 2) {
      return `${props.gap[0]} ${props.gap[1]}`;
    }

    if (typeof props.gap === 'number') {
      return `${props.gap}px`;
    }

    return '32px';
  }};
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
`;

export const GridItem = styled.div<GridItemStyledProps>`
  justify-self: ${(props) => (props.justifySelf ? props.justifySelf : 'stretch')};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'stretch')};
  grid-column-start: ${(props) => props.colStart || 'auto'};
  grid-column-end: ${(props) => (props.colSpan ? `span ${props.colSpan}` : null)};
  grid-row-start: ${(props) => props.rowStart || 'auto'};
  grid-row-end: span ${(props) => (props.rowSpan ? props.rowSpan : 1)};
  min-width: 0;

  ${(props) => generateMediaGridItem({ xl: props.xl, lg: props.lg, md: props.md, sm: props.sm })};
`;
