import type { MouseEventHandler } from 'react';
import { useState, useEffect, useMemo, useCallback } from 'react';

import styled, { css } from 'styled-components';

import { transitions } from '@feather/animation';
import * as Icons from '@feather/components/icons';
import { Spinner } from '@feather/components/spinner';
import cssVariables from '@feather/theme/cssVariables';
import type { BadgeProps, CSSVariableKey } from '@feather/types';
import { BadgeVariant } from '@feather/types';

const Container = styled.div<
  Omit<BadgeProps, 'count'> & { fontColor: string; isRemoved: boolean; isTransitioned: boolean }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  min-width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 1.09;
  font-weight: 500;
  color: ${(props) => props.fontColor};
  letter-spacing: 0.25px;
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;
  transform: scale(${({ isRemoved, isTransitioned }) => (!isRemoved && !isTransitioned ? 1 : 0)});
  transition: ${transitions({ duration: 0.3, properties: ['transform'] })};

  ${(props) =>
    props.variant === BadgeVariant.Dark
      ? css`
          background-color: ${cssVariables(`${props.color}-${props.color === 'neutral' ? 7 : 5}` as CSSVariableKey)};
        `
      : css`
          background-color: ${cssVariables([props.color, 2])};
          color: ${props.color === 'neutral' ? cssVariables('neutral-7') : cssVariables([props.color, 6])};
        `}
`;

const SpinnerWrapper = styled.div`
  width: 24px;
  text-align: center;
  line-height: 0;
  user-select: none;

  > * {
    display: inline-block;
  }
`;

const Button = styled.button`
  display: block;
  margin-left: 2px;
  margin-right: -2px;
  padding: 2px;
  font-size: 0;
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
`;

export const Badge = styled(
  ({
    count = 0,
    color,
    max = 999,
    variant = BadgeVariant.Light,
    className,
    isRemovable = false,
    isLoading = false,
    prefixNode,
    onClose,
  }: BadgeProps) => {
    const [isRemoved, setIsRemoved] = useState(true);
    const [isTransitioned, setIsTransitioned] = useState(false);

    const handleRemoveClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        event.preventDefault();
        setIsTransitioned(true);
        setTimeout(() => {
          setIsRemoved(true);
          setIsTransitioned(false);
        }, 300);

        onClose?.();
      },
      [onClose],
    );

    useEffect(() => {
      setIsRemoved(false);
    }, []);

    let contentColor = 'white';
    if (variant === BadgeVariant.Dark) {
      contentColor = color === 'yellow' ? cssVariables('neutral-10') : 'white';
    } else {
      contentColor = color === 'neutral' ? cssVariables('neutral-7') : cssVariables([color, 6]);
    }

    const children = useMemo(() => {
      if (isLoading) {
        return (
          <SpinnerWrapper>
            <Spinner stroke={contentColor} size={12} />
          </SpinnerWrapper>
        );
      }
      const refinedCount: string | number =
        count > max ? `${Number(max).toLocaleString()}+` : Number(count).toLocaleString();
      return (
        <>
          {refinedCount}
          {isRemovable && (
            <Button type="button" onClick={handleRemoveClick}>
              <Icons.Close size={12} color={contentColor} />
            </Button>
          )}
        </>
      );
    }, [contentColor, count, handleRemoveClick, isLoading, isRemovable, max]);

    if (isRemoved && isTransitioned) {
      return null;
    }

    return (
      <Container
        data-test-id="Badge"
        className={className}
        color={color}
        fontColor={contentColor}
        variant={variant}
        isRemoved={isRemoved}
        isTransitioned={isTransitioned}
      >
        {prefixNode}
        {children}
      </Container>
    );
  },
)``;
