import { useState } from 'react';

import { nanoid } from 'nanoid/non-secure';

const useInputId = (idPropValue?: string) => {
  const [id] = useState(nanoid());
  return idPropValue || id;
};

export default useInputId;
