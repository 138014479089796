import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TicketViewsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path fillRule="evenodd" d="m21 17 3-3h-8l3 3v4h2v-4z" clipRule="evenodd" />
      <path
        fillRule="evenodd"
        d="M2 4h20v8H11.171L17 17.828V20H2v-5a3 3 0 1 0 0-6V4zm7 1H7v2h2V5zM7 9h2v2H7V9zm2 4H7v2h2v-2zm-2 4h2v2H7v-2z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TicketViewsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TicketViewsFilledSvg} {...props} />
));
TicketViewsFilled.displayName = 'TicketViewsFilled';
export default TicketViewsFilled;
