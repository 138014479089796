import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ReportMessageSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10.833 1.084v3.083H9.166V1.084h1.667zM5.345 5.834 3.089 3.578 1.91 4.757l2.256 2.255 1.179-1.178z" />
      <path
        fillRule="evenodd"
        d="M2.5 18.334h15v-1.667h-1.667v-5a5.833 5.833 0 0 0-11.667 0v5H2.499v1.667zm11.666-6.667v5H5.833v-5a4.167 4.167 0 0 1 8.333 0z"
        clipRule="evenodd"
      />
      <path d="m15.833 7.013 2.256-2.256-1.179-1.179-2.256 2.256 1.179 1.179z" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const ReportMessage = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ReportMessageSvg} {...props} />
));
ReportMessage.displayName = 'ReportMessage';
export default ReportMessage;
