import type { MouseEventHandler } from 'react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { getLocalTimeZone } from '@internationalized/date';
import type { RangeValue } from '@react-types/shared';
import type { PopperChildrenProps } from 'react-popper';
import { Manager, Reference, Popper } from 'react-popper';
import styled, { css } from 'styled-components';

import { TooltipVariant } from '@feather/components/tooltip/types';
import { elevation } from '@feather/elevation';
import cssVariables from '@feather/theme/cssVariables';
import { Body } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

import { Button } from '../button';
import { Dropdown, DropdownToggleIcon } from '../dropdown';
import { toast } from '../notification';
import { ToggleText } from './ToggleText';
import RangeCalendar from './components/RangeCalendar';
import type {
  DateRange,
  DatePickerCommonProps,
  DateRangeDropdownItem,
  DatePickerDropdownProps,
  DateRangeItemLabel,
  DateRangeDropdownItems,
} from './types';
import { DateRangePickerValue } from './types';
import {
  convertDateRangePickerValueToDateRange,
  formatDateRange,
  getDropdownItems,
  useDefaultDateFormatter,
} from './utils';

const DateRangePickerWrapper = styled.div<{ fullWidth?: boolean }>`
  position: relative;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const DatePickerDropdownProxy = forwardRef<HTMLButtonElement, DatePickerDropdownProps>((props, ref) =>
  Dropdown<DateRangeDropdownItem>({ ...props, toggleButtonRef: ref }),
);

const DatePickerDropdown = styled(DatePickerDropdownProxy)<{ isDayPickerVisible: boolean }>`
  ${(props) =>
    props.isDayPickerVisible &&
    // If the calendar is visible, dropdown must be look like it's active.
    css`
      color: ${cssVariables('purple-7')} !important;
      border-color: ${cssVariables('purple-7')} !important;
      background-color: ${cssVariables('purple-2')} !important;

      ${DropdownToggleIcon} {
        fill: ${cssVariables('purple-7')} !important;
      }

      /* Arrow icon */
      ${DropdownToggleIcon} {
        transform: rotate(180deg);
      }
    `}
`;

const DropdownClickEventInterceptor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const CalendarWrapper = styled.div<{ width?: number | string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  border-radius: 4px;
  overflow: hidden;
  ${elevation.popover}
  z-index: ${ZIndexes.dropdownMenu};
  background: white;
  ${({ width }) =>
    width &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}
  padding: 12px 16px;
`;

const CustomDateWrapper = styled.div`
  display: flex;
  height: 100%;
  margin-left: 16px;
`;

const DateText = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 90px;
  height: 100%;
  text-align: left;
  line-height: 0px;
  font-size: 14px;
  ${Body['body-short-01']}

  ${(props) =>
    props.$isSelected &&
    css`
      line-height: 1px;
      box-shadow: inset 0 -2px 0 0 ${cssVariables('primary')};
    `}
`;

const DateDivider = styled.div`
  &::after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 100%;
    content: '–';
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: end;
  margin: 16px -16px -12px -16px;
  padding: 12px 16px;
  box-shadow: inset ${cssVariables('neutral-3')} 0 1px;
  z-index: 1; /* <- should guarantee inset-shadow not be hidden by the calendar */
`;

export type SelectableDateRangePickerProps = DatePickerCommonProps & {
  cancelText?: string;
  confirmText?: string;
  dateRange?: DateRange;
  dayPickerProps?: Pick<DatePickerDropdownProps, 'positionFixed' | 'modifiers'>;
  dropdownProps?: Omit<
    DatePickerDropdownProps,
    'placement' | 'selectedItem' | 'itemType' | 'items' | 'onChange' | 'tooltipProps'
  > & { tooltipProps?: Omit<NonNullable<DatePickerDropdownProps['tooltipProps']>, 'content'> }; // Content must be determined by DateRangePicker component itself.
  endDateText?: string;
  fullWidth?: boolean;
  itemLabel?: DateRangeItemLabel;
  maximumNights?: number;
  minimumNights?: number;
  onChange: (value: DateRangePickerValue, dateRange?: DateRange) => void;
  portalId?: string;
  startDateText?: string;
  value: DateRangePickerValue;
};
const SelectableDateRangePicker = ({
  cancelText = 'Cancel',
  confirmText = 'Apply',
  dateRange,
  dayPickerProps,
  disabled = false,
  dropdownProps,
  endDateText = 'End date',
  formatDate,
  fullWidth = false,
  itemLabel,
  maxDate,
  maximumNights = undefined,
  minDate,
  minimumNights = 0,
  onChange,
  placement = 'bottom-start',
  portalId = 'portal',
  startDateText = 'Start date',
  size = 'medium',
  timeZone = getLocalTimeZone(),
  value,
}: SelectableDateRangePickerProps) => {
  const [dayPickerRange, setDayPickerRange] = useState<
    { startDate: CalendarDate | null; endDate: CalendarDate | null } | undefined
  >();
  const [focusedInput, setFocusedInput] = useState<keyof DateRange | null>(null);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const dropdownClickEventInterceptorRef = useRef<HTMLDivElement>(null);
  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();
  const defaultDateFormatter = useDefaultDateFormatter();
  const [tempMaxDate, setTempMaxDate] = useState<CalendarDate | null>(null);
  const [tempMinDate, setTempMinDate] = useState<CalendarDate | null>(null);

  const dateRangeDropdownItems: DateRangeDropdownItems = {
    today: {
      value: DateRangePickerValue.Today,
      label: itemLabel?.today ?? DateRangePickerValue.Today,
    },
    yesterday: {
      value: DateRangePickerValue.Yesterday,
      label: itemLabel?.yesterday ?? DateRangePickerValue.Yesterday,
    },
    last7Days: {
      value: DateRangePickerValue.Last7Days,
      label: itemLabel?.last7Days ?? DateRangePickerValue.Last7Days,
    },
    last14Days: {
      value: DateRangePickerValue.Last14Days,
      label: itemLabel?.last14Days ?? DateRangePickerValue.Last14Days,
    },
    last30Days: {
      value: DateRangePickerValue.Last30Days,
      label: itemLabel?.last30Days ?? DateRangePickerValue.Last30Days,
    },
    last90Days: {
      value: DateRangePickerValue.Last90Days,
      label: itemLabel?.last90Days ?? DateRangePickerValue.Last90Days,
    },
    last180Days: {
      value: DateRangePickerValue.Last180Days,
      label: itemLabel?.last180Days ?? DateRangePickerValue.Last180Days,
    },
    custom: {
      value: DateRangePickerValue.Custom,
      label: itemLabel?.custom ?? DateRangePickerValue.Custom,
    },
  };

  const isDayPickerVisible = !!(focusedInput && dayPickerRange);
  const selectedItem = isDayPickerVisible
    ? dateRangeDropdownItems.custom
    : Object.values(dateRangeDropdownItems).find((item) => item.value === value);

  const hideCalendar = () => {
    setFocusedInput(null);
  };

  const getToggleText = (item?: DateRangeDropdownItem | null) => {
    if (!item) return '';
    if (item.value === DateRangePickerValue.Custom) {
      const toggleDisplayedDateRange: DateRange | undefined =
        dayPickerRange && dayPickerRange.startDate && dayPickerRange.endDate
          ? (dayPickerRange as DateRange)
          : dateRange;

      if (toggleDisplayedDateRange) {
        return formatDateRange(toggleDisplayedDateRange, formatDate ?? defaultDateFormatter, timeZone);
      }
    }
    return item.label;
  };

  const toggleText = getToggleText(selectedItem);

  useEffect(() => {
    if (!!dateRange && selectedItem?.value === DateRangePickerValue.Custom) {
      setDayPickerRange(dateRange);
    }
  }, [dateRange, selectedItem?.value]);

  useEffect(() => {
    const ARROW_WIDTH = 34;

    if (dropdownClickEventInterceptorRef.current && dropdownRef.current) {
      dropdownClickEventInterceptorRef.current.style.zIndex = String(
        getComputedStyle(dropdownClickEventInterceptorRef.current).zIndex + 1,
      );
      dropdownClickEventInterceptorRef.current.style.width = `${ARROW_WIDTH}px`;
      dropdownClickEventInterceptorRef.current.style.height = `${dropdownRef.current.clientHeight}px`;
      dropdownClickEventInterceptorRef.current.style.left = `${dropdownRef.current.clientWidth - ARROW_WIDTH}px`;

      scheduleUpdateRef.current?.();
    }
  }, [toggleText, size, isDayPickerVisible]);

  const toggleRenderer: DatePickerDropdownProps['toggleRenderer'] = ({ selectedItem }) => {
    if (selectedItem?.value === 'Custom') {
      const startDate = dayPickerRange?.startDate;
      const endDate = dayPickerRange?.endDate;

      return (
        <CustomDateWrapper>
          <DateText
            // FIXME(DFE-758): startDate marker disabled temporarily
            // $isSelected={focusedInput === 'startDate'}
            onClick={(e) => {
              e.stopPropagation();
              setFocusedInput('startDate');
            }}
          >
            {(startDate && (formatDate ?? defaultDateFormatter)(startDate)) || startDateText}
          </DateText>
          <DateDivider
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <DateText
            // FIXME(DFE-758): endDate marker disabled temporarily
            // $isSelected={focusedInput === 'endDate'}
            onClick={(e) => {
              e.stopPropagation();
              setFocusedInput('endDate');
            }}
          >
            {(endDate && (formatDate ?? defaultDateFormatter)(endDate)) || endDateText}
          </DateText>
        </CustomDateWrapper>
      );
    }
    return <ToggleText size={size}>{getToggleText(selectedItem)}</ToggleText>;
  };

  const notifyChange = (value: DateRangePickerValue, customDateRange?: DateRange) => {
    if (value === DateRangePickerValue.Custom && customDateRange == null) {
      return;
    }

    const dateRange =
      value === DateRangePickerValue.Custom ? customDateRange : convertDateRangePickerValueToDateRange(value, timeZone);
    onChange?.(value, dateRange);
  };

  const onItemSelected: DatePickerDropdownProps['onItemSelected'] = (item) => {
    if (item == null) {
      return;
    }
    if (onChange == null) {
      return;
    }

    if (item.value === DateRangePickerValue.Custom) {
      // Don't call onChange until a custom date range is set.
      setDayPickerRange(dateRange || { startDate: null, endDate: null });
      setFocusedInput('startDate');
      return;
    }
    notifyChange(item.value);
  };

  const onDropdownClickEventInterceptorClick: MouseEventHandler<HTMLDivElement> = () => {
    setDayPickerRange(dateRange);
    hideCalendar();
  };

  const handleAfterRangeChange = () => {
    setTempMinDate(null);
    setTempMaxDate(null);
  };
  const handleBeforeRangeChange = (range: { start: CalendarDate; end: CalendarDate }) => {
    setTempMinDate(range.start);
    setTempMaxDate(range.end);
  };

  const handleChange = ({ start: startDate, end: endDate }: RangeValue<CalendarDate>) => {
    const startDateObject = startDate.toDate(timeZone);
    const endDateObject = endDate.toDate(timeZone);
    setDayPickerRange({ startDate, endDate });
    if (startDateObject && endDateObject) {
      if (maximumNights && endDate.compare(startDate.add({ days: maximumNights })) > 0) {
        if (focusedInput === 'startDate') {
          setDayPickerRange({ startDate, endDate: null });
          setFocusedInput('endDate');
        } else {
          setDayPickerRange({ startDate: null, endDate });
          setFocusedInput('startDate');
        }
      } else {
        // If both startDate and endDate are defined, set focusedInput to 'startDate'
        // so that the next click on a day will set the start date.
        setFocusedInput('startDate');
      }
    }
  };

  const onDayPickerCancelButtonClick = () => {
    setDayPickerRange(dateRange);
    hideCalendar();
  };

  const onDayPickerApplyButtonClick = () => {
    if (dayPickerRange == null) {
      return;
    }
    const { startDate, endDate } = dayPickerRange;
    if (startDate == null) {
      toast.error({ message: 'Select the start date.' });
      return;
    }
    if (endDate == null) {
      toast.error({ message: 'Select the end date.' });
      return;
    }

    notifyChange(DateRangePickerValue.Custom, { startDate, endDate });
    hideCalendar();
  };

  const tooltipText = (() => {
    if (value === DateRangePickerValue.Custom || isDayPickerVisible) {
      return undefined;
    }

    const specificDateRange = convertDateRangePickerValueToDateRange(value, timeZone);
    return specificDateRange
      ? formatDateRange(specificDateRange, formatDate ?? defaultDateFormatter, timeZone)
      : undefined;
  })();

  const dropdownWidth = useMemo(() => {
    if (dropdownProps?.width) return dropdownProps.width;
    return fullWidth ? '100%' : 'auto';
  }, [fullWidth, dropdownProps?.width]);

  return (
    <DateRangePickerWrapper fullWidth={fullWidth}>
      <Manager>
        <Reference innerRef={dropdownRef}>
          {({ ref }) => (
            <>
              <DatePickerDropdown
                ref={ref}
                isDayPickerVisible={isDayPickerVisible}
                selectedItem={selectedItem}
                itemsType="section"
                items={getDropdownItems({
                  dropdownItems: dateRangeDropdownItems,
                  hasLast180Days: true,
                  minimumNights,
                  maximumNights,
                })}
                size={size}
                toggleRenderer={toggleRenderer}
                onItemSelected={onItemSelected}
                itemToString={(item) => item.label}
                placement={placement}
                disabled={disabled}
                toggleButtonProps={{
                  onClick: (e) => {
                    if (focusedInput) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  },
                }}
                {...dropdownProps}
                tooltipProps={
                  tooltipText
                    ? {
                        variant: TooltipVariant.Light,
                        content: tooltipText,
                        placement: 'top',
                        ...dropdownProps?.tooltipProps,
                      }
                    : undefined
                }
                width={dropdownWidth}
              />
            </>
          )}
        </Reference>
        {focusedInput && dayPickerRange && (
          <DropdownClickEventInterceptor
            ref={dropdownClickEventInterceptorRef}
            onClick={onDropdownClickEventInterceptorClick}
          />
        )}

        {focusedInput && dayPickerRange && (
          <Popper
            placement={placement}
            positionFixed={dayPickerProps?.positionFixed}
            modifiers={dayPickerProps?.modifiers}
          >
            {({ ref, style, scheduleUpdate }) => {
              scheduleUpdateRef.current = scheduleUpdate;
              return (
                <CalendarWrapper ref={ref} style={{ ...style, top: 2 }}>
                  <RangeCalendar
                    isDisabled={disabled}
                    maximumNights={maximumNights}
                    maxValue={tempMaxDate ?? maxDate ?? undefined}
                    minimumNights={minimumNights}
                    minValue={tempMinDate ?? minDate ?? undefined}
                    monthPicker={true}
                    monthPickerPortalId={portalId}
                    onAfterRangeChange={handleAfterRangeChange}
                    onBeforeRangeChange={handleBeforeRangeChange}
                    onChange={handleChange}
                    timeZone={timeZone}
                    value={
                      dayPickerRange?.startDate && dayPickerRange.endDate
                        ? {
                            start: dayPickerRange.startDate,
                            end: dayPickerRange.endDate,
                          }
                        : undefined
                    }
                  />
                  <ButtonContainer>
                    <Button buttonType="tertiary" size="small" onClick={onDayPickerCancelButtonClick}>
                      {cancelText}
                    </Button>
                    <Button
                      buttonType="primary"
                      size="small"
                      onClick={onDayPickerApplyButtonClick}
                      disabled={!dayPickerRange.startDate || !dayPickerRange.endDate}
                    >
                      {confirmText}
                    </Button>
                  </ButtonContainer>
                </CalendarWrapper>
              );
            }}
          </Popper>
        )}
      </Manager>
      <div id="portal" />
    </DateRangePickerWrapper>
  );
};

export default SelectableDateRangePicker;
