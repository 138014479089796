import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallP2PFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.297 2.41 13.883.996l-3.707 3.707 3.707 3.707 1.414-1.414-1.293-1.293h5.172l-1.293 1.293 1.414 1.414 3.707-3.707L19.297.996 17.883 2.41l1.293 1.293h-5.172l1.293-1.293zM19 16.192a2.012 2.012 0 0 0-1.45-1.941l-3.526-1.027a2.01 2.01 0 0 0-2.107.644L9.87 16.326a15.184 15.184 0 0 1-4.196-4.198l2.454-2.046a2.012 2.012 0 0 0 .641-2.115l-1.024-3.47a2.011 2.011 0 0 0-1.906-1.441L1 3l.047 1.063c.194 4.36 1.875 8.57 5.097 11.793 3.222 3.222 7.43 4.904 11.789 5.098l1.045.046.022-4.808z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallP2PFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallP2PFilledSvg} {...props} />
));
CallP2PFilled.displayName = 'CallP2PFilled';
export default CallP2PFilled;
