import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const Delete3MinsTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 4.5A2.5 2.5 0 0 1 8.5 2H11v2H8.5a.5.5 0 0 0-.5.5V6H6V4.5zM11 7.5H4v.017H2v2h2V19.5A2.5 2.5 0 0 0 6.5 22h11a2.5 2.5 0 0 0 2.5-2.5V13h-2v6.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5V9.517h5V7.5z" />
      <path d="M11 12H9v6h2v-6zM13 13v5h2v-5h-2z" />
      <path
        fillRule="evenodd"
        d="M23 1H13v10h10V1zm-5.838 5.32v-.864l.92-.96h-2.065V3.2h3.977v.872l-1.216 1.232c.744.136 1.256.792 1.256 1.624 0 1.136-.88 1.952-2.104 1.952-1.145 0-2.008-.752-2.072-1.808h1.351c.056.248.28.488.72.488.417 0 .72-.272.72-.672 0-.616-.727-.792-1.488-.568z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Delete3MinsTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={Delete3MinsTwotoneSvg} {...props} />
));
Delete3MinsTwotone.displayName = 'Delete3MinsTwotone';
export default Delete3MinsTwotone;
