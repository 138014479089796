import type { ComponentProps, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { Avatar } from '@feather/components/avatar';
import cssVariables from '@feather/theme/cssVariables';
import { Body, Typography } from '@feather/typography';

type TwoLineDropdownItemProps = {
  title: ReactNode;
  subtitle: ReactNode;
  isSelected?: boolean;
  className?: string;
};

type TwoLineDropdownItemWithAvatarProps = TwoLineDropdownItemProps & {
  avatar: Omit<ComponentProps<typeof Avatar>, 'size' | 'className' | 'children'>;
};

const Title = styled.div`
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${cssVariables('neutral-10')};
  ${Body['body-short-01']};
`;

const Subtitle = styled.div`
  min-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${cssVariables('neutral-7')};
  ${Typography['caption-01']};
`;

const StyledAvatar = styled(Avatar)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Container = styled.div<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      ${Title}, ${Subtitle} {
        color: ${cssVariables('purple-7')};
      }
    `}
`;

const TwoLineDropdownItemWithAvatarContainer = styled(Container)`
  position: relative;
  padding-left: 44px;

  & + svg {
    top: 6px;
    margin-top: 0;
  }
`;

export const TwoLineDropdownItem = ({ title, subtitle, isSelected = false, className }: TwoLineDropdownItemProps) => {
  return (
    <Container className={className} $isSelected={isSelected}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

export const TwoLineDropdownItemWithAvatar = ({
  title,
  subtitle,
  avatar,
  isSelected = false,
  className,
}: TwoLineDropdownItemWithAvatarProps) => {
  return (
    <TwoLineDropdownItemWithAvatarContainer $isSelected={isSelected} className={className}>
      <StyledAvatar size="medium" {...avatar} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TwoLineDropdownItemWithAvatarContainer>
  );
};
