import type { FocusEventHandler, MouseEventHandler } from 'react';

const onMouseUp: MouseEventHandler<HTMLElement> = ({ currentTarget }) => {
  currentTarget.style.boxShadow = 'none';
};

const onFocus: FocusEventHandler<HTMLElement> = ({ currentTarget }) => {
  currentTarget.style.boxShadow = '';
};

const onMouseDown: MouseEventHandler<HTMLElement> = ({ currentTarget }) => {
  currentTarget.style.boxShadow = '';
};

export { onMouseDown, onMouseUp, onFocus };
