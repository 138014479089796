import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LoactionFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.5 10.408C3.5 5.752 7.317 2 12 2s8.5 3.752 8.5 8.408c0 1.579-.58 2.963-1.343 4.171-.72 1.14-1.646 2.189-2.477 3.13L12 23l-4.68-5.29c-.831-.942-1.758-1.99-2.477-3.13C4.08 13.37 3.5 11.986 3.5 10.407zM12 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LoactionFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LoactionFilledSvg} {...props} />
));
LoactionFilled.displayName = 'LoactionFilled';
export default LoactionFilled;
