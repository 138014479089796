import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UnrealColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#000"
        fillRule="evenodd"
        d="M19.131 13.729c-.207.997-1.127 3.556-4.06 4.942l-1.18-1.325-1.988 1.999A7.338 7.338 0 0 1 6.1 16.368c.211.066.43.107.65.123.326.006.678-.114.678-.66v-5.394a.89.89 0 0 0-1.116-.89c-.921.211-1.656 2.508-1.656 2.508a7.304 7.304 0 0 1 2.527-5.597 7.408 7.408 0 0 1 3.73-1.72c-1.005.573-1.57 1.507-1.57 2.29 0 1.261.76 1.109.985.923v7.28c.038.091.088.177.148.256a1.074 1.074 0 0 0 .88.445c.76 0 1.746-.868 1.746-.868V9.172c0-.6-.451-1.324-.904-1.573 0 0 .838-.148 1.484.347.121-.148.25-.29.386-.425 1.509-1.48 2.93-1.902 4.113-2.112 0 0-2.152 1.69-2.152 3.96 0 1.686.044 5.801.044 5.801.799.771 1.986-.343 3.058-1.441z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 23.52A11.52 11.52 0 1 1 12 .477a11.52 11.52 0 0 1 0 23.042z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UnrealColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UnrealColoredSvg} {...props} />
));
UnrealColored.displayName = 'UnrealColored';
export default UnrealColored;
