import type { ReactNode } from 'react';

import type { SimpleInterpolation } from 'styled-components';

export enum AlertStatus {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  // WARNING = 'WARNING',
  INFO = 'INFO',
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export enum AlertSlideFrom {
  TOP = 'TOP',
  // RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
}

export enum AlertMessageAlign {
  // RIGHT = 'right',
  LEFT = 'left',
  // CENTER = 'center',
}

export interface ContainerProps {
  show: boolean;
  status: AlertStatus;
  align: AlertMessageAlign;
  slideFrom?: AlertSlideFrom;
}

export type AlertProps = {
  message?: ReactNode;
  innerHTML?: string;
  show: boolean;
  status: AlertStatus;
  align?: AlertMessageAlign;
  slideFrom?: AlertSlideFrom;
  styles?: {
    CONTAINER?: SimpleInterpolation;
    MESSAGE?: SimpleInterpolation;
  };
};
