// @deprecated
import { createContext, useContext } from 'react';

/**
 * Global theme
 *
 * #### Why not use styled-components ThemeProvider?
 *
 * The project that imports Feather may already be using styled-components ThemeProvider. We don't want to have a
 * conflict between the theme of the project and the theme of Feather.
 */

export enum FeatherThemeEnum {
  Default = 'default',
  Neutral = 'neutral',
  Purple = 'purple',
  Gray = 'gray',
}

export const FeatherThemeContext = createContext(FeatherThemeEnum.Default);

export const useFeatherTheme = () => useContext(FeatherThemeContext);
