import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrEventsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-ev-a)">
        <path
          fillRule="evenodd"
          d="M22.992 8h-20v11a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8zm-10.994 9 4.994-4.955L15.94 11l-3.942 3.911-1.954-1.94-1.052 1.046L11.998 17z"
          clipRule="evenodd"
        />
        <path d="M6.992 3h1.5v1h8.996V3h1.5v1h1.254a2.75 2.75 0 0 1 2.75 2.75V7h-1.5v-.25c0-.69-.56-1.25-1.25-1.25h-14.5c-.69 0-1.25.56-1.25 1.25V7h-1.5v-.25A2.75 2.75 0 0 1 5.742 4h1.25V3z" />
      </g>
      <defs>
        <clipPath id="br-ev-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrEvents = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrEventsSvg} {...props} />
));
BrEvents.displayName = 'BrEvents';
export default BrEvents;
