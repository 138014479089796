import { useMemo } from 'react';

import type { DateDuration } from '@internationalized/date';
import { endOfMonth, getWeeksInMonth, startOfMonth } from '@internationalized/date';
import { useCalendarGrid } from '@react-aria/calendar';
import type { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import styled from 'styled-components';

import { cssVariables } from '@feather/theme';
import { Body } from '@feather/typography';

import CalendarCell from './CalendarCell';
import { CalendarType, useCalendarOptions } from './OptionsContext';

const Table = styled.table`
  min-width: 224px;
  border-collapse: collapse;
  border: none;
`;

const WeekdayCell = styled.th`
  width: 32px;
  height: 32px;
  ${Body['body-short-01']};
  color: ${cssVariables('content-3')};
`;

type Props = {
  offset?: DateDuration;
  state: CalendarState | RangeCalendarState;
};
const CalendarGrid = ({ offset = {}, state }: Props) => {
  const { locale, type } = useCalendarOptions();

  const calendarGridProps = useMemo(() => {
    const startDate = startOfMonth(state.visibleRange.start.add(offset));
    const endDate = endOfMonth(startDate);
    return { startDate, endDate };
  }, [offset, state.visibleRange.start]);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(calendarGridProps, state);

  const weeksInMonth = getWeeksInMonth(calendarGridProps.startDate, locale);

  return (
    <Table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <WeekdayCell key={index}>{day}</WeekdayCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex, calendarGridProps.startDate).map((date, i) => {
              if (!date) {
                return <td key={i} />;
              }
              return type === CalendarType.Calendar ? (
                <CalendarCell
                  key={i}
                  currentMonth={calendarGridProps.startDate}
                  date={date}
                  state={state as CalendarState}
                  type={CalendarType.Calendar}
                />
              ) : (
                <CalendarCell
                  key={i}
                  currentMonth={calendarGridProps.startDate}
                  date={date}
                  state={state as RangeCalendarState}
                  type={CalendarType.RangeCalendar}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CalendarGrid;
