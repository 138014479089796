import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NotificationOffFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m21.586 23-5.5-5.5H3.243l1.315-2.215a10.356 10.356 0 0 0 1.453-5.29c0-.763.143-1.494.403-2.167l-5.41-5.41 1.414-1.414L23 21.586 21.586 23zM19.337 15.11a10.306 10.306 0 0 1-1.359-5.114A5.994 5.994 0 0 0 13 4.084V3a1 1 0 1 0-2 0v1.082c-.71.12-1.379.364-1.98.71l10.317 10.319z" />
      <path d="M12 20a1 1 0 0 1-1-1H9a3 3 0 1 0 6 0h-2a1 1 0 0 1-1 1z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NotificationOffFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NotificationOffFilledSvg} {...props} />
));
NotificationOffFilled.displayName = 'NotificationOffFilled';
export default NotificationOffFilled;
