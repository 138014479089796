import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UnityColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#222C37"
        fillRule="evenodd"
        d="M16.859 1.402c.574-.14 1.117-.274 1.559-.386L20.689.5l.294.674c.273.672 1.111 3.343 2.042 6.618L23.5 9.43l-.657 1.121c-.386.606-.681 1.212-.681 1.324 0 .112.294.695.657 1.3l.658 1.123-.545 1.975c-.211.787-.561 2.067-.884 3.249v.001c-.13.47-.254.925-.364 1.327-.385 1.414-.726 2.604-.771 2.648-.023.023-.84-.157-1.814-.404-3.901-1.03-6.827-1.817-7.213-1.929-.227-.067-.703-.672-1.089-1.346l-.703-1.212h-2.79L.5 11.877l6.804-6.732h2.836l.68-1.212.68-1.189 2.336-.583c.82-.218 1.973-.501 3.023-.759zm-2.19 6.167a145.87 145.87 0 0 0 1.918-3.461h.001c.109-.293-.502-.203-2.899.472-1.198.337-2.55.697-2.986.764-.676.135-1.199.563-3.16 2.72C6.277 9.48 5.165 10.693 5.1 10.738c-.044.068 1.657.112 3.814.112h3.902l1.852-3.281zm4.886-1.737.209.772v.002l.725 2.677.711 2.72-.623 2.309c-.333 1.28-.756 2.88-.934 3.542-.177.685-.422 1.143-.51 1.029-.09-.092-1.001-1.646-2.002-3.451-1.223-2.195-1.779-3.383-1.667-3.658.266-.753 3.558-6.651 3.714-6.674.066 0 .244.343.378.731l-.001.001zm-2.967 14.214c.11-.088-.524-1.254-2.839-5.174h.001l-.961-1.65-3.888-.067c-2.38-.021-3.844.022-3.8.155.022.11 1.136 1.298 2.424 2.641l2.38 2.444 3.299.88c1.813.463 3.341.815 3.384.771z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UnityColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UnityColoredSvg} {...props} />
));
UnityColored.displayName = 'UnityColored';
export default UnityColored;
