import styled from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { OverflowMenuProps, IconButtonProps } from '@feather/types';

import { OverflowMenu } from '../OverflowMenu';
import { IconButton, generateButtonContainerStyle } from '../button';

const StyleOverriddenOverflowMenu = styled(OverflowMenu)`
  ${IconButton} {
    flex: none;
    width: 40px;
    height: 40px;

    ${generateButtonContainerStyle({
      contentColor: 'white',
      disabledContentColor: 'white',
      bgColor: 'transparent',
      hoverBgColor: cssVariables('purple-8'),
      activeBgColor: cssVariables('purple-9'),
      pressedBgColor: cssVariables('purple-8'),
      disabledBgColor: 'transparent',
      borderColor: 'transparent',
      disabledBorderColor: 'transparent',
      focusOutlineColor: 'white',
    })}

    svg {
      fill: white !important;
    }
  }
`;

const GNBOverflowMenuStyleable = (
  props: Omit<OverflowMenuProps, 'iconButtonProps'> & {
    iconButtonProps?: Partial<Omit<IconButtonProps, 'buttonType' | 'size'>>;
  },
) => {
  return (
    <StyleOverriddenOverflowMenu
      {...props}
      iconButtonProps={props.iconButtonProps && { ...props.iconButtonProps, size: 'small' }}
    />
  );
};

export const GNBOverflowMenu = styled(GNBOverflowMenuStyleable)``;
