import type { SimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { SortOrder } from '@feather/types';

type SortOrderIndicatorProps = {
  order?: SortOrder;
  className?: string;
  styles?: SimpleInterpolation;
};

const Container = styled.div<{ styles: SimpleInterpolation }>`
  width: 16px;
  height: 16px;
  position: relative;
  transition: opacity 0.2s ${transitionDefault};
  ${(props) => props.styles}
`;

const Arrow = styled.div<{ color: string; direction: 'up' | 'down' }>`
  position: absolute;
  width: 0;
  height: 0;
  border-width: ${(props) => (props.direction === 'up' ? 0 : '5px')} 4px
    ${(props) => (props.direction === 'down' ? 0 : '5px')} 4px;
  border-color: ${(props) => props.color} transparent ${(props) => props.color} transparent;
  border-style: solid;
  transition: border-color 0.2s ${transitionDefault};
`;

const UpArrow = styled(Arrow).attrs({ direction: 'up' })`
  top: 2px;
  left: 4px;
`;

const DownArrow = styled(Arrow).attrs({ direction: 'down' })`
  bottom: 2px;
  left: 4px;
`;

export const SortOrderIndicator = ({ order, className, styles }: SortOrderIndicatorProps) => {
  return (
    <Container className={className} styles={styles}>
      <UpArrow color={order === 'ascend' ? cssVariables('purple-7') : cssVariables('neutral-6')} />
      <DownArrow color={order === 'descend' ? cssVariables('purple-7') : cssVariables('neutral-6')} />
    </Container>
  );
};
