import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NumberSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m11.72 9.47 1.131-1.216h-2.33V7.055h4.095v.819l-1.402 1.444c.845.084 1.453.785 1.453 1.697 0 1.19-.912 2.035-2.179 2.035-1.173 0-2.06-.76-2.136-1.832h1.25c.067.329.371.616.886.616.524 0 .895-.355.895-.853 0-.709-.81-.954-1.663-.709V9.47zM5.31 12.966v-.896l1.9-1.857c.625-.608.92-.87.92-1.334 0-.397-.354-.693-.802-.693-.582 0-.886.431-.852 1.064H5.218c-.06-1.35.852-2.28 2.136-2.28 1.199 0 2.06.786 2.06 1.875 0 .81-.54 1.444-1.317 2.162l-.743.743H9.43v1.216H5.31zM3.892 7.055v5.91H2.65V8.77L1.333 9.782V8.288l1.723-1.233h.836z" />
    </svg>
  )),
  { width: 16, height: 20 },
);
const Number = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NumberSvg} {...props} />
));
Number.displayName = 'Number';
export default Number;
