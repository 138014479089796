import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AudioFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6.889 8.143 13 3v18l-6.111-5.143H5.857a3.857 3.857 0 1 1 0-7.714H6.89zM18.576 6.21A8.145 8.145 0 0 1 21 12c0 2.17-.87 4.252-2.424 5.79l1.407 1.42A10.145 10.145 0 0 0 23 12c0-2.706-1.086-5.3-3.017-7.21l-1.407 1.42zM15.296 9.456a3.573 3.573 0 0 1 1.064 2.54c0 .95-.381 1.863-1.063 2.539l1.406 1.421a5.573 5.573 0 0 0 1.657-3.96 5.573 5.573 0 0 0-1.657-3.961l-1.406 1.421z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AudioFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AudioFilledSvg} {...props} />
));
AudioFilled.displayName = 'AudioFilled';
export default AudioFilled;
