export const scrollTo =
  (container: HTMLDivElement) =>
  (...params) => {
    if (container.scrollTo) {
      container.scrollTo(...params);
      return;
    }
    if (params.length === 0) {
      return;
    }

    if (typeof params[0] === 'number' && params[1] === undefined) {
      throw new SyntaxError('Value could not be converted');
    }

    if (params[0].left) {
      container.scrollLeft = params[0].left;
    } else if (typeof params[0] === 'number') {
      container.scrollLeft = Math.floor(params[0]);
    }

    if (params[0].top) {
      container.scrollTop = params[0].top;
    } else if (typeof params[1] === 'number') {
      container.scrollTop = Math.floor(params[1]);
    }
  };

export const scrollBy =
  (container: HTMLDivElement) =>
  (...params) => {
    if (container.scrollBy) {
      container.scrollBy(...params);
      return;
    }
    if (params.length === 0) {
      return;
    }

    if (params[0].left) {
      container.scrollLeft = params[0].left + container.scrollLeft;
    } else if (typeof params[0] === 'number') {
      container.scrollLeft = Math.floor(params[0]) + container.scrollLeft;
    }

    if (params[0].top) {
      container.scrollTop = params[0].top + container.scrollTop;
    } else if (typeof params[1] === 'number') {
      container.scrollTop = Math.floor(params[1]) + container.scrollTop;
    }
  };
