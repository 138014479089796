import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DirectCallSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path fillRule="evenodd" d="M11 2H3v13h8V2zM5 13V4h4v9H5zM21 9h-8v13h8V9zm-6 11v-9h4v9h-4z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DirectCall = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DirectCallSvg} {...props} />
));
DirectCall.displayName = 'DirectCall';
export default DirectCall;
