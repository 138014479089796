import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VideoOffFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#video-a)">
        <g>
          <path d="M4.416 3h-.002L1.707.293A1 1 0 0 0 .293 1.707l1.899 1.899A2.995 2.995 0 0 0 1 6v12a3 3 0 0 0 3 3h10a3 3 0 0 0 2.975-2.61l5.318 5.317a1 1 0 0 0 1.414-1.414L17 15.586v-.002L4.416 3zM22.819 18.574A.999.999 0 0 0 23 18V6a1 1 0 0 0-1.65-.76L17 8.97V6a3 3 0 0 0-3-3H7.244L22.82 18.574z" />
        </g>
      </g>
      <defs>
        <clipPath id="video-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const VideoOffFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VideoOffFilledSvg} {...props} />
));
VideoOffFilled.displayName = 'VideoOffFilled';
export default VideoOffFilled;
