import { useRef } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { isSameDay, isSameMonth, today } from '@internationalized/date';
import { useCalendarCell } from '@react-aria/calendar';
import type { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import clsx from 'clsx';
import styled, { css } from 'styled-components';

import { cssVariables } from '@feather/theme';
import { Typography } from '@feather/typography';

import { CalendarType, useCalendarOptions } from './OptionsContext';

const hoverStyle = css`
  background-color: ${cssVariables('bg-overlay-1')};
  color: inherit;
`;

const selectedStyle = css`
  background-color: ${cssVariables('bg-primary')};
  color: ${cssVariables('content-inverse-overlay-1')};

  &.today:after {
    background-color: #ffffff;
  }
`;

const outsideStyle = css`
  color: ${cssVariables('content-disabled')};
`;

const disabledStyle = css`
  background-color: transparent;
  color: ${cssVariables('content-disabled')};
  cursor: default;
`;

const Cell = styled.td`
  width: 32px;
  height: 32px;

  &.inRange {
    background-color: ${cssVariables('bg-overlay-1')};

    &.selectionEqualToValue {
      background-color: ${cssVariables('purple-1')};
    }
  }

  &.selectionStart {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  &.selectionEnd {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &.hidden {
    visibility: hidden !important;
  }
`;

const DayButton = styled.button`
  display: inline-flex;
  width: 100%;
  height: 100%;
  border-style: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  ${Typography['code-01']};
  color: ${cssVariables('content-1')};
  cursor: pointer;

  &:focus-visible {
    outline-style: none;
  }

  /* Default */
  background-color: transparent;

  &:hover,
  &:focus-visible {
    ${hoverStyle}
  }

  &.today {
    position: relative;
    color: ${cssVariables('content-primary')};

    &:after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 14px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${cssVariables('bg-primary')};
    }
  }

  &.selected:not(.inRange) {
    &,
    &:hover {
      ${selectedStyle}
    }
  }

  &.outsideVisibleRange {
    ${outsideStyle}
  }

  &.disabled:not(.outsideVisibleRange) {
    &,
    &:hover {
      ${disabledStyle}
    }
  }

  &.inRange {
    &.selected.selectionStart.selectionEnd {
      ${selectedStyle}
    }

    &.selected.selectionEqualToValue:not(:is(.selectionStart, .selectionEnd)) {
      color: ${cssVariables('content-primary')};
    }

    ${Cell} &.selected:is(.selectionStart, .selectionEnd) {
      &:not(.focused),
      &.selectionEqualToValue {
        ${selectedStyle}
      }
    }
  }
`;

type Props = {
  currentMonth: CalendarDate;
  date: CalendarDate;
} & (
  | {
      state: CalendarState;
      type: CalendarType.Calendar;
    }
  | {
      state: RangeCalendarState;
      type: CalendarType.RangeCalendar;
    }
);
const CalendarCell = ({ currentMonth, date, state, type }: Props) => {
  const { showOutsideVisibleRange, timeZone } = useCalendarOptions();

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, cellProps, formattedDate, isDisabled, isFocused, isOutsideVisibleRange, isSelected } =
    useCalendarCell({ date }, state, ref);
  const isToday = date.compare(today(timeZone)) === 0;
  const isInRange =
    type === CalendarType.RangeCalendar &&
    state.highlightedRange &&
    date.compare(state.highlightedRange.start) >= 0 &&
    date.compare(state.highlightedRange.end) <= 0;
  const isSelectionStart =
    type === CalendarType.RangeCalendar && state.highlightedRange && isSameDay(date, state.highlightedRange.start);
  const isSelectionEnd =
    type === CalendarType.RangeCalendar && state.highlightedRange && isSameDay(date, state.highlightedRange.end);
  const isOutsideMonth = !isSameMonth(currentMonth, date);
  const isSelectionEqualToValue =
    type === CalendarType.RangeCalendar &&
    state.value &&
    state.highlightedRange &&
    state.value.start.compare(state.highlightedRange.start) === 0 &&
    state.value.end.compare(state.highlightedRange.end) === 0;

  return (
    <Cell
      {...cellProps}
      className={clsx({
        hidden: !showOutsideVisibleRange && (isOutsideVisibleRange || isOutsideMonth),
        inRange: isInRange,
        selectionEnd: isSelectionEnd,
        selectionEqualToValue: isSelectionEqualToValue,
        selectionStart: isSelectionStart,
      })}
    >
      <DayButton
        {...buttonProps}
        ref={ref}
        type="button"
        className={clsx({
          disabled: isDisabled,
          focused: isFocused,
          inRange: isInRange,
          outsideVisibleRange: isOutsideVisibleRange || isOutsideMonth,
          selected: isSelected,
          selectionEnd: isSelectionEnd,
          selectionEqualToValue: isSelectionEqualToValue,
          selectionStart: isSelectionStart,
          today: isToday,
        })}
      >
        {formattedDate}
      </DayButton>
    </Cell>
  );
};

export default CalendarCell;
