import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ChatSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 5a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v10h15a3 3 0 0 0 3-3V5zM3 16.5h6l-6 6v-6z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Chat = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ChatSvg} {...props} />
));
Chat.displayName = 'Chat';
export default Chat;
