import type { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react';

import type { PopperProps } from 'react-popper';
import type { SimpleInterpolation } from 'styled-components';

import type { FeatherIconComponent } from '@feather/components/icons/types';

import type { FeatherThemeEnum } from '../../themes';
import type { DropdownProps } from './dropdown';

export enum OverlayButtonBackgroundType {
  Inverse = 'inverse',
  Mono = 'mono',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
}

/**
 * Variant
 * we use materials variant system for distinguish button shape
 * @see @https://material-ui.com/api/button/#button-api
 */
export type ButtonVariant = 'default' | 'ghost';

/**
 * Size
 */
export type ButtonSize = 'large' | 'medium' | 'small' | 'xsmall';
type ButtonIconPosition = 'left' | 'right';
export type IconButtonSize = 'small' | 'xsmall';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'support' | 'danger' | 'dangerTeritary';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  buttonType: ButtonType;
  size?: ButtonSize;
  icon?: ReactElement | FeatherIconComponent;
  iconPosition?: ButtonIconPosition;
  isLoading?: boolean;
  styles?: SimpleInterpolation;
}

type IconButtonOwnProps = {
  buttonType: ButtonType;
  size: IconButtonSize;
  icon: FeatherIconComponent;
  iconSize?: number;
  isLoading?: boolean;
  tooltipPlacement?: PopperProps['placement'];
  isTooltipHoverable?: boolean;
  title?: Exclude<ReactNode, null | undefined>;
};

export type IconButtonProps = IconButtonOwnProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof IconButtonOwnProps> & { 'data-test-id'?: string };

export interface OverlayButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export interface ButtonSizeStyleGenerator {
  (options: { size: ButtonSize; isGhostButton?: boolean }): SimpleInterpolation;
}

export interface ButtonContainerColorSet {
  contentColor: string;
  hoverContentColor?: string;
  activeContentColor?: string;
  pressedContentColor?: string;
  disabledContentColor: string;
  bgColor: string;
  hoverBgColor?: string;
  activeBgColor: string;
  pressedBgColor?: string;
  disabledBgColor: string;
  borderColor: string;
  hoverBorderColor?: string;
  activeBorderColor?: string;
  pressedBorderColor?: string;
  disabledBorderColor: string;
  focusOutlineColor?: string;
}

export interface GetContainerGeneratorOptions {
  (options: {
    theme?: FeatherThemeEnum;
    buttonType?: ButtonType;
    variant?: ButtonProps['variant'];
  }): ButtonContainerColorSet;
}

export interface ButtonContainerStyleGenerator {
  (options: ButtonContainerColorSet): SimpleInterpolation;
}

export interface SplitButtonProps {
  buttonType?: IconButtonProps['buttonType'];
  size: IconButtonProps['size'];
  left: Omit<IconButtonProps, 'buttonType' | 'size'>;
  right: Omit<IconButtonProps, 'buttonType' | 'size'>;
  dropdown?: Omit<DropdownProps<any>, 'variant' | 'size' | 'showArrow' | 'toggleTheme' | 'toggleRenderer'> | null;
}
