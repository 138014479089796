import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MemoColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect width={20} height={20} fill="#8012B3" rx={4} />
      <path
        fill="#fff"
        d="M15 6H5v6.75c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25V11.5h-1v1.25a.25.25 0 0 1-.25.25h-7.5a.25.25 0 0 1-.25-.25V7h8v.5h1V6z"
      />
      <path
        fill="#fff"
        d="m11.794 11.323 3.14-3.14a.624.624 0 0 1 .883.883l-3.14 3.14-1.177.294.294-1.177zM12.5 9.5H7v-1h5.5v1zM7 11.5h3v-1H7v1z"
      />
    </svg>
  )),
  { width: 20, height: 20 },
);
const MemoColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MemoColoredSvg} {...props} />
));
MemoColored.displayName = 'MemoColored';
export default MemoColored;
