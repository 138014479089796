import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MessagesFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4.5 2A2.5 2.5 0 0 0 2 4.5v13.414L5.414 14.5H15.5A2.5 2.5 0 0 0 18 12V4.5A2.5 2.5 0 0 0 15.5 2h-11z" />
      <path d="M6 16.5v-1h2v1a.5.5 0 0 0 .5.5h10.914l.586.586V9a.5.5 0 0 0-.5-.5H19v-2h.5A2.5 2.5 0 0 1 22 9v13.414L18.586 19H8.5A2.5 2.5 0 0 1 6 16.5z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MessagesFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MessagesFilledSvg} {...props} />
));
MessagesFilled.displayName = 'MessagesFilled';
export default MessagesFilled;
