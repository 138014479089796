import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextAlignTopSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 5H3V3h18v2zM6.293 11.793l1.414 1.414L11 9.914V21h2V9.914l3.293 3.293 1.414-1.414L12 6.086l-5.707 5.707z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextAlignTop = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextAlignTopSvg} {...props} />
));
TextAlignTop.displayName = 'TextAlignTop';
export default TextAlignTop;
