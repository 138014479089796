import styled from 'styled-components';

import { cssVariables } from '@feather/theme';
import { Typography } from '@feather/typography';

import { OptionListItem } from './components';

export const DropdownSubheader = styled(OptionListItem)`
  position: relative;
  padding-bottom: 4px;
  padding-top: 16px;
  ${Typography['label-01']};
  color: ${cssVariables('content-3')};
`;
