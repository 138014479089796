import type { CalendarDate } from '@internationalized/date';
import type { PopperProps } from 'react-popper';

import type { DropdownProps, DropdownSize } from '../../types/components/dropdown';

export type DatePickerCommonProps = {
  /**
   * If true, show the days outside of a month
   */
  enableOutsideDays?: boolean;
  placement?: PopperProps['placement'];
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  disabled?: boolean;
  size?: DropdownSize;

  /**
   * Custom date formatter
   */
  formatDate?: (date: CalendarDate, timeZone?: string) => string;

  /** Today value will be based on this `timeZone`. Default timeZone is client time zone from browser.  */
  timeZone?: string;
};

export enum DateRangePickerValue {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 days',
  Last14Days = 'Last 14 days',
  Last30Days = 'Last 30 days',
  Last90Days = 'Last 90 days',
  Last180Days = 'Last 180 days',
  AllDates = 'All dates',
  Custom = 'Custom',
}

export type DateRangeDropdownItem = {
  value: DateRangePickerValue;
  label: string;
};

export type DatePickerDropdownProps = DropdownProps<DateRangeDropdownItem>;

export type DateRangeItemLabel = {
  today: string;
  yesterday: string;
  last7Days: string;
  last14Days: string;
  last30Days: string;
  last90Days: string;
  last180Days?: string;
  allDates?: string;
  custom: string;
};

export type DateRangeDropdownItems = {
  [key in keyof DateRangeItemLabel]: DateRangeDropdownItem;
};

export type GetDropdownItems = (payload: {
  dropdownItems: DateRangeDropdownItems;
  hasAllDays?: boolean;
  hasLast180Days?: boolean;
  maximumNights: number | undefined;
  minimumNights: number | undefined;
}) => DatePickerDropdownProps['items'];

export type DateRange = { startDate: CalendarDate; endDate: CalendarDate };
