import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ScrollSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4 9a8 8 0 1 1 16 0v5h-2V9A6 6 0 0 0 6 9v6a6 6 0 0 0 8 5.659v2.089A8 8 0 0 1 4 15V9z" />
      <path d="M12 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12zM21.1 16 19 18.1 16.9 16l-1.4 1.4 2.1 2.1-2.1 2.1 1.4 1.4 2.1-2.1 2.1 2.1 1.4-1.4-2.1-2.1 2.1-2.1-1.4-1.4z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Scroll = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ScrollSvg} {...props} />
));
Scroll.displayName = 'Scroll';
export default Scroll;
