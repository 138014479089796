import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MinimizeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M20 9h-3.586l5.293-5.293-1.414-1.414L15 7.586V4h-2v7h7V9zM7.586 15H4v-2h7v7H9v-3.586l-5.293 5.293-1.414-1.414L7.586 15z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Minimize = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MinimizeSvg} {...props} />
));
Minimize.displayName = 'Minimize';
export default Minimize;
