import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LiveColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#6210CC"
        d="M14.75 18.98A7.502 7.502 0 0 0 12 4.5a7.5 7.5 0 0 0-2.75 14.48v2.637C5.064 20.422 2 16.57 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10c0 4.57-3.064 8.422-7.25 9.617V18.98z"
      />
      <path fill="#6210CC" d="M14.75 14.165a3.5 3.5 0 1 0-5.5 0v3.17a6 6 0 1 1 5.5 0v-3.17z" />
      <path
        fill="#6210CC"
        d="M10.75 13.561v8.362c.408.05.824.077 1.246.077h.008c.422 0 .838-.026 1.246-.077V13.56a2 2 0 1 0-2.5 0z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LiveColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LiveColoredSvg} {...props} />
));
LiveColored.displayName = 'LiveColored';
export default LiveColored;
