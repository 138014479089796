import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CloudDownloadSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m13 17.896 1.543-1.543 1.414 1.415L12 21.725l-3.957-3.957 1.414-1.415L11 17.896V11h2v6.896z" />
      <path d="M8.638 6c.354-1.184 1.403-2 2.52-2 1.121 0 2.119.778 2.499 1.926l.3.909.922-.261c1.114-.316 2.085.413 2.106 1.553l.02 1.01 1.008-.028c1.23-.034 2.142 1 1.96 2.205-.088.59-.343.993-.707 1.264-.379.283-.962.482-1.793.482H15v2h2.473c1.139 0 2.176-.273 2.988-.877.826-.616 1.331-1.511 1.49-2.573a3.89 3.89 0 0 0-3.088-4.425 3.557 3.557 0 0 0-3.649-2.682C14.433 3.047 12.944 2 11.16 2 9.347 2 7.787 3.113 7.023 4.662 4.245 4.682 2 7.112 2 9.861c0 2.835 2.236 5.198 5.057 5.199H9v-2H7.057C5.404 13.06 4 11.657 4 9.86 4 7.952 5.719 6.408 7.52 6.7l.867.14.251-.84z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CloudDownload = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CloudDownloadSvg} {...props} />
));
CloudDownload.displayName = 'CloudDownload';
export default CloudDownload;
