type WalkObjectPredicate<Leaf = unknown> = (value: unknown, path: string[]) => Leaf;

const isArray = <T>(value: any): value is Array<T> => {
  return Array.isArray(value);
};
const isObject = (value: any): value is Record<string, any> => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function') && !isArray(value);
};

type MappedLeavesObject<Obj, LeafType> = {
  [Prop in keyof Obj]: Obj[Prop] extends Array<any>
    ? MappedLeavesObject<Obj[Prop][number], LeafType>[]
    : Obj[Prop] extends object
    ? MappedLeavesObject<Obj[Prop], LeafType>
    : LeafType;
};

export const walkObject = <Target, LeafType>(params: {
  target: Target;
  predicate: WalkObjectPredicate<LeafType>;
}): MappedLeavesObject<Target, ReturnType<WalkObjectPredicate<LeafType>>> => {
  const { target, predicate } = params;
  const inner = (value: unknown, path: string[] = []): any => {
    if (isArray(value)) {
      return value.map((item, index) => inner(item, [...path, String(index)]));
    }

    if (isObject(value)) {
      return Object.fromEntries(Object.entries(value).map(([key, child]) => [key, inner(child, [...path, key])]));
    }

    return predicate(value, path);
  };

  return inner(target);
};
