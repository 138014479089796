import type { ThemeProviderProps } from 'styled-components';
import { ThemeProvider as StyledThemeProvider, createGlobalStyle, css } from 'styled-components';

import type { FeatherTheme, FeatherThemeCSSColors } from '@feather/types';
import { Body } from '@feather/typography';

import { useColorMode } from './ColorModeProvider';
import cssVariables from './cssVariables';

type FeatherThemeProviderProps = ThemeProviderProps<FeatherTheme, Pick<FeatherTheme, 'colorMode'>> & {
  cssVariablesRoot?: string;
};

const globalIconStyle = css`
  svg.feather__icon {
    fill: var(--icon-primary-color);

    // Assign secondary color to path elements with id="Color_01" attribute in two-tone icons
    #Color_01 {
      fill: var(--icon-secondary-color);
    }
  }
`;

const globalStyle = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    ${Body['body-short-01']}
    color: ${cssVariables('content-1')};
  }

  button,
  textarea,
  select {
    font: inherit;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
  ${globalIconStyle}
`;

const GlobalCssVariableStyle = createGlobalStyle<{ cssVariablesRoot: string; cssColors: FeatherThemeCSSColors }>`
  ${({ cssVariablesRoot }) => cssVariablesRoot} {
    ${({ cssColors }) =>
      css`${Object.entries(cssColors)
        .map(([key, value]) => `--${key}: ${value};`)
        .join('')}}`}
`;

const FeatherThemeProvider = ({
  theme: themeProp,
  cssVariablesRoot = ':host, :root',
  children,
}: FeatherThemeProviderProps) => {
  const { colorMode } = useColorMode();

  const theme = typeof themeProp === 'function' ? themeProp({ colorMode }) : themeProp;

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalCssVariableStyle cssVariablesRoot={cssVariablesRoot} cssColors={theme.cssColors} />
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};

export default FeatherThemeProvider;
