import type { MouseEventHandler, Key, KeyboardEvent } from 'react';
import { useEffect, useRef, useState, useCallback } from 'react';

import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import type {
  SideMenuProps,
  CreateSideMenuItem,
  SideMenuItemDefaultInterface,
  SideMenuItemWithCountInterface,
  SideMenuItemExpandableInterface,
  SideMenuItemSubInterface,
  SideMenuItem,
  ScrollBarRef,
} from '@feather/types';
import { SideMenuItemType } from '@feather/types';
import { Subtitles } from '@feather/typography';

import { ScrollBar } from '../ScrollBar';

const transitionTimeInMs = 150;

const Container = styled.nav<{ hasTitle: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: inset -1px 0 ${cssVariables('neutral-2')};
  padding-bottom: 8px;
  ${({ hasTitle }) =>
    hasTitle &&
    css`
      padding-top: 20px;
    `}
  width: 232px;

  .SubMenuItemTransition-enter {
    opacity: 0;
  }

  .SubMenuItemTransition-enter-active {
    opacity: 1;
    transition: opacity ${transitionTimeInMs}ms ${transitionDefault};
  }

  .SubMenuItemTransition-exit {
    opacity: 1;
  }

  .SubMenuItemTransition-exit-active {
    opacity: 0;
    transition: opacity ${transitionTimeInMs}ms ${transitionDefault};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 28px;
  user-select: none;
`;

const Title = styled.h5`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${cssVariables('neutral-10')};
  font-weight: 600;
`;

const TitleActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const MenuItemListScrollBar = styled(ScrollBar)<{ hasTitle: boolean }>`
  flex: 1;
  padding: 0 16px;
  ${({ hasTitle }) =>
    hasTitle &&
    css`
      padding-top: 8px;
    `}
`;

const MenuItemList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const menuItemCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  ${Subtitles['subtitle-01']}
  color: ${cssVariables('neutral-10')};
  border-radius: 8px;
  transition: ${transitionTimeInMs}ms ${transitionDefault};
  transition-property: background-color;
  user-select: none;
  outline: none;
  text-decoration: none !important;

  &[data-clickable='true'] {
    cursor: pointer;

    :hover,
    :focus {
      background-color: ${cssVariables('neutral-1')};
    }
  }

  &[aria-current='page'] {
    background-color: ${cssVariables('neutral-2')} !important;
    color: ${cssVariables('purple-7')};
    font-weight: 600;
  }
`;

const MenuItem = styled.li`
  ${menuItemCSS}
`;

const MenuItemLink = styled.a`
  ${menuItemCSS}
`;

const MenuItemReactRouterLink = styled(Link)`
  ${menuItemCSS}
`;

const SubMenuItem = styled(MenuItem)<{ isParentExpandable: boolean }>`
  font-weight: 400;
  padding-left: ${({ isParentExpandable }) => (isParentExpandable ? 34 : 20)}px;
`;

const ExpandableMenuItem = styled(MenuItem)<{ hasParent: boolean }>`
  ${SubMenuItem} + & {
    margin-top: 8px;
  }

  ${({ hasParent }) =>
    hasParent &&
    css`
      padding-left: 20px;
      font-weight: 400;

      ${SubMenuItem} + & {
        margin-top: 0;
      }
    `};
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  transition: inherit;
  transition-property: inherit;
`;

const MenuItemLabel = styled.span`
  transition: inherit;
  transition-property: inherit;

  /* The space on top of the capital letter is little bigger than the space on the bottom of the text. */
  /* The margin-top below compensates the difference and make the text looks visually centered. */
  margin-top: 0.083em;
`;

const ExpandChevronDownIcon = styled(Icons.ChevronDown)<{ $isExpanded: boolean }>`
  margin: 4px;
  transform: rotate(${(props) => (props.$isExpanded ? 0 : -90)}deg);
  transition: ${transitionTimeInMs}ms transform ${transitionDefault};
`;

const MenuItemCount = styled.span`
  margin-left: auto;
`;

export const createSideMenuItemDefault: CreateSideMenuItem<SideMenuItemDefaultInterface> = (params) => ({
  type: SideMenuItemType.Default,
  ...params,
});

export const createSideMenuItemWithCount: CreateSideMenuItem<SideMenuItemWithCountInterface> = (params) => ({
  type: SideMenuItemType.WithCount,
  ...params,
});

export const createSideMenuItemExpandable: CreateSideMenuItem<SideMenuItemExpandableInterface> = (params) => ({
  type: SideMenuItemType.Expandable,
  ...params,
});

export const createSideMenuItemSub: CreateSideMenuItem<SideMenuItemSubInterface> = (params) => ({
  type: SideMenuItemType.Sub,
  ...params,
});

const isExpandedExpandableMenuItem = (item: SideMenuItem) =>
  item.type === SideMenuItemType.Expandable && item.isExpanded;

export const SideMenu = ({ title, titleAction, activeItemKey, items, ...containerAttributes }: SideMenuProps) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(
    items.filter(isExpandedExpandableMenuItem).map((item) => item.key),
  );
  const previousItems = useRef<typeof items>(items);
  const scrollBarRef = useRef<ScrollBarRef>(null);
  const menuItemListRef = useRef<HTMLUListElement>(null);
  const resizeObserverRef = useRef(
    new ResizeObserver(() => {
      if (scrollBarRef.current) {
        scrollBarRef.current.update();
      }
    }),
  );
  const hasTitle = title != null;

  useEffect(() => {
    const { current: resizeObserver } = resizeObserverRef;
    if (menuItemListRef.current) {
      resizeObserver.observe(menuItemListRef.current);
    }
    return () => resizeObserver.disconnect();
  });

  useEffect(() => {
    if (!isEqual(items, previousItems.current)) {
      // items prop has been replaced.
      setExpandedKeys(items.filter(isExpandedExpandableMenuItem).map((item) => item.key));
    }
    previousItems.current = items;
  }, [items]);

  const onMenuItemClick = (item: SideMenuItem) => () => {
    if (item.onClick) {
      item.onClick();
    }
  };

  const onMenuItemKeyPress = (item: SideMenuItem) => (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onMenuItemClick(item)();
    }
  };

  const onExpandableItemClick = useCallback(
    (item: SideMenuItem) => () => {
      const { key, onClick } = item;
      const isExpanded = expandedKeys.includes(key);
      if (!key) {
        return;
      }
      setExpandedKeys((expandedKeys) => {
        if (isExpanded) {
          return expandedKeys.filter((item) => item !== key);
        }
        return expandedKeys.concat([key]);
      });
      onClick && onClick();
    },
    [expandedKeys],
  );

  const onExpandableItemKeyPress = (item: SideMenuItem) => (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onExpandableItemClick(item)();
    }
  };

  return (
    <Container hasTitle={hasTitle} {...containerAttributes}>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
          <TitleActionContainer>{titleAction}</TitleActionContainer>
        </TitleContainer>
      )}
      <MenuItemListScrollBar hasTitle={hasTitle} ref={scrollBarRef}>
        <MenuItemList ref={menuItemListRef}>
          <TransitionGroup className="sub">
            {items.map((item) => {
              switch (item.type) {
                case SideMenuItemType.Default:
                case SideMenuItemType.WithCount: {
                  const isClickable = !!item.onClick || !!item.href;
                  const menuItemProps = {
                    key: item.key,
                    onClick: onMenuItemClick(item),
                    'aria-current': activeItemKey === item.key ? ('page' as const) : undefined,
                    'data-clickable': isClickable,
                    tabIndex: 0,
                    onKeyPress: onMenuItemKeyPress(item),
                  };
                  if (item.href) {
                    if (item.useReactRouterLink) {
                      return (
                        <CSSTransition key={item.key} timeout={transitionTimeInMs} classNames="SubMenuItemTransition">
                          <MenuItemReactRouterLink {...menuItemProps} to={item.href}>
                            <MenuItemContainer>
                              <MenuItemLabel>{item.label}</MenuItemLabel>
                              {item.labelSuffixNode}
                            </MenuItemContainer>
                          </MenuItemReactRouterLink>
                        </CSSTransition>
                      );
                    }
                    return (
                      <CSSTransition key={item.key} timeout={transitionTimeInMs} classNames="SubMenuItemTransition">
                        <MenuItemLink {...menuItemProps} href={item.href}>
                          <MenuItemContainer>
                            <MenuItemLabel>{item.label}</MenuItemLabel>
                            {item.labelSuffixNode}
                          </MenuItemContainer>
                        </MenuItemLink>
                      </CSSTransition>
                    );
                  }
                  return (
                    <CSSTransition key={item.key} timeout={transitionTimeInMs} classNames="SubMenuItemTransition">
                      <MenuItem {...menuItemProps}>
                        <MenuItemContainer>
                          <MenuItemLabel>{item.label}</MenuItemLabel>
                          {item.labelSuffixNode}
                        </MenuItemContainer>
                        {item.type === SideMenuItemType.WithCount && <MenuItemCount>{item.count}</MenuItemCount>}
                      </MenuItem>
                    </CSSTransition>
                  );
                }

                case SideMenuItemType.Expandable: {
                  const isExpanded = expandedKeys.includes(item.key);
                  const hasParent = item.parentKey !== undefined;
                  const isParentExpended = !!item.parentKey && expandedKeys.includes(item.parentKey);

                  // onMouseUp clear the background color after a click.
                  const onMouseUp: MouseEventHandler<HTMLElement> = ({ currentTarget }) => {
                    currentTarget.blur();
                  };

                  if (!hasParent || isParentExpended) {
                    return (
                      <CSSTransition key={item.key} timeout={transitionTimeInMs} classNames="SubMenuItemTransition">
                        <ExpandableMenuItem
                          key={item.key}
                          aria-current={activeItemKey === item.key ? 'page' : undefined}
                          hasParent={hasParent}
                          onClick={onExpandableItemClick(item)}
                          onMouseUp={onMouseUp}
                          onKeyPress={onExpandableItemKeyPress(item)}
                          data-clickable={true}
                          tabIndex={0}
                        >
                          <MenuItemLabel>{item.label}</MenuItemLabel>
                          {item.labelSuffixNode}
                          <ExpandChevronDownIcon
                            $isExpanded={isExpanded}
                            size={16}
                            color={cssVariables('neutral-10')}
                          />
                        </ExpandableMenuItem>
                      </CSSTransition>
                    );
                  }

                  return null;
                }
                case SideMenuItemType.Sub: {
                  const parentItem = items.find((menuItem) => menuItem.key === item.parentKey);
                  const expandableGrandParentItem = items.find(
                    (menuItem) =>
                      parentItem?.type === SideMenuItemType.Expandable &&
                      menuItem.type === SideMenuItemType.Expandable &&
                      menuItem.key === parentItem.parentKey,
                  );

                  if (
                    expandedKeys.includes(item.parentKey) &&
                    (!expandableGrandParentItem ||
                      (expandableGrandParentItem && expandedKeys.includes(expandableGrandParentItem.key)))
                  ) {
                    return (
                      <CSSTransition key={item.key} timeout={transitionTimeInMs} classNames="SubMenuItemTransition">
                        <SubMenuItem
                          aria-current={activeItemKey === item.key ? 'page' : undefined}
                          onClick={onMenuItemClick(item)}
                          data-clickable={!!item.onClick || !!item.href}
                          tabIndex={0}
                          isParentExpandable={
                            parentItem?.type === SideMenuItemType.Expandable && !!parentItem.parentKey
                          }
                          onKeyPress={onMenuItemKeyPress(item)}
                        >
                          <MenuItemLabel>{item.label}</MenuItemLabel>
                          {item.labelSuffixNode}
                        </SubMenuItem>
                      </CSSTransition>
                    );
                  }
                  return null;
                }
                default:
                  return null;
              }
            })}
          </TransitionGroup>
        </MenuItemList>
      </MenuItemListScrollBar>
    </Container>
  );
};
