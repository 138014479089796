import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const StarTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        id="Color_02"
        fillRule="evenodd"
        d="m12 .806 2.612 7.924h8.502l-6.878 4.925 2.618 7.942L12 16.69l-6.853 4.908 2.618-7.942L.887 8.73h8.502L12 .805z"
        clipRule="evenodd"
      />
      <path id="Color_01" d="M12 .807 9.389 8.729H.887l6.878 4.927-2.618 7.941L12 16.69V.807z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const StarTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={StarTwotoneSvg} {...props} />
));
StarTwotone.displayName = 'StarTwotone';
export default StarTwotone;
