import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const KeySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m20.291 1.291 1.411 1.418-1.295 1.288 3.01 2.996-4.929 4.907-3.01-2.996-2.758 2.744a6.481 6.481 0 0 1 1.276 3.838 6.469 6.469 0 0 1-1.91 4.614 6.52 6.52 0 0 1-4.63 1.9 6.542 6.542 0 0 1-4.617-1.93l-.007-.008A6.479 6.479 0 0 1 1 15.492a6.48 6.48 0 0 1 1.911-4.536 6.537 6.537 0 0 1 8.377-.705l9.003-8.96zm-9.584 11.074-.047-.046a4.533 4.533 0 0 0-3.18-1.262 4.532 4.532 0 0 0-3.158 1.317A4.48 4.48 0 0 0 3 15.51a4.479 4.479 0 0 0 1.263 3.155A4.52 4.52 0 0 0 7.463 20a4.542 4.542 0 0 0 3.212-1.317 4.494 4.494 0 0 0 1.321-3.19 4.471 4.471 0 0 0-1.289-3.128zm6.188-4.872 1.593 1.585 2.094-2.085-1.592-1.585-2.095 2.085z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Key = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={KeySvg} {...props} />
));
Key.displayName = 'Key';
export default Key;
