import { useCallback, useEffect, useMemo, useRef } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { DateFormatter, createCalendar, getLocalTimeZone } from '@internationalized/date';
import type { AriaRangeCalendarProps } from '@react-aria/calendar';
import { useRangeCalendar } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import type { RangeCalendarStateOptions } from '@react-stately/calendar';
import { useRangeCalendarState } from '@react-stately/calendar';
import styled, { css } from 'styled-components';

import { IconButton } from '@feather/components/button';
import { ChevronLeft, ChevronRight } from '@feather/components/icons';
import { Headings } from '@feather/typography';

import CalendarGrid from './CalendarGrid';
import MonthPicker from './MonthPicker';
import type { CalendarOptions } from './OptionsContext';
import { CalendarOptionsProvider, CalendarType } from './OptionsContext';

const Header = styled.header`
  display: flex;
  gap: 24px;
  height: 32px;
  margin-bottom: 12px;
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 100%;
`;

const Title = styled.h3<{ paddingPosition: 'left' | 'right' }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  ${Headings['heading-01']};
  ${({ paddingPosition }) => css`
    padding-${paddingPosition}: 32px;
  `}
`;

const CalendarWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

type Props = Omit<
  AriaRangeCalendarProps<CalendarDate> & RangeCalendarStateOptions<CalendarDate>,
  'createCalendar' | 'locale'
> & {
  maximumNights?: number;
  minimumNights?: number;
  monthPicker?: boolean;
  monthPickerPortalId?: string;
  onAfterRangeChange?: () => void;
  onBeforeRangeChange?: (range: { start: CalendarDate; end: CalendarDate }) => void;
  onRangeChanging?: (range: { start: CalendarDate; end: CalendarDate } | null) => void;
  showOutsideVisibleRange?: boolean;
  timeZone?: string;
};
const RangeCalendar = ({
  maximumNights,
  minimumNights,
  monthPicker,
  monthPickerPortalId,
  onAfterRangeChange,
  onBeforeRangeChange,
  onRangeChanging,
  showOutsideVisibleRange = false,
  timeZone = getLocalTimeZone(),
  ...props
}: Props) => {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    createCalendar,
    locale,
    pageBehavior: 'single',
    visibleDuration: { months: 2 },
  });
  const ref = useRef<HTMLDivElement>(null);
  const {
    calendarProps,
    nextButtonProps: { onPress: _, onFocusChange: onNextFocusChange, ...nextButtonProps },
    prevButtonProps: { onPress: __, onFocusChange: onPrevFocusChange, ...prevButtonProps },
  } = useRangeCalendar(props, state, ref);

  const currentAnchorDate = useRef<CalendarDate | null>(null);
  const handleChangeIsOpen = (isOpen: boolean) => {
    if (isOpen) {
      currentAnchorDate.current = state.anchorDate;
      return;
    }
    if (currentAnchorDate.current) {
      state.setAnchorDate(currentAnchorDate.current);
    }
  };

  const handleSelect = (value: CalendarDate, position: 'left' | 'right') => {
    const start = position === 'right' ? value.subtract({ months: 1 }) : value;
    /**
     * This might be awkward, but we have to get the steps separated into Promises,
     * since `visibleRange` in `state` doesn't get updated right after calling of `setFocusedDate`.
     * In addition, we have to set temporary minValue and maxValue which can be injected from the parent component,
     * so that visibleRange gets updated correctly.
     */
    Promise.resolve()
      .then(() => {
        const start = position === 'right' ? value.subtract({ months: 1 }).set({ day: 1 }) : value.set({ day: 1 });
        const end = start.add({ months: 1 }).set({ day: 31 });
        onBeforeRangeChange?.({ start, end });
      })
      .then(() => {
        if (state.visibleRange.start.compare(start) > 0 || state.visibleRange.end.compare(start) < 0) {
          state.setFocusedDate(start);
        } else {
          state.setFocusedDate(start.add({ months: 1 }));
        }
      })
      .then(() => {
        onAfterRangeChange?.();
      });
  };

  const calendarOptions = useMemo<CalendarOptions>(
    () => ({
      locale,
      showOutsideVisibleRange,
      timeZone,
      type: CalendarType.RangeCalendar as const,
    }),
    [locale, showOutsideVisibleRange, timeZone],
  );

  const handleClickNext = () => {
    state.focusNextPage();
  };

  const handleClickPrev = () => {
    state.focusPreviousPage();
  };

  state.isCellDisabled = useCallback(
    (date) => {
      if (props.minValue && date.compare(props.minValue) < 0) {
        return true;
      }
      if (props.maxValue && date.compare(props.maxValue) > 0) {
        return true;
      }
      const { anchorDate } = state;
      if (anchorDate) {
        const difference = date.compare(anchorDate);
        if (minimumNights) {
          if (Math.abs(difference) < minimumNights) {
            return true;
          }
        }
        if (maximumNights) {
          if (Math.abs(difference) > maximumNights) {
            return true;
          }
        }
      }
      return false;
    },
    [maximumNights, minimumNights, props.maxValue, props.minValue, state],
  );

  useEffect(() => {
    onRangeChanging?.(state.anchorDate ? state.highlightedRange : null);
  }, [onRangeChanging, state.anchorDate, state.highlightedRange, state.isDragging]);

  const leftValue = state.visibleRange.start;
  const rightValue = state.visibleRange.end;
  const formatter = new DateFormatter(locale, { year: 'numeric', month: 'long', timeZone });

  return (
    <div {...calendarProps} ref={ref}>
      <CalendarOptionsProvider options={calendarOptions}>
        <Header>
          <HeaderColumn>
            <IconButton
              {...prevButtonProps}
              buttonType="secondary"
              icon={ChevronLeft}
              onBlur={() => onPrevFocusChange?.(false)}
              onClick={handleClickPrev}
              onFocus={() => onPrevFocusChange?.(true)}
              size="small"
            />
            {monthPicker ? (
              <MonthPicker
                key="left"
                month={leftValue}
                onChangeIsOpen={handleChangeIsOpen}
                onSelect={(date) => handleSelect(date, 'left')}
                paddingPosition="right"
                portalId={monthPickerPortalId}
              />
            ) : (
              <Title key="left" paddingPosition="right">
                {formatter.format(leftValue.toDate(timeZone))}
              </Title>
            )}
          </HeaderColumn>
          <HeaderColumn>
            {monthPicker ? (
              <MonthPicker
                key="right"
                month={rightValue}
                onChangeIsOpen={handleChangeIsOpen}
                onSelect={(date) => handleSelect(date, 'right')}
                paddingPosition="left"
                portalId={monthPickerPortalId}
              />
            ) : (
              <Title key="right" paddingPosition="left">
                {formatter.format(rightValue.toDate(timeZone))}
              </Title>
            )}
            <IconButton
              {...nextButtonProps}
              buttonType="secondary"
              icon={ChevronRight}
              onBlur={() => onNextFocusChange?.(false)}
              onClick={handleClickNext}
              onFocus={() => onNextFocusChange?.(true)}
              size="small"
            />
          </HeaderColumn>
        </Header>

        <CalendarWrapper>
          <CalendarGrid state={state} />
          <CalendarGrid offset={{ months: 1 }} state={state} />
        </CalendarWrapper>
      </CalendarOptionsProvider>
    </div>
  );
};

export default RangeCalendar;
