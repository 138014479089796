import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AutomationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3 4a3 3 0 0 1 5.83-1h9.32C20.864 3 23 5.275 23 8s-2.136 5-4.85 5H5.85C4.312 13 3 14.307 3 16s1.312 3 2.85 3h9.32a3.001 3.001 0 1 1 0 2H5.85C3.136 21 1 18.725 1 16s2.136-5 4.85-5h12.3C19.688 11 21 9.693 21 8s-1.312-3-2.85-3H8.83A3.001 3.001 0 0 1 3 4zm3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm11 17a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Automation = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AutomationSvg} {...props} />
));
Automation.displayName = 'Automation';
export default Automation;
