import { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';

export const placeholder = css`
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${cssVariables('neutral-6')};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${cssVariables('neutral-6')};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${cssVariables('neutral-6')};
  }
`;

export const focusRing = css`
  box-shadow: 0 0 0 1px white, 0 0 0 2px ${cssVariables('purple-7')};
`;
