import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallVideoSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 4h12.5A2.5 2.5 0 0 1 17 6.5v2.504l5-4.125V19.12l-5-4.125V17.5a2.5 2.5 0 0 1-2.5 2.5h-10A2.5 2.5 0 0 1 2 17.5V4zm2 2v11.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5H4zm12.51 6L20 14.879V9.12L16.51 12z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallVideo = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallVideoSvg} {...props} />
));
CallVideo.displayName = 'CallVideo';
export default CallVideo;
