import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MicOffSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M19.5 12c0 1.501-.441 2.9-1.201 4.072l-1.459-1.458c.421-.778.66-1.668.66-2.614h2zM16.25 11.746c0 .664-.152 1.293-.424 1.853l-1.591-1.59c.01-.087.015-.174.015-.263v-6.5a2.25 2.25 0 0 0-4.5 0v2.277l-2-2v-.277a4.25 4.25 0 1 1 8.5 0v6.5zM7.75 11.164.004 3.418l1.414-1.414L22 22.586 20.586 24l-5.27-5.27a7.448 7.448 0 0 1-2.316.704V21h3v2H8v-2h3v-1.566A7.501 7.501 0 0 1 4.5 12h2a5.5 5.5 0 0 0 7.288 5.203l-1.241-1.242a4.25 4.25 0 0 1-4.797-4.215v-.582z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MicOff = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MicOffSvg} {...props} />
));
MicOff.displayName = 'MicOff';
export default MicOff;
