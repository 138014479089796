import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrVoiceSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-vo-a)">
        <path d="M7.748 3.996a2 2 0 0 1 2-2h4.5a2 2 0 0 1 2 2v8.5a4.25 4.25 0 1 1-8.5 0v-8.5z" />
        <path d="M17.748 12.5a5.75 5.75 0 1 1-11.5 0h-1.5a7.251 7.251 0 0 0 6.5 7.212V21.5h-3.25V23h8v-1.5h-3.25v-1.788a7.251 7.251 0 0 0 6.5-7.212h-1.5z" />
      </g>
      <defs>
        <clipPath id="br-vo-a">
          <path d="M0 0H23.996V23.996H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrVoice = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrVoiceSvg} {...props} />
));
BrVoice.displayName = 'BrVoice';
export default BrVoice;
