const replaceWhiteSpace = (value: string, replaceValue = '-') => {
  return value.replace(/\s+/g, replaceValue);
};

const escape = (value: string | number) => {
  const valueStr = replaceWhiteSpace(value.toString());
  if (valueStr.includes('\\.')) return value;
  const isDecimal = !Number.isInteger(parseFloat(value.toString()));
  return isDecimal ? valueStr.replace('.', '\\.') : value;
};

const addPrefix = (value: string, prefix = '') => {
  return [prefix, escape(value)].filter(Boolean).join('-');
};

const toVariableReference = (params: { name: string; fallback?: string }) => {
  const { name, fallback } = params;
  return `var(${escape(name)}${fallback ? `, ${fallback}` : ''})`;
};

const toVariableDefinition = (params: { value: string; prefix?: string }) => {
  const { value, prefix = '' } = params;
  return `--${addPrefix(value, prefix)}`;
};

const getCssVariable = (params: { name: string; fallback?: string; cssVariablePrefix?: string }) => {
  const { name, fallback, cssVariablePrefix } = params;
  const cssVariable = toVariableDefinition({ value: name, prefix: cssVariablePrefix });
  return {
    variable: cssVariable,
    reference: toVariableReference({ name: cssVariable, fallback }),
  };
};

export default getCssVariable;
