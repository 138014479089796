import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrHighestCorporateIntegritySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-hi-a)">
        <path d="M11.003 15.5h-10v.5a5 5 0 0 0 10 0v-.5zM23.003 15.5h-10v.5a5 5 0 0 0 10 0v-.5z" />
        <path
          fillRule="evenodd"
          d="M18.709 4.5h3.294V3h-20v1.5h3.294a.787.787 0 0 0-.002.007L2.003 14h1.59l2.41-6.95L8.413 14h1.59L6.71 4.507A.787.787 0 0 0 6.71 4.5h10.588l-.003.007L14.003 14h1.59l2.41-6.95 2.41 6.95h1.59L18.71 4.507 18.71 4.5z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-hi-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrHighestCorporateIntegrity = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrHighestCorporateIntegritySvg} {...props} />
));
BrHighestCorporateIntegrity.displayName = 'BrHighestCorporateIntegrity';
export default BrHighestCorporateIntegrity;
