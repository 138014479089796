import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AwsMarketplaceColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#F8972C" d="M6.327 1.898 3.266 3.43l3.06 1.53 3.062-1.53-3.061-1.53z" />
      <path fill="#F27C22" d="M3.266 6.745V3.43l3.06 1.53v3.317l-3.06-1.53z" />
      <path fill="#E86026" d="M9.388 6.745V3.43l-3.061 1.53v3.317l3.06-1.53z" />
      <path fill="#34C2DA" d="m13.807 1.898-3.06 1.531 3.06 1.53 3.061-1.53-3.06-1.53z" />
      <path fill="#08A4CC" d="M10.746 6.745V3.43l3.061 1.53v3.317l-3.06-1.53z" />
      <path fill="#188FBA" d="M16.868 6.745V3.43l-3.06 1.53v3.317l3.06-1.53z" />
      <path fill="#197FBF" d="M9.993 7.706 6.774 9.315l3.219 1.61 3.218-1.61-3.218-1.61z" />
      <path fill="#1F67B1" d="M6.774 12.801V9.315l3.219 1.609v3.486l-3.219-1.609z" />
      <path fill="#1251A0" d="M13.21 12.801V9.315l-3.218 1.609v3.486l3.218-1.609z" />
      <path
        fill="#232F3F"
        fillRule="evenodd"
        d="M17.811 8.02c-.184.637-.447 1.53-.582 1.986-.135.456-.526 1.791-.87 2.967l-.795 2.728-.172.589h-5.08l-5.08-.001-.409-1.33c-.225-.732-.604-1.96-.842-2.727l-.434-1.397-1.274-.024c-.7-.013-1.274.007-1.273.044 0 .037.6 1.874 1.332 4.082l1.331 4.015 6.648.022 6.647.023.346-1.114 1.235-3.993c.489-1.584 1.036-3.351 1.216-3.927l.328-1.048 2.458-.023L25 8.869V6.864h-6.852l-.337 1.157zM5.224 19.942c-.614.386-.912 1.082-.733 1.719.25.896 1.227 1.35 2.104.979a1.68 1.68 0 0 0 .56-.455c.176-.23.256-.451.284-.783.047-.569-.141-1.01-.572-1.338-.235-.18-.423-.238-.838-.261-.416-.024-.595.007-.805.139zm8.912.04c-.418.236-.74.767-.742 1.221-.002.39.194.898.455 1.176.103.11.381.256.618.327.34.101.51.108.815.03.43-.107.952-.534.852-.696-.039-.063-.026-.08.033-.043.141.087.303-.597.236-.994-.059-.347-.412-.91-.51-.813-.032.032-.057.013-.057-.042 0-.172-.498-.366-.934-.365-.272.001-.541.071-.766.199z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AwsMarketplaceColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AwsMarketplaceColoredSvg} {...props} />
));
AwsMarketplaceColored.displayName = 'AwsMarketplaceColored';
export default AwsMarketplaceColored;
