import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrBetterThanTheBestSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-be-a)">
        <path d="M3.503 20V10h3.5V8.5h-5v13h5V20h-3.5z" />
        <path d="M11.772 2h-.632L8.238 8.56a2.75 2.75 0 0 0-.235 1.113v9.569a2.75 2.75 0 0 0 2.747 2.75l7.021.006a2.75 2.75 0 0 0 2.724-2.353l1.166-7.998a2.75 2.75 0 0 0-2.72-3.147h-5.82l1.166-2.638C15.091 4.044 13.76 2 11.772 2z" />
        <path d="M3.503 20V10h3.5V8.5h-5v13h5V20h-3.5z" />
        <path d="M11.772 2h-.632L8.238 8.56a2.75 2.75 0 0 0-.235 1.113v9.569a2.75 2.75 0 0 0 2.747 2.75l7.021.006a2.75 2.75 0 0 0 2.724-2.353l1.166-7.998a2.75 2.75 0 0 0-2.72-3.147h-5.82l1.166-2.638C15.091 4.044 13.76 2 11.772 2z" />
      </g>
      <defs>
        <clipPath id="br-be-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrBetterThanTheBest = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrBetterThanTheBestSvg} {...props} />
));
BrBetterThanTheBest.displayName = 'BrBetterThanTheBest';
export default BrBetterThanTheBest;
