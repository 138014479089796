import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UnlinkSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m14.586 16-3-3H7v-2h2.586l-2.98-2.98A4 4 0 0 0 7 16h3v2H7A6 6 0 0 1 4.946 6.36L1.004 2.419l1.414-1.414L23 21.586 21.586 23l-5-5H13v-2h1.586zM23 12c0 1.854-.84 3.511-2.162 4.612l-1.423-1.423A4 4 0 0 0 17 8h-4V6h4a6 6 0 0 1 6 6z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Unlink = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UnlinkSvg} {...props} />
));
Unlink.displayName = 'Unlink';
export default Unlink;
