import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const StarSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 17.877 18.798 22l-1.804-7.77L23 9.002l-7.909-.674L12 1 8.909 8.328 1 9.002l6.006 5.228L5.202 22 12 17.877z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Star = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={StarSvg} {...props} />
));
Star.displayName = 'Star';
export default Star;
