import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MetadataSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1 11v2.056h1c.098 0 .194-.004.29-.013A1 1 0 0 1 3 14v5.056a2.5 2.5 0 0 0 2.5 2.5H8v-2H5.5a.5.5 0 0 1-.5-.5V14c0-.755-.279-1.444-.739-1.972.46-.527.739-1.217.739-1.972V5a.5.5 0 0 1 .5-.5H8v-2H5.5A2.5 2.5 0 0 0 3 5v5.056a1 1 0 0 1-.71.958A3.047 3.047 0 0 0 2 11H1zM21 10.056a1 1 0 0 0 .71.958c.096-.01.192-.014.29-.014h1v2.056h-1c-.098 0-.194-.004-.29-.013A1 1 0 0 0 21 14v5.056a2.5 2.5 0 0 1-2.5 2.5h-2v-2h2a.5.5 0 0 0 .5-.5V14c0-.755.279-1.444.739-1.972A2.989 2.989 0 0 1 19 10.056V5a.5.5 0 0 0-.5-.5H16v-2h2.5A2.5 2.5 0 0 1 21 5v5.056zM12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      <path d="M17 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM8 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Metadata = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MetadataSvg} {...props} />
));
Metadata.displayName = 'Metadata';
export default Metadata;
