import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ShowFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.5 12a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z" />
      <path
        fillRule="evenodd"
        d="M22.834 11.44a16.196 16.196 0 0 0-.671-1.09 18.31 18.31 0 0 0-2.06-2.548C18.309 5.961 15.566 4 12 4 8.433 4 5.69 5.96 3.897 7.802a18.314 18.314 0 0 0-2.592 3.393 10.79 10.79 0 0 0-.176.314L.873 12l.256.491a7.02 7.02 0 0 0 .176.314 18.311 18.311 0 0 0 2.592 3.392C5.691 18.04 8.433 20 12 20c3.566 0 6.309-1.96 8.103-3.802a18.308 18.308 0 0 0 2.592-3.393c.06-.103.107-.186.139-.245.101-.184.196-.373.293-.56-.097-.187-.192-.376-.293-.56zM12 7.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ShowFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ShowFilledSvg} {...props} />
));
ShowFilled.displayName = 'ShowFilled';
export default ShowFilled;
