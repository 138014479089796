import type { MouseEventHandler } from 'react';
import { forwardRef } from 'react';

import type { PopperChildrenProps } from 'react-popper';
import type { SimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

import { ContextualHelp } from '@feather/components/ContextualHelp';
import cssVariables from '@feather/theme/cssVariables';
import { FeatherThemeContext, FeatherThemeEnum } from '@feather/themes';
import { typeface } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

import { TooltipWrapper } from './TooltipWrapper';
import type { TooltipRef, TooltipProps } from './types';
import { TooltipVariant } from './types';

type TooltipContentProps = PopperChildrenProps &
  Pick<TooltipProps, 'content' | 'tooltipContentStyle' | 'variant'> & {
    id?: string;
    onTooltipReferenceMouseLeave: MouseEventHandler<HTMLDivElement>;
  };

const TooltipContentWrapper = styled.div<{ $tooltipContentStyle?: SimpleInterpolation; variant?: TooltipVariant }>`
  z-index: ${ZIndexes.tooltip};
  border-radius: 4px;
  background-color: ${cssVariables('neutral-9')};
  padding: 6px 8px;
  max-width: 256px;
  line-height: 1;
  white-space: pre-wrap;
  word-break: keep-all;
  color: ${cssVariables('bg-1')};
  font-family: ${typeface.system};
  font-size: 12px;
  font-weight: 500;
  ${(props) => props.$tooltipContentStyle}
`;

const Arrow = styled.div`
  position: absolute;
  pointer-events: none;

  &[data-placement*='bottom'] {
    top: 0;
    margin-top: -6px;
    width: 12px;
    height: 6px;
    &::before {
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent ${cssVariables('neutral-9')} transparent;
    }
  }

  &[data-placement*='top'] {
    bottom: 0;
    margin-bottom: -6px;
    width: 12px;
    height: 6px;
    &::before {
      border-width: 6px 6px 0 6px;
      border-color: ${cssVariables('neutral-9')} transparent transparent transparent;
    }
  }

  &[data-placement*='right'] {
    left: 0;
    margin-left: -6px;
    height: 12px;
    width: 6px;
    &::before {
      border-width: 6px 6px 6px 0;
      border-color: transparent ${cssVariables('neutral-9')} transparent transparent;
    }
  }

  &[data-placement*='left'] {
    right: 0;
    margin-right: -6px;
    height: 12px;
    width: 6px;
    &::before {
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent ${cssVariables('neutral-9')};
    }
  }

  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    pointer-events: none;
  }
`;

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  ({ id, variant, content, style, placement, arrowProps, onTooltipReferenceMouseLeave, tooltipContentStyle }, ref) => (
    <FeatherThemeContext.Provider value={FeatherThemeEnum.Neutral}>
      <TooltipContentWrapper
        id={id}
        ref={ref}
        role="tooltip"
        onMouseLeave={onTooltipReferenceMouseLeave}
        style={style}
        variant={variant}
        $tooltipContentStyle={tooltipContentStyle}
      >
        {/*
         * Make sure that arrow is placed before the content to remove the bottom margin of the last
         * paragraph of the content.
         */}
        <Arrow ref={arrowProps.ref} style={arrowProps.style} data-placement={placement} data-is-arrow="true" />
        {content}
      </TooltipContentWrapper>
    </FeatherThemeContext.Provider>
  ),
);

/**
 * Tooltips display a text label identifying an element, such as a description of its function.
 */
export const Tooltip = forwardRef<TooltipRef, TooltipProps>((props, ref) => {
  /**
   * Default variant of Tooltip is TooltipVariant.Dark
   */
  const { variant = TooltipVariant.Dark } = props;

  if (variant === TooltipVariant.Light) {
    /**
     * ContextualHelp component is for Tooltips using TooltipVariant.Light
     */
    return <ContextualHelp ref={ref} {...props} />;
  }

  const { children, tooltipContentStyle, tooltipId, ...wrapperProps } = props;
  return (
    <TooltipWrapper {...wrapperProps} tooltipRef={ref} target={children}>
      {(popperChildrenProps) => (
        <TooltipContent
          id={tooltipId}
          variant={variant}
          tooltipContentStyle={tooltipContentStyle}
          content={props.content}
          {...popperChildrenProps}
        />
      )}
    </TooltipWrapper>
  );
});
