import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { transitionDefault, transitions } from '@feather/animation';
import { elevation } from '@feather/elevation';
import { cssVariables } from '@feather/theme';
import { Body } from '@feather/typography';

import type { DropdownSize } from '../../types/components/dropdown';

export const MonthIcon = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 11px 0 4px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  font-size: 0;
`;

export const MonthTarget = styled.button<{ isActive: boolean; styles: SimpleInterpolation; size: DropdownSize }>`
  display: flex;
  align-items: center;
  justify-content: left;
  transition: ${transitions({ duration: 0.2, properties: ['color', 'border', 'box-shadow', 'background-color'] })};
  outline: 0;
  border: 1px solid ${cssVariables('neutral-3')};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  min-width: 127px;
  height: ${({ size }) => (size === 'small' ? '32px' : '40px')};
  color: ${cssVariables('neutral-10')};
  ${Body['body-short-01']};

  svg {
    flex: none;
    transform: rotate(0);
    transition: all 0.2s ${transitionDefault};
    fill: ${cssVariables('neutral-9')};
  }

  &:hover,
  &:focus {
    color: ${cssVariables('purple-7')};

    svg {
      fill: ${cssVariables('purple-7')};
    }
  }

  &:hover,
  &:active {
    border: 1px solid ${cssVariables('purple-7')};
  }

  &:focus:not(:active) {
    border: 1px solid ${cssVariables('purple-7')};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${cssVariables('purple-7')};
      background-color: ${cssVariables('purple-2')};
      color: ${cssVariables('purple-7')};

      ${MonthIcon} svg {
        transform: rotate(180deg);
        fill: ${cssVariables('purple-7')};
      }
    `}
`;

export const MonthTargetStart = styled.time<{ isThisMonth?: boolean }>`
  flex: 1;
  padding-left: 12px;

  ${({ isThisMonth }) =>
    isThisMonth &&
    css`
      box-sizing: content-box;
      width: 95px;
    `}
`;

export const MonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: white;
  min-width: 200px;
  ${elevation.popover};
`;
