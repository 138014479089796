import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const InfoDuotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g id="Icon" fillRule="evenodd" strokeWidth={1}>
        <g id="Icon-/-Status-/-info-/-duotone-/-24" fillRule="nonzero">
          <circle id="Color_02" cx={12} cy={12} r={11} />
          <path
            id="Color_01"
            d="M13 12v5c0 1.333-2 1.333-2 0v-5c0-1.333 2-1.333 2 0zm0-4c0 1.333-2 1.333-2 0s2-1.333 2 0z"
          />
        </g>
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const InfoDuotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={InfoDuotoneSvg} {...props} />
));
InfoDuotone.displayName = 'InfoDuotone';
export default InfoDuotone;
