import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ReplyTemplateSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 4.5A2.5 2.5 0 0 1 4.5 2h15A2.5 2.5 0 0 1 22 4.5v11a2.5 2.5 0 0 1-2.5 2.5H6l-4 4V4.5zm2 12.672L5.172 16H19.5a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v12.672z"
        clipRule="evenodd"
      />
      <path d="m12 12.404 2.781 1.687-.738-3.179L16.5 8.774l-3.236-.276L12 5.5l-1.264 2.998-3.236.276 2.457 2.138-.738 3.179L12 12.404z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ReplyTemplate = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ReplyTemplateSvg} {...props} />
));
ReplyTemplate.displayName = 'ReplyTemplate';
export default ReplyTemplate;
