import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallVideoP2PSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.297 2.41 13.883.996l-3.707 3.707 3.707 3.707 1.414-1.414-1.293-1.293h5.172l-1.293 1.293 1.414 1.414 3.707-3.707L19.297.996 17.883 2.41l1.293 1.293h-5.172l1.293-1.293z" />
      <path d="M8.5 4H1v13.5A2.5 2.5 0 0 0 3.5 20h10a2.5 2.5 0 0 0 2.5-2.5v-2.42l5 4V10.5h-2v4.42l-5-4v6.58a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5V6h5.5V4z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallVideoP2P = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallVideoP2PSvg} {...props} />
));
CallVideoP2P.displayName = 'CallVideoP2P';
export default CallVideoP2P;
