import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SendbirdColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#6210CC" d="M12 2H2v5a5 5 0 0 0 5 5H2c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const SendbirdColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SendbirdColoredSvg} {...props} />
));
SendbirdColored.displayName = 'SendbirdColored';
export default SendbirdColored;
