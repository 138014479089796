import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FreezeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12.06 3.586 9.767 1.293 8.353 2.707l2.707 2.707v4.854L6.856 7.841l-.99-3.698-1.932.518.839 3.132-3.132.84.517 1.931 3.698-.99L10.06 12l-4.204 2.427-3.698-.99-.517 1.931 3.132.84-.84 3.131 1.933.518.99-3.698 4.204-2.427v4.854l-2.707 2.707 1.414 1.414 2.293-2.293 2.292 2.293 1.415-1.414-2.707-2.707v-4.854l4.203 2.427.991 3.698 1.932-.518-.84-3.132 3.133-.839-.518-1.932-3.698.991L14.06 12l4.203-2.427 3.698.991.518-1.932-3.132-.839.839-3.132-1.932-.518-.99 3.698-4.204 2.427V5.414l2.707-2.707-1.415-1.414-2.292 2.293z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Freeze = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FreezeSvg} {...props} />
));
Freeze.displayName = 'Freeze';
export default Freeze;
