import type { ReactNode, MutableRefObject, RefObject } from 'react';
import { useRef, useState, useLayoutEffect, forwardRef, useEffect } from 'react';

import rgba from 'polished/lib/color/rgba';
import styled, { css } from 'styled-components';

import { transitionDefault, transitions } from '@feather/animation';
import type { FeatherIconComponent, FeatherIconProps } from '@feather/components/icons/types';
import { shadow } from '@feather/elevation';
import cssVariables from '@feather/theme/cssVariables';
import type { NotificationType, ToastOption, ToastAction } from '@feather/types';
import { Body, Headings } from '@feather/typography';

import * as Icons from '../icons';
import { maxWidth, minWidth } from './constants';

const SINGLE_LINE_HEIGHT = 20;

type Layout = 'short' | 'tall';

export interface PrerenderResult {
  height: number;
  layout: Layout;
}

type Props = {
  status: NotificationType;
  title?: ReactNode;
  message: ReactNode;
  onActionClick?: (action: ToastAction) => void;
  onCloseButtonClick?: () => void;
  actions?: ToastOption['actions'];
  className?: string;
  prerender?: boolean;
  layout?: Layout;
  iconProps: { icon: FeatherIconComponent } & FeatherIconProps;
};

const baseColorMap: Record<NotificationType, string> = {
  info: cssVariables('neutral-10'),
  success: cssVariables('neutral-10'),
  warning: cssVariables('yellow-5'),
  error: cssVariables('red-5'),
};

const ToastTitle = styled.h3`
  margin-bottom: 4px;
  ${Headings['heading-01']};
`;

const ToastText = styled.div`
  flex: 1;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 16px;
  color: inherit;
  ${Body['body-short-01']};

  // If you're changing the line height, make sure that the change is reflected in the calculation in usePrerender.
  line-height: ${SINGLE_LINE_HEIGHT}px;
`;

const ToastCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ${transitionDefault};
  margin: 0;
  margin-left: 8px;
  border: none;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  width: 32px;
  height: 32px;
  color: inherit;
  -webkit-appearance: none;

  &:hover {
    background-color: ${rgba('white', 0.16)};
    cursor: pointer;
  }

  &:active {
    background-color: ${rgba('white', 0.12)};
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  > * + * {
    margin-left: 4px;
  }
`;

const ToastContainer = styled.div<{
  $baseColor: string;
  $layout?: Layout;
  $height?: number;
}>`
  position: relative;
  border-radius: 4px;
  background-color: ${({ $baseColor }) => $baseColor};
  overflow: hidden;
  color: ${({ $baseColor }) => ($baseColor === cssVariables('yellow-5') ? cssVariables('neutral-9') : 'white')};
  ${shadow[16]};

  ${({ $height }) => ($height ? `height: ${$height}px;` : null)}

  ${({ $layout }) =>
    $layout === 'tall' &&
    css`
      ${Actions} {
        justify-content: flex-end;
        order: 10;
        padding: 8px;
        width: 100%;
      }
    `}

  ${ToastText} {
    word-break: break-word;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 8px;
  padding-left: 52px;
  min-width: ${minWidth}px;
  max-width: ${maxWidth}px;
  min-height: 0;
`;

// FIXME: replace with overlay button
const ToastActionButton = styled.button<{ baseColor: string }>`
  display: flex;
  flex: none;
  align-items: center;
  align-self: center;
  justify-content: center;
  transition: ${transitions({ duration: 0.2, properties: ['color', 'background-color', 'border', 'box-shadow'] })};
  outline: none;
  border: 0;
  border-radius: 4px;
  background-color: ${({ baseColor }) =>
    baseColor === cssVariables('yellow-5') ? cssVariables('bg-overlay-1') : cssVariables('bg-inverse-overlay-1')};
  padding: 6px 16px;
  min-width: 80px;
  line-height: 1.43;
  color: ${({ baseColor }) => (baseColor === cssVariables('yellow-5') ? 'black' : 'white')};
  font-size: 14px;
  font-weight: 600;
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: ${rgba('white', 0.16)};
  }

  &:focus:not(:active) {
    box-shadow: 0 0 0 2px ${cssVariables('purple-7')};
  }
`;

const usePrerender = (params: {
  props: Pick<Props, 'prerender' | 'layout' | 'actions' | 'message'>;
  containerRef: RefObject<HTMLDivElement | null>;
  textRef: RefObject<HTMLDivElement | null>;
}) => {
  const {
    props: { layout, prerender, actions, message },
    containerRef,
    textRef,
  } = params;

  const [prerenderLayout, setPrerenderLayout] = useState<Layout>(layout || 'short');
  const [isPrerenderDone, setIsPrerenderDone] = useState(false);
  const [prerenderResult, setPrerenderResult] = useState<PrerenderResult | null>(null);

  const actionCount = actions?.length ?? 0;

  // This effect should be applied before DOM mutations are painted.
  useLayoutEffect(() => {
    if (prerender) {
      // if there are 2+ actions or the text wraps, move the actions to the next line.
      const moveActionsToNextLine = actionCount > 1 || (textRef.current?.scrollHeight ?? 0) > SINGLE_LINE_HEIGHT;
      setPrerenderLayout(moveActionsToNextLine ? 'tall' : 'short');
      setIsPrerenderDone(true);
    }
  }, [message, actionCount, prerender, textRef]);

  useEffect(() => {
    if (prerender && isPrerenderDone) {
      setPrerenderResult({
        height: containerRef.current?.scrollHeight ?? 0,
        layout: prerenderLayout,
      });
    }
  }, [isPrerenderDone, prerenderLayout, prerender, containerRef]);

  return prerenderResult;
};

export const ToastComponent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    status,
    title,
    message,
    actions,
    iconProps: { icon: StatusIcon, ...iconProps },
    onActionClick,
    onCloseButtonClick,
    className,
    layout: layoutProp,
    prerender,
  } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const toastBaseColor = baseColorMap[status];

  const prerenderResult = usePrerender({ props, containerRef, textRef });

  // If title exists, layout must be always `tall`.
  const layout = title ? 'tall' : prerenderResult?.layout ?? layoutProp;

  useEffect(() => {
    if (!prerender) {
      // set height CSS property to animate the height using CSS transition
      setHeight(containerRef.current?.scrollHeight ?? 0);
    }
  }, [containerRef, prerender]);

  return (
    <ToastContainer
      ref={(node) => {
        containerRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          (ref as MutableRefObject<HTMLDivElement | null>).current = node;
        }
      }}
      /**
       * do not remove status
       * classNames `error | info | warning | success` are used for custom styling
       */
      className={className ? `${className} ${status}` : status}
      role={prerender ? undefined : 'alert'}
      $baseColor={toastBaseColor}
      $layout={layout}
      $height={height}
      data-prerender-height={prerenderResult?.height ?? undefined}
      data-prerender-layout={prerenderResult?.layout ?? undefined}
    >
      <StatusIcon {...iconProps} style={{ position: 'absolute', top: 14, left: 16 }} />
      <ContentWrapper>
        <ToastText ref={textRef}>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message}
        </ToastText>
        {actions && actions.length > 0 && (
          <Actions>
            {actions.map((action) => {
              const { label, onClick } = action;

              return (
                <ToastActionButton
                  key={label}
                  type="button"
                  baseColor={toastBaseColor}
                  onClick={(event) => {
                    onClick?.(event);
                    onActionClick?.(action);
                  }}
                >
                  {label}
                </ToastActionButton>
              );
            })}
          </Actions>
        )}
        <ToastCloseButton type="button" onClick={onCloseButtonClick} title="Close">
          <Icons.Close size={20} color="currentColor" />
        </ToastCloseButton>
      </ContentWrapper>
    </ToastContainer>
  );
});
