import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrRocketSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.997 13.503C15.869 15.63 11.75 17 11.75 17l-1.779-1.779c.312.503.502 1.084.526 1.699a3.032 3.032 0 0 1-.872 2.285C8.383 20.447 3 21 3 21s.553-5.383 1.795-6.625a3.032 3.032 0 0 1 2.285-.872 3.486 3.486 0 0 1 1.699.526L7 12.25S8.372 8.127 10.499 6c5.343-5.343 9.966-3.94 11.028-3.52.408 1.06 1.813 5.68-3.53 11.023zM14 8c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z"
        clipRule="evenodd"
      />
      <path d="m10.5 4.416-2.425-.16a2.724 2.724 0 0 0-2.366 1.1L2 10.353l4.067 1.147.511-1.421-2.075-.585 2.4-3.235c.253-.339.657-.527 1.076-.5l1.119.074L10.5 4.416zM19.584 13.497l.16 2.426a2.724 2.724 0 0 1-1.1 2.366L13.647 22l-1.147-4.067 1.421-.512.585 2.075 3.235-2.4c.339-.253.527-.657.5-1.076l-.074-1.119 1.418-1.404z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrRocket = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrRocketSvg} {...props} />
));
BrRocket.displayName = 'BrRocket';
export default BrRocket;
