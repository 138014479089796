import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LinkSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M17 8h-4V6h4a6 6 0 0 1 0 12h-4v-2h4a4 4 0 0 0 0-8zM11 6v2H7a4 4 0 1 0 0 8h4v2H7A6 6 0 0 1 7 6h4z" />
      <path d="M7 13h10v-2H7v2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Link = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LinkSvg} {...props} />
));
Link.displayName = 'Link';
export default Link;
