import type { Dispatch, MouseEvent, MouseEventHandler, ReactElement, SetStateAction } from 'react';
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import cssVariables from '@feather/theme/cssVariables';

import { BadgeVariant } from '../../types';
import type { LeftNavigationBarMenuItem, LeftNavigationBarProps } from '../../types/components/leftNavigationBar';
import { LeftNavigationBarItemType } from '../../types/components/leftNavigationBar';
import {
  DividerItem,
  LabelItem,
  MenuBadge,
  MenuExpanderIcon,
  MenuGroupExpander,
  MenuIconPlaceholder,
  MenuItem,
  MenuItemLabel,
  MenuItemLink,
  MenuItemReactRouterLink,
  SubMenuBadge,
  SubMenuBadgeArea,
  SubMenuItem,
  SubMenuItemLabel,
  SubMenuItemLink,
  SubMenuItemReactRouterLink,
} from './styled';

type Props = {
  type: LeftNavigationBarItemType;
  item: LeftNavigationBarMenuItem;
  subMenuParentKey?: string;
  isLNBCollapsed: boolean;
  isLNBHovered: boolean;
  collapsedLNBWidth: number;
  activeGroup: LeftNavigationBarProps['activeGroup'];
  expandedGroup: string | undefined;
  setExpandedGroup: Dispatch<SetStateAction<string>>;
  onEnter: MouseEventHandler;
  activeKey: string | number | undefined;
};

export const LNBMenuItem = memo<Props>(
  ({
    item,
    subMenuParentKey,
    activeGroup,
    activeKey,
    isLNBCollapsed,
    isLNBHovered,
    collapsedLNBWidth,
    expandedGroup,
    setExpandedGroup,
    onEnter,
  }) => {
    const [isCurrentGroupCollapsed, setIsCurrentGroupCollapsed] = useState(true);
    const handleToggleGroupCollapse = useCallback(
      ({ e, group }: { e: MouseEvent; group: string }) => {
        e.preventDefault();
        e.stopPropagation();
        if (expandedGroup === group) {
          setIsCurrentGroupCollapsed((prev) => !prev);
          return;
        }
        setExpandedGroup(group);
      },
      [expandedGroup, setExpandedGroup],
    );

    const subMenuRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
      const isSubmenuOverflowed = subMenuRef.current && subMenuRef.current?.clientHeight > 36; // 36 is the height of the label
      if (isSubmenuOverflowed) {
        subMenuRef.current.style.lineHeight = '20px';
        subMenuRef.current.style.paddingTop = '8px';
        subMenuRef.current.style.paddingBottom = '8px';
      }
    }, []);

    useEffect(() => {
      if ('group' in item && item.group) {
        expandedGroup === item.group ? setIsCurrentGroupCollapsed(false) : setIsCurrentGroupCollapsed(true);
      }
    }, [expandedGroup, item]);

    switch (item.type) {
      case LeftNavigationBarItemType.Group: {
        const { icon: MenuIcon, label, labelSuffix, items, group } = item;
        const isCurrentActiveGroup = group === activeGroup;
        const isCurrentActiveGroupCollapsed = isCurrentActiveGroup && isCurrentGroupCollapsed;
        const isCurrentActiveGroupOnNarrowScreen =
          isCurrentActiveGroup && !isCurrentGroupCollapsed && isLNBCollapsed && !isLNBHovered;
        const shouldBeHighlighted = isCurrentActiveGroupCollapsed || isCurrentActiveGroupOnNarrowScreen;
        return (
          <>
            <MenuGroupExpander
              id={group}
              aria-pressed={isCurrentGroupCollapsed}
              onClick={(e) => handleToggleGroupCollapse({ e, group })}
              onMouseEnter={onEnter}
              $isLNBCollapsed={isLNBCollapsed}
              $isHovered={isLNBHovered}
              $isHighlighted={shouldBeHighlighted}
              $isCollapsed={isCurrentGroupCollapsed}
            >
              <MenuIconPlaceholder>
                {MenuIcon && <MenuIcon size={20} color={cssVariables('neutral-8')} />}
              </MenuIconPlaceholder>
              <MenuItemLabel $isLNBCollapsed={isLNBCollapsed} $isLNBHovered={isLNBHovered}>
                {label}
              </MenuItemLabel>
              {labelSuffix}
              <MenuExpanderIcon
                data-test-id="ChevronIcon"
                size={16}
                color={cssVariables('neutral-8')}
                onClick={(e) => handleToggleGroupCollapse({ e, group })}
                $isCollapsed={isCurrentGroupCollapsed}
              />
            </MenuGroupExpander>
            {items.map(
              (item) =>
                !isCurrentGroupCollapsed &&
                (!isLNBCollapsed || isLNBHovered) && (
                  <LNBMenuItem
                    type={item.type}
                    key={item.key}
                    item={item}
                    activeKey={activeKey}
                    isLNBCollapsed={isLNBCollapsed}
                    isLNBHovered={isLNBHovered}
                    collapsedLNBWidth={collapsedLNBWidth}
                    activeGroup={activeGroup}
                    expandedGroup={expandedGroup}
                    setExpandedGroup={setExpandedGroup}
                    onEnter={onEnter}
                  />
                ),
            )}
          </>
        );
      }
      case LeftNavigationBarItemType.Item: {
        const {
          group,
          key,
          href,
          icon: MenuIcon,
          badgeCount,
          label,
          labelSuffix,
          useReactRouterLink,
          target,
          onClick: itemOnClick,
          disabled,
          subMenus,
          id,
        } = item;

        const hasSubMenu = subMenus && subMenus.length > 1;
        const isActive = activeKey === key;
        const totalBadgeCount = hasSubMenu
          ? subMenus?.reduce((acc, subMenu) => acc + (subMenu.badgeCount ?? 0), badgeCount ?? 0)
          : badgeCount;

        const linkId = group ? `${group}-${key}` : (key as string);
        const children = (
          <>
            <MenuIconPlaceholder>
              {MenuIcon && (
                <MenuIcon size={20} color={isActive ? cssVariables('purple-7') : cssVariables('neutral-8')} />
              )}
              {typeof totalBadgeCount === 'number' && totalBadgeCount > 0 && (
                <MenuBadge count={totalBadgeCount} color="red" variant={BadgeVariant.Dark} max={99} />
              )}
            </MenuIconPlaceholder>
            <MenuItemLabel id={id} $isLNBCollapsed={isLNBCollapsed} $isLNBHovered={isLNBHovered}>
              {label}
            </MenuItemLabel>
            {labelSuffix}
          </>
        );

        let result: ReactElement;

        if (href) {
          if (useReactRouterLink) {
            result = (
              <MenuItemReactRouterLink
                id={linkId}
                key={key}
                to={href}
                target={target}
                onMouseEnter={onEnter}
                children={children}
                $isActive={isActive}
                $isDisabled={disabled}
                $shouldBoldLabel={!!group}
              />
            );
          } else {
            result = (
              <MenuItemLink
                id={linkId}
                key={key}
                href={href}
                target={target}
                children={children}
                onMouseEnter={onEnter}
                $isActive={isActive}
                $isDisabled={disabled}
                $shouldBoldLabel={!!group}
              />
            );
          }
        } else {
          result = (
            <MenuItem
              id={linkId}
              key={key}
              role="link"
              onClick={itemOnClick}
              children={children}
              onMouseEnter={onEnter}
              $isActive={isActive}
              $shouldBoldLabel={!!group}
              $isDisabled={disabled}
            />
          );
        }
        return (
          <>
            {result}
            {subMenus && (!isLNBCollapsed || isLNBHovered) && (
              <>
                {subMenus.map((subMenu) => (
                  <LNBMenuItem
                    type={item.type}
                    key={subMenu.key}
                    subMenuParentKey={linkId}
                    item={subMenu}
                    activeKey={activeKey}
                    activeGroup={activeGroup}
                    isLNBCollapsed={isLNBCollapsed}
                    isLNBHovered={isLNBHovered}
                    collapsedLNBWidth={collapsedLNBWidth}
                    expandedGroup={expandedGroup}
                    setExpandedGroup={setExpandedGroup}
                    onEnter={onEnter}
                  />
                ))}
              </>
            )}
          </>
        );
      }
      case LeftNavigationBarItemType.SubItem: {
        const {
          group,
          key,
          href,
          badgeCount,
          label,
          labelSuffix,
          useReactRouterLink,
          onClick: itemOnClick,
          disabled,
        } = item;
        const isActive = activeKey === key;
        const linkId = subMenuParentKey ? `${subMenuParentKey}-${key}` : `${group}-${key}`;

        const children = (
          <>
            <SubMenuItemLabel ref={subMenuRef} $isActive={isActive}>
              {label}
              {labelSuffix}
            </SubMenuItemLabel>
            {typeof badgeCount === 'number' && badgeCount > 0 && (
              <SubMenuBadgeArea>
                <SubMenuBadge count={badgeCount} color="red" variant={BadgeVariant.Dark} max={99} />
              </SubMenuBadgeArea>
            )}
          </>
        );

        if (href) {
          if (useReactRouterLink) {
            return (
              <SubMenuItemReactRouterLink
                id={linkId}
                key={key}
                to={href}
                onClick={itemOnClick}
                children={children}
                $isActive={isActive}
                $isDisabled={disabled}
                $shouldBoldLabel={!!group}
              />
            );
          }
          return (
            <SubMenuItemLink
              id={linkId}
              key={key}
              href={href}
              onClick={itemOnClick}
              children={children}
              $isActive={isActive}
              $isDisabled={disabled}
              $shouldBoldLabel={!!group}
            />
          );
        }
        return (
          <SubMenuItem
            id={linkId}
            key={key}
            role="link"
            onClick={itemOnClick}
            children={children}
            $isActive={isActive}
            $isDisabled={disabled}
            $shouldBoldLabel={!!group}
          />
        );
      }
      case LeftNavigationBarItemType.Label:
        return (
          <LabelItem key={item.key}>
            {item.label}
            {item.labelSuffix}
          </LabelItem>
        );
      case LeftNavigationBarItemType.Divider:
        return <DividerItem />;
      default:
        return null;
    }
  },
);
