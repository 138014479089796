import styled from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import { Body } from '@feather/typography';

export const MENU_SCROLLBAR_VERTICAL_PADDING = 8;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  color: ${cssVariables('neutral-5')};
  text-align: center;
  ${Body['body-short-01']};
`;

const getTransitionConstantObject = (durationMs: number) => {
  const durationCss = `${durationMs}ms`;
  return {
    duration: { css: durationCss, milliseconds: durationMs },
    css: `${durationCss} ${transitionDefault}`,
  };
};

/** constants for the transition of dropdown elements like toggle buttons, menu items, ... */
export const defaultTransition = getTransitionConstantObject(200);

/** constants for the fade transition of a dropdown menu */
export const menuTransition = getTransitionConstantObject(100);

export const defaultEmptyView = <Wrapper>No results</Wrapper>;

export const defaultItemToString = (item) => `${item}`;
