import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrLivestreamSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-li-a)" clipRule="evenodd">
        <path d="M3.996 5.75A2.75 2.75 0 0 1 6.746 3h12.5a2.75 2.75 0 0 1 2.75 2.75V6h-1.5v-.25c0-.69-.56-1.25-1.25-1.25h-12.5c-.69 0-1.25.56-1.25 1.25V6h-1.5v-.25z" />
        <path d="M21.996 7h-18v12a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7zm-11 10 5-3-5-3v6z" />
      </g>
      <defs>
        <clipPath id="br-li-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrLivestream = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrLivestreamSvg} {...props} />
));
BrLivestream.displayName = 'BrLivestream';
export default BrLivestream;
