import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HideFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.14 18.553 21.585 23 23 21.586 2.418 1.004 1.004 2.418 5.195 6.61c-.476.389-.91.793-1.298 1.192a18.314 18.314 0 0 0-2.592 3.393 10.79 10.79 0 0 0-.176.314L.873 12l.256.491a7.02 7.02 0 0 0 .176.314 18.311 18.311 0 0 0 2.592 3.392C5.691 18.04 8.433 20 12 20c1.972 0 3.692-.6 5.14-1.447zm-2.744-2.743-1.482-1.482a2.5 2.5 0 0 1-3.241-3.242L8.19 9.604a4.5 4.5 0 0 0 6.206 6.206z"
        clipRule="evenodd"
      />
      <path d="M22.163 13.65a18.389 18.389 0 0 1-1.903 2.384l-3.768-3.768a4.5 4.5 0 0 0-4.758-4.758L8.78 4.553A9.682 9.682 0 0 1 12 4c3.567 0 6.31 1.96 8.104 3.802a18.31 18.31 0 0 1 2.592 3.393 10.405 10.405 0 0 1 .346.64l.086.165-.082.16c-.07.134-.139.268-.211.4a16.196 16.196 0 0 1-.671 1.09z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const HideFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HideFilledSvg} {...props} />
));
HideFilled.displayName = 'HideFilled';
export default HideFilled;
