import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeskHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.348 14.699c0 1.498 1.202 2.712 2.686 2.712h7.164c1.484 0 2.687-1.214 2.687-2.712v-11.3h-9.85c-1.485 0-2.687 1.214-2.687 2.712V14.7zm29.54-4.793v-5.13h2.79v13.54h-2.79v-1.398c-.672 1.02-1.722 1.599-3.087 1.599-2.514 0-4.513-2.297-4.513-5.114 0-2.818 1.999-5.095 4.512-5.095 1.366 0 2.415.579 3.088 1.598zm-4.868 3.496a2.41 2.41 0 0 0 2.434 2.418c1.365 0 2.434-1.058 2.434-2.418a2.41 2.41 0 0 0-2.434-2.417 2.41 2.41 0 0 0-2.434 2.418zm8.707-.04c0-2.857 2.217-5.054 5.047-5.054l-.001-.001c2.633 0 4.79 2.078 4.79 4.775 0 .22-.02.68-.06 1H39.28c.198 1.278 1.168 2.097 2.474 2.097 1.09 0 1.782-.48 1.94-1.159h2.732c-.317 2.119-2.158 3.497-4.592 3.497-2.87 0-5.106-2.299-5.106-5.155zm4.928-2.778c-1.069 0-1.9.54-2.236 1.699h4.235c-.079-.999-.93-1.699-1.999-1.699zm8.333.74c0-.458.475-.679 1.108-.679.89 0 1.424.44 1.465.94h2.81c-.237-2.178-2.117-3.277-4.215-3.277-2.395 0-3.86 1.399-3.86 3.217 0 2.195 1.97 2.554 3.543 2.84 1.047.19 1.92.35 1.92.996 0 .46-.476.74-1.307.74-.93 0-1.623-.44-1.682-1.1h-2.673c.198 2.218 1.959 3.516 4.493 3.516 2.197 0 3.879-1.378 3.879-3.175 0-2.546-2.118-2.892-3.721-3.154-.976-.16-1.762-.288-1.762-.862h.002v-.001zm-32.76-3.857c1.978 0 3.582 1.62 3.582 3.616a3.62 3.62 0 0 1-1.79 3.132v1.059c0 2.927-2.352 5.3-5.252 5.3H9.616v-1.807h4.152c1.911 0 3.46-1.564 3.46-3.493V7.467zm48.889 1.04-4.038 4.736 4.354 5.074h-3.581l-3.523-4.395v4.395h-2.791V4.777h2.79v7.786l3.365-4.055h3.424z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 70, height: 24 },
);
const DeskHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeskHorizontalSvg} {...props} />
));
DeskHorizontal.displayName = 'DeskHorizontal';
export default DeskHorizontal;
