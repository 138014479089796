import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HorizontalAlignBottonSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 19v-2h18v2H3zM8 15V5h8v8a2 2 0 0 1-2 2H8z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const HorizontalAlignBotton = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HorizontalAlignBottonSvg} {...props} />
));
HorizontalAlignBotton.displayName = 'HorizontalAlignBotton';
export default HorizontalAlignBotton;
