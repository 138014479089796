import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallLogsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 6v2h2V6H3zM7 6v2h14V7l-.001-1H7zM7 13v-2h13.999L21 12v1H7zM7 16v2h14v-1l-.001-1H7zM3 13v-2h2v2H3zM3 16v2h2v-2H3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallLogs = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallLogsSvg} {...props} />
));
CallLogs.displayName = 'CallLogs';
export default CallLogs;
