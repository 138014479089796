import { forwardRef } from 'react';

import type { SimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

import { TooltipWrapper } from '@feather/components/tooltip/TooltipWrapper';
import type { TooltipRef, TooltipProps } from '@feather/components/tooltip/types';
import { elevation } from '@feather/elevation';
import cssVariables from '@feather/theme/cssVariables';
import { FeatherThemeContext, FeatherThemeEnum } from '@feather/themes';
import { typeface } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

const TooltipContent = styled.div<{ $tooltipContentStyle: SimpleInterpolation }>`
  padding: 16px 20px;
  border-radius: 4px;
  background-color: ${cssVariables('bg-1')};
  border: 1px solid ${cssVariables('neutral-4')};
  z-index: ${ZIndexes.tooltip};
  font-family: ${typeface.system};
  font-size: 14px;
  line-height: 1.43;
  color: ${cssVariables('neutral-10')};
  ${elevation.popover}

  ${(props) => props.$tooltipContentStyle}
`;

// `&::before` is for 1px border and `&::after` is for background.
const BorderArrow = styled.div`
  position: absolute;
  pointer-events: none;

  &[data-placement*='bottom'] {
    top: 0;

    &::before {
      position: absolute;
      left: -8px;
      margin-top: -8px;
      width: 16px;
      height: 8x;
      border-top-width: 0;
      border-bottom-color: ${cssVariables('neutral-3')};
    }

    &::after {
      left: -7px;
      margin-top: -7px;
      width: 14px;
      height: 7px;
      border-top-width: 0;
      border-bottom-color: white;
    }
  }

  &[data-placement*='top'] {
    bottom: 0;

    &::before {
      position: absolute;
      left: -8px;
      margin-bottom: -8px;
      width: 16px;
      height: 8px;
      border-bottom-width: 0;
      border-top-color: ${cssVariables('neutral-3')};
    }

    &::after {
      left: -7px;
      margin-bottom: -7px;
      width: 14px;
      height: 7px;
      border-bottom-width: 0;
      border-top-color: white;
    }
  }

  &[data-placement*='left'] {
    right: 0;

    &::before {
      position: absolute;
      top: -8px;
      margin-right: -8px;
      width: 8px;
      height: 16x;
      border-right-width: 0;
      border-left-color: ${cssVariables('neutral-3')};
    }

    &::after {
      top: -7px;
      margin-right: -7px;
      width: 7px;
      height: 14px;
      border-right-width: 0;
      border-left-color: white;
    }
  }

  &[data-placement*='right'] {
    left: 0;

    &::before {
      position: absolute;
      top: -8px;
      margin-left: -8px;
      width: 8px;
      height: 16x;
      border-left-width: 0;
      border-right-color: ${cssVariables('neutral-3')};
    }

    &::after {
      top: -7px;
      margin-left: -7px;
      width: 7px;
      height: 14px;
      border-left-width: 0;
      border-right-color: white;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    pointer-events: none;
    border-color: transparent;
  }

  &::before {
    border-width: 8px;
  }
  &::after {
    border-width: 7px;
  }
`;

/**
 * @deprecated Use Tooltip with variant: 'light' instead.
 */
export const ContextualHelp = forwardRef<TooltipRef, TooltipProps>(
  ({ children, tooltipContentStyle, ...props }, ref) => {
    return (
      <TooltipWrapper {...props} tooltipRef={ref} target={children}>
        {({ ref, style, placement, arrowProps, onTooltipReferenceMouseLeave }) => {
          return (
            <FeatherThemeContext.Provider value={FeatherThemeEnum.Default}>
              <TooltipContent
                ref={ref}
                role="tooltip"
                onMouseLeave={onTooltipReferenceMouseLeave}
                style={style}
                $tooltipContentStyle={tooltipContentStyle}
              >
                <BorderArrow
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  data-placement={placement}
                  data-is-arrow="true"
                />
                {props.content}
              </TooltipContent>
            </FeatherThemeContext.Provider>
          );
        }}
      </TooltipWrapper>
    );
  },
);
