import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BentRightSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m17.586 15-2.293-2.293 1.414-1.414L21.414 16l-4.707 4.707-1.414-1.414L17.586 17H5.5A2.5 2.5 0 0 1 3 14.5V4h2v10.5a.5.5 0 0 0 .5.5h12.086z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BentRight = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BentRightSvg} {...props} />
));
BentRight.displayName = 'BentRight';
export default BentRight;
