import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ToneAdjustmentSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM16 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM7.1 12a5.002 5.002 0 0 0 9.8 0h-2.071a3.001 3.001 0 0 1-5.659 0H7.1z" />
      <path
        fillRule="evenodd"
        d="M3 11a9 9 0 1 1 14.152 7.38 8.01 8.01 0 0 1 2.45 3.62h-2.146a6.024 6.024 0 0 0-2.265-2.582A8.98 8.98 0 0 1 12 20a8.98 8.98 0 0 1-3.19-.582A6.025 6.025 0 0 0 6.543 22H4.398a8.01 8.01 0 0 1 2.45-3.62A8.99 8.99 0 0 1 3 11zm9-7a7 7 0 1 0 0 14 7 7 0 0 0 0-14z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ToneAdjustment = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ToneAdjustmentSvg} {...props} />
));
ToneAdjustment.displayName = 'ToneAdjustment';
export default ToneAdjustment;
