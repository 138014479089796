import type { ReactNode, InputHTMLAttributes, MutableRefObject } from 'react';
import { useRef, useEffect, useCallback, forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { transitions } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { HasIndeterminateClass } from '@feather/types';
import { Body } from '@feather/typography';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> &
  Partial<HasIndeterminateClass> & {
    label?: ReactNode;
    hasError?: boolean;
  };

const Input = styled.input`
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid ${cssVariables('content-2')};
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  transition: ${transitions({ duration: 0.2, properties: ['background-color', 'border-color', 'box-shadow'] })};

  &:disabled {
    border: 2px solid ${cssVariables('content-disabled')};
    &:checked {
      background-color: ${cssVariables('bg-primary-disabled')};
      border-color: ${cssVariables('bg-primary-disabled')};
    }
  }

  &:focus:not(:active) {
    box-shadow: ${cssVariables('primary')} 0px 0px 0px 2px;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:checked {
    border-color: ${cssVariables('primary')};
    background-color: ${cssVariables('primary')};
  }

  &:indeterminate {
    border-color: ${cssVariables('primary')};
    background-color: ${cssVariables('primary')};

    &:disabled {
      background-color: ${cssVariables('bg-primary-disabled')};
      border-color: ${cssVariables('bg-primary-disabled')};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 9px;
      left: 5px;
      width: 10px;
      height: 2px;
      border-radius: 1px;
      background-color: white;
    }
  }
`;

const CheckmarkContainer = styled.div`
  opacity: 0;
  position: absolute;
  display: flex;
  align-items: center;
  top: 6px;
  left: 5px;
  transition: ${transitions({ duration: 0.2, properties: ['opacity'] })};
  pointer-events: none;
  svg {
    fill: white;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;

  input:checked:not(:indeterminate) + ${CheckmarkContainer} {
    opacity: 1;
  }
`;

const Label = styled.label<{ hasLabel?: boolean }>`
  display: inline-block;
  flex: 1;
  transition: ${transitions({ duration: 0.2, properties: ['color'] })};
  margin-left: ${(props) => (props.hasLabel ? 8 : 0)}px;
  cursor: pointer;
  color: ${cssVariables('content-1')};
  ${Body['body-short-01']};
`;

const Wrapper = styled.div<{ hasError?: boolean; disabled?: boolean }>`
  display: flex;

  ${(props) =>
    props.hasError &&
    css`
      input {
        border-color: ${cssVariables('negative')};
      }

      ${Label} {
        color: ${cssVariables('negative')};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      ${Label} {
        color: ${cssVariables('content-disabled')};
      }
    `}
`;

const Checkmark = () => (
  <CheckmarkContainer>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9">
      <path
        fillRule="evenodd"
        d="M8.327 1.26a1 1 0 1 1 1.346 1.48l-5.5 5a1 1 0 0 1-1.346 0l-2.5-2.273a1 1 0 0 1 1.346-1.48L3.5 5.65 8.327 1.26z"
      />
    </svg>
  </CheckmarkContainer>
);

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, name, label, className, indeterminate = false, hasError = false, disabled, ...rest }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate;
      }

      if (ref) {
        typeof ref === 'function'
          ? ref(inputRef.current)
          : ((ref as MutableRefObject<HTMLInputElement | null>).current = inputRef.current);
      }
    });

    const handleMouseUp = useCallback(() => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }, []);

    return (
      <Wrapper hasError={hasError} disabled={disabled} className={className}>
        <Container>
          <Input
            ref={inputRef}
            id={id || name}
            name={name}
            type="checkbox"
            disabled={disabled}
            onMouseUp={handleMouseUp}
            {...rest}
          />
          <Checkmark />
        </Container>
        <Label htmlFor={id || name} hasLabel={!!label}>
          {label}
        </Label>
      </Wrapper>
    );
  },
);
