import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const RadioSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.333 8a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0z" />
      <path
        fillRule="evenodd"
        d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334zM13.333 8A5.333 5.333 0 1 1 2.667 8a5.333 5.333 0 0 1 10.666 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 16, height: 16 },
);
const Radio = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={RadioSvg} {...props} />
));
Radio.displayName = 'Radio';
export default Radio;
