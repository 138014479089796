import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PushPinFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m19.36 11.191 1.83 1.83 1.415-1.414L11.998 1l-1.414 1.415 1.83 1.83L7.9 8.761c-1.724-.08-3.45.497-4.74 1.786l-.707.707 4.243 4.242-4.95 4.95L3.16 21.86l4.95-4.95 4.243 4.243.707-.707c1.289-1.289 1.866-3.015 1.786-4.74l4.515-4.515z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PushPinFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PushPinFilledSvg} {...props} />
));
PushPinFilled.displayName = 'PushPinFilled';
export default PushPinFilled;
