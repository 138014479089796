import { forwardRef } from 'react';

import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { LinkProps } from '@feather/types';
import { LinkVariant } from '@feather/types';

const getColors = (variant: LinkProps['variant']) => {
  switch (variant) {
    case LinkVariant.Neutral:
      return {
        color: cssVariables('neutral-10'),
        hoverColor: cssVariables('purple-7'),
        focusColor: cssVariables('purple-7'),
        activeColor: cssVariables('neutral-9'),
        disabledColor: cssVariables('neutral-5'),
      };
    case LinkVariant.Default:
    case LinkVariant.Inline:
      return {
        color: cssVariables('purple-7'),
        hoverColor: cssVariables('purple-8'),
        focusColor: cssVariables('purple-7'),
        activeColor: cssVariables('neutral-9'),
        disabledColor: cssVariables('neutral-5'),
      };
    case LinkVariant.Mono:
      return {
        color: 'inherit',
        hoverColor: 'inherit',
        focusColor: 'inherit',
        activeColor: 'inherit',
        disabledColor: 'inherit',
        hoverOpacity: 0.48,
        activeOpacity: 0.72,
        disabledOpacity: 0.32,
      };
    default:
      return {};
  }
};

const getTextDecoration = (variant: LinkProps['variant']) => {
  switch (variant) {
    case LinkVariant.Inline:
    case LinkVariant.Mono:
      return 'text-decoration: underline;';
    default:
      return 'text-decoration: none;';
  }
};

const LinkIcon = styled.div<{ variant: LinkVariant }>`
  margin-left: 4px;
  display: inline;

  ${(props) =>
    props.variant === LinkVariant.Inline &&
    css`
      vertical-align: middle;
      transform: translateY(-6.25%);
    `}
`;

type AnchorCSSProps = { variant: LinkProps['variant']; $isClickable: boolean };

const anchorCSS = ({ variant, $isClickable }: AnchorCSSProps) => {
  const { color, hoverColor, activeColor, focusColor, disabledColor, hoverOpacity, activeOpacity, disabledOpacity } =
    getColors(variant);

  return css`
    ${variant === LinkVariant.Inline
      ? css`
          display: inline;
        `
      : css`
          display: inline-flex;
          flex-direction: row;
          align-items: center;
        `}
    color: ${color};
    font-weight: 500;
    cursor: ${$isClickable ? 'pointer' : 'initial'};
    ${getTextDecoration(variant)}

    transition: 0.2s ${transitionDefault};
    transition-property: color, outline, opacity, box-shadow;

    svg {
      fill: ${color};
      transition: fill 0.2s ${transitionDefault};
    }

    :hover {
      color: ${hoverColor};
      text-decoration: underline;
      opacity: ${hoverOpacity};

      svg {
        fill: ${hoverColor};
      }
    }

    :not(:active):focus {
      color: ${focusColor};
      box-shadow: 0 0 0 2px ${cssVariables('purple-7')};
      outline: 0;
    }

    :active {
      color: ${activeColor};
      opacity: ${activeOpacity};

      svg {
        fill: ${cssVariables('neutral-9')};
      }
    }

    &[aria-disabled='true'] {
      pointer-events: none;
      color: ${disabledColor};
      opacity: ${disabledOpacity};

      svg {
        fill: ${cssVariables('neutral-5')};
      }
    }
  `;
};

const Anchor = styled.a<AnchorCSSProps>`
  ${anchorCSS}
`;

const StyledReactRouterDomLink = styled(ReactRouterDomLink)<AnchorCSSProps>`
  ${anchorCSS}
`;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { children, iconProps, variant = LinkVariant.Default, disabled = false, useReactRouter, href, ...anchorAttributes },
    ref,
  ) => {
    const { onClick } = anchorAttributes;

    const isValidLink = !!href && (typeof href === 'string' || useReactRouter);
    const $isClickable = (isValidLink || typeof onClick === 'function') && !disabled;

    const renderIcon = () => {
      if (!iconProps) {
        return null;
      }
      const { icon: Icon, ...props } = iconProps;
      return <LinkIcon as={Icon} variant={variant} color="currentColor" {...props} />;
    };

    const commonProps = {
      variant,
      $isClickable,
      ...anchorAttributes,
      ...(disabled ? { tabIndex: -1, role: 'button', 'aria-disabled': true } : null),
    };

    if (useReactRouter && href) {
      return (
        <StyledReactRouterDomLink to={href} ref={ref} {...commonProps}>
          {children}
          {renderIcon()}
        </StyledReactRouterDomLink>
      );
    }

    return (
      <Anchor href={typeof href === 'string' ? href : undefined} ref={ref} {...commonProps}>
        {children}
        {renderIcon()}
      </Anchor>
    );
  },
);
