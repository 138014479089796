import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AdminSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.103 15.777c.648-.82.897-1.482.897-1.97V5.952l-6-1.86-6 1.86v7.853c0 .489.248 1.151.897 1.97.63.798 1.491 1.555 2.341 2.192 1 .75 2.018 1.36 2.762 1.771a24.445 24.445 0 0 0 2.762-1.771M12 22a27.149 27.149 0 0 0 3.96-2.431c1.843-1.381 4.04-3.501 4.04-5.763V4.48L12 2 4 4.48v9.326c0 2.262 2.197 4.382 4.04 5.763A27.147 27.147 0 0 0 12 22zm5.103-6.223c-.63.797-1.491 1.554-2.341 2.191l2.34-2.191z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="m15.804 10.264-1.902 1.854.45 2.618L12 13.5l-2.351 1.236.449-2.618-1.902-1.854 2.628-.382L12 7.5l1.176 2.382 2.628.382z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Admin = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AdminSvg} {...props} />
));
Admin.displayName = 'Admin';
export default Admin;
