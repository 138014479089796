import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VendorSquarespaceSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m3.345 10.153 5.06-5.032a1.829 1.829 0 0 1 2.574 0l.393.391.789-.784-.393-.394a2.949 2.949 0 0 0-4.156 0L2.555 9.368l.79.785z" />
      <path d="m10.101 6.778-.788-.785-5.063 5.04a1.827 1.827 0 0 1-2.577-.002 1.81 1.81 0 0 1 0-2.566l4.368-4.347-.788-.785L.885 7.681A2.924 2.924 0 0 0 0 9.755a2.912 2.912 0 0 0 .86 2.085 2.94 2.94 0 0 0 2.094.857 2.952 2.952 0 0 0 2.085-.88l5.062-5.04zm5.04.903a2.95 2.95 0 0 0-4.156 0L5.922 12.72l.789.784 5.062-5.039a1.825 1.825 0 0 1 1.986-.391 1.821 1.821 0 0 1 1.125 1.675 1.778 1.778 0 0 1-.539 1.283L9.983 15.38l.79.784 4.368-4.347a2.921 2.921 0 0 0 0-4.136z" />
      <path d="m12.68 9.345-5.062 5.037a1.83 1.83 0 0 1-2.58 0l-.392-.393-.789.785.393.393a2.95 2.95 0 0 0 4.156 0l5.063-5.04-.789-.782z" />
    </svg>
  )),
  { width: 16, height: 20 },
);
const VendorSquarespace = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VendorSquarespaceSvg} {...props} />
));
VendorSquarespace.displayName = 'VendorSquarespace';
export default VendorSquarespace;
