import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PermissionFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7 17.662A9.955 9.955 0 0 0 12 19c1.821 0 3.53-.487 5-1.338v5.281l-5-1.875-5 1.875v-5.28zM12 1a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PermissionFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PermissionFilledSvg} {...props} />
));
PermissionFilled.displayName = 'PermissionFilled';
export default PermissionFilled;
