import type { CSSProperties } from 'react';
import { forwardRef, useState } from 'react';

import { nanoid } from 'nanoid/non-secure';
import styled from 'styled-components';

import type { FeatherInnerIconProps } from './types';

const InnerIcon = forwardRef<SVGSVGElement, FeatherInnerIconProps>(
  (
    {
      svgComponent: SVGComponent,
      assistiveText: title,
      titleId: titleIdProp,
      size,
      color = '#000',
      secondaryColor = '#fff',
      style: styleProp,
      className,
      role,
      ...props
    },
    ref,
  ) => {
    const [titleId] = useState(titleIdProp || `id_${nanoid()}`);
    const { width, height } = SVGComponent;
    const style = {
      ...styleProp,
      '--icon-primary-color': color,
      '--icon-secondary-color': secondaryColor,
    } as CSSProperties;
    return (
      <SVGComponent
        {...props}
        ref={ref}
        style={style}
        width={size}
        height={(size * height) / width}
        titleId={titleId}
        title={title}
        role={role || (title ? 'img' : undefined)}
        className={`feather__icon${className ? ` ${className}` : ''}`}
      />
    );
  },
);

const FeatherIcon = styled(InnerIcon)`
  display: inline-block;
`;

export default FeatherIcon;
