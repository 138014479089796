import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PlatformApiColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#2876E8"
        fillRule="evenodd"
        d="M3.631 1.5C2.73 1.5 2 2.226 2 3.122v3.251c0 .896.73 1.623 1.631 1.623H20.37c.902 0 1.631-.727 1.631-1.623v-3.25c0-.897-.73-1.623-1.631-1.623H3.63zm13.925 3.413c0 .657.497 1.19 1.11 1.19.614 0 1.112-.533 1.112-1.19 0-.658-.498-1.19-1.111-1.19-.614 0-1.111.532-1.111 1.19zM3.631 16.115c-.902 0-1.631.727-1.631 1.623v3.25c0 .897.73 1.623 1.631 1.623H20.37c.902 0 1.631-.726 1.631-1.622v-3.251c0-.896-.73-1.623-1.631-1.623H3.63zm15.036 4.274c-.614 0-1.111-.533-1.111-1.19 0-.658.497-1.191 1.11-1.191.614 0 1.112.533 1.112 1.19 0 .658-.498 1.19-1.111 1.19zM2 10.43c0-.896.73-1.622 1.631-1.622H20.37c.9 0 1.631.726 1.631 1.622v3.251c0 .896-.73 1.622-1.631 1.622H3.63c-.9 0-1.631-.726-1.631-1.622v-3.25zm15.556 1.626c0 .657.497 1.19 1.11 1.19.614 0 1.112-.533 1.112-1.19 0-.658-.498-1.19-1.111-1.19-.614 0-1.111.532-1.111 1.19z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PlatformApiColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PlatformApiColoredSvg} {...props} />
));
PlatformApiColored.displayName = 'PlatformApiColored';
export default PlatformApiColored;
