import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AudioSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.857 8.143H6.89L13 3v18l-6.111-5.143H5.857a3.857 3.857 0 1 1 0-7.714zm0 2h1.761L11 7.297v9.406l-3.382-2.846h-1.76a1.857 1.857 0 1 1 0-3.714z"
        clipRule="evenodd"
      />
      <path d="M18.576 6.21A8.145 8.145 0 0 1 21 12c0 2.17-.87 4.252-2.424 5.79l1.407 1.42A10.145 10.145 0 0 0 23 12c0-2.706-1.086-5.3-3.017-7.21l-1.407 1.42zM15.296 9.456a3.572 3.572 0 0 1 1.064 2.54c0 .95-.381 1.863-1.063 2.539l1.406 1.421a5.573 5.573 0 0 0 1.657-3.96 5.573 5.573 0 0 0-1.657-3.961l-1.406 1.421z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Audio = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AudioSvg} {...props} />
));
Audio.displayName = 'Audio';
export default Audio;
