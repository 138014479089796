import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { Dropdown } from '@feather/components/dropdown';
import type { FormInputProps } from '@feather/components/input';
import { FormGroup } from '@feather/components/input';
import useInputId from '@feather/components/input/useInputId';
import type { DropdownProps } from '@feather/types';

export interface InputSelectItem {
  value: string | number;
  label: ReactNode | string;
  node?: ReactNode | string;
}

interface InputSelectProps extends DropdownProps<InputSelectItem> {}

type FormInputSelectProps = FormInputProps<HTMLInputElement, InputSelectProps>;

const InputSelect = forwardRef<typeof Dropdown, FormInputSelectProps>(
  (
    {
      id,
      className,
      name,
      icons,
      size,
      width,
      label,
      labelHelperText,
      labelExtra,
      helperText = '',
      readOnly = false,
      disabled = false,
      error,
      ...dropdownProps
    },
    ref,
  ) => {
    const inputId = useInputId(id);

    const defaultItemToString = (item: InputSelectItem) => `${item.label}`;
    const defaultItemToElement = (item: InputSelectItem) => (item.node ? item.node : `${item.label}`);

    return (
      <FormGroup
        inputId={inputId}
        className={className}
        inputName={name}
        label={label}
        labelHelperText={labelHelperText}
        labelExtra={labelExtra}
        icons={icons}
        helperText={helperText}
        readOnly={readOnly}
        disabled={disabled}
        error={error}
      >
        <Dropdown<InputSelectItem>
          toggleButtonRef={ref as FormInputSelectProps['toggleButtonRef']}
          size={size}
          width={width}
          error={error?.hasError ?? false}
          itemToString={defaultItemToString}
          itemToElement={defaultItemToElement}
          disabled={disabled}
          {...dropdownProps}
        />
      </FormGroup>
    );
  },
);

export default InputSelect;
