import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextAlignBottomSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 19H3v2h18v-2zM6.293 12.207l1.414-1.414L11 14.086V3h2v11.086l3.293-3.293 1.414 1.414L12 17.914l-5.707-5.707z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextAlignBottom = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextAlignBottomSvg} {...props} />
));
TextAlignBottom.displayName = 'TextAlignBottom';
export default TextAlignBottom;
