import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UnmuteFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14.667 2H1.333v10.667h4.724L8 14.61l1.943-1.943h4.724V2zm-9 5.333a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3.333 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm2.333 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 16, height: 16 },
);
const UnmuteFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UnmuteFilledSvg} {...props} />
));
UnmuteFilled.displayName = 'UnmuteFilled';
export default UnmuteFilled;
