import { nanoid } from 'nanoid/non-secure';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import type {
  NotificationType,
  ToastGeneratorIconDict,
  ToastGeneratorOption,
  ToastID,
  ToastIconDict,
  ToastOption,
} from '@feather/types';

import { ToastQueue } from './ToastQueue';
import type { ToastQueueRef } from './constants';
import { initialContainerStyles } from './constants';

const DEFAULT_TOAST_ROOT_ELEMENT_ID = '@feather-toast-root';

const createToastRootElement = (rootElementID: string = DEFAULT_TOAST_ROOT_ELEMENT_ID) => {
  const rootElement = document.createElement('div');

  rootElement.setAttribute('id', rootElementID);
  document.body.appendChild(rootElement);

  return rootElement;
};

const defaultIconDict: ToastIconDict = {
  error: { icon: Icons.ErrorFilled, color: 'white', size: 20 },
  info: { icon: Icons.InfoFilled, color: 'white', size: 20 },
  success: { icon: Icons.SuccessFilled, color: cssVariables('bg-positive'), size: 20 },
  warning: { icon: Icons.WarningFilled, color: cssVariables('neutral-10'), size: 20 },
};

let toastRoot: Root | null = null;

class ToastStatic {
  private toastRootElement: HTMLElement | null = null;
  private toastQueueRef: ToastQueueRef | null = null;
  private iconDict: ToastIconDict = defaultIconDict;
  private onRenderCallback: ((ref: ToastQueueRef) => void) | null = null;

  constructor(
    private options: ToastGeneratorOption = {
      rootElementID: DEFAULT_TOAST_ROOT_ELEMENT_ID,
    },
  ) {
    this.options = options;
    this.toastRootElement = null;

    this.options.iconDict && this.setIconDictWithOptions(this.options.iconDict);
    this.options.containerStyles = initialContainerStyles;

    if (typeof window === 'undefined') {
      // Note that window.document can be undefined on server-side rendered applications.
      this.toastRootElement = null;
      return;
    }
  }

  set rootElementID(value: string) {
    if (this.options.rootElementID !== value) {
      this.unmount();
      this.toastRootElement = null;
      this.options.rootElementID = value || undefined;
    }
  }

  get rootElementID() {
    return this.options.rootElementID ?? DEFAULT_TOAST_ROOT_ELEMENT_ID;
  }

  set containerStyles(containerStyles: ToastGeneratorOption['containerStyles']) {
    this.options.containerStyles = containerStyles ?? initialContainerStyles;
  }

  get containerStyles() {
    return this.options.containerStyles;
  }

  private setIconDictWithOptions(newIconDict: ToastGeneratorIconDict) {
    // custom iconDict would be merged to defaultIconDict
    const overWrittenIconDict = { ...this.iconDict };
    Object.entries(newIconDict).forEach(([status, iconProps]) => {
      overWrittenIconDict[status] = { ...overWrittenIconDict[status], ...iconProps };
    });
    this.iconDict = overWrittenIconDict;
  }

  private getRootElement() {
    if (this.toastRootElement) {
      return this.toastRootElement;
    }
    const rootElement = document.getElementById(this.rootElementID);
    if (rootElement) {
      this.toastRootElement = rootElement;
      return rootElement;
    }
    this.toastRootElement = createToastRootElement(this.rootElementID);
    return this.toastRootElement;
  }

  private renderToastQueue = () => {
    if (!toastRoot) {
      toastRoot = createRoot(this.getRootElement());
    }
    toastRoot.render(
      <ToastQueue
        ref={(ref) => {
          if (ref) {
            this.toastQueueRef = ref;
            if (this.onRenderCallback) {
              this.onRenderCallback(ref);
              this.onRenderCallback = null;
            }
          }
        }}
      />,
    );
  };

  private showToast = (status: NotificationType, option: ToastOption): Promise<ToastID> => {
    return new Promise((resolve) => {
      const id = nanoid();
      const addToast = (toastQueue: ToastQueueRef) => {
        // custom iconProps would be merged to iconDict
        const overWrittenIconProps = { ...this.iconDict[status], ...option.iconProps };
        toastQueue.addToast({
          id,
          status,
          option: { ...option, iconProps: overWrittenIconProps },
        });
        return resolve(id);
      };
      if (this.toastQueueRef == null) {
        // render ToastQueue then add a toast
        this.onRenderCallback = addToast;
        this.renderToastQueue();
        return resolve(id);
      }
      addToast(this.toastQueueRef);
    });
  };

  /**
   * Show toast for success action
   * @returns {ToastID} toast id
   */
  public success = (option: ToastOption) => this.showToast('success', option);

  /**
   * Show toast for information action
   * @returns {ToastID} toast id
   */
  public info = (option: ToastOption) => this.showToast('info', option);

  /**
   * Show toast for warning action
   * @returns {ToastID} toast id
   */
  public warning = (option: ToastOption) => this.showToast('warning', option);

  /**
   * Show toast for error action
   * @returns {ToastID} toast id
   */
  public error = (option: ToastOption) => this.showToast('error', option);

  /**
   * Show toast with status
   * @returns {ToastID} toast id
   */
  public open = ({ status, ...option }: ToastOption & { status: NotificationType }) => this.showToast(status, option);

  /**
   * Close toast
   */
  public close = (toastId: ToastID) => this.toastQueueRef?.removeToast(toastId);

  /**
   * Close all toast
   */
  public closeAll = () => this.toastQueueRef?.removeAllToast();

  /**
   * Unmount and remove the current ToastQueue
   */
  public unmount = () => {
    if (this.toastRootElement) {
      toastRoot?.unmount();
      toastRoot = null;
      this.toastQueueRef = null;
    }
  };
}

/**
 * @var {ToastStatic} toast generated with default option
 */
export const toast = new ToastStatic();
