import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LightbulbFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 2C7.862 2 4.5 5.342 4.5 9.474c0 2.24.89 4.294 2.508 5.685v3.338H17V15.16c1.62-1.392 2.5-3.447 2.5-5.685C19.5 5.342 16.138 2 12 2zM6.5 9.474C6.5 6.454 8.959 4 12 4s5.5 2.454 5.5 5.474c0 1.846-.775 3.427-2.094 4.4l-.406.3v2.323H9.008v-2.323l-.406-.3c-1.32-.975-2.102-2.556-2.102-4.4z"
        clipRule="evenodd"
      />
      <path d="M7 20v2h10v-2H7z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LightbulbFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LightbulbFilledSvg} {...props} />
));
LightbulbFilled.displayName = 'LightbulbFilled';
export default LightbulbFilled;
