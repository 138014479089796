import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CollapseSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M18 20v-7H7.914l2.293 2.293-1.414 1.414L4.086 12l4.707-4.707 1.414 1.414L7.914 11H18V4h2v16h-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Collapse = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CollapseSvg} {...props} />
));
Collapse.displayName = 'Collapse';
export default Collapse;
