import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const IntegrationsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m20.754 4.708 2.23-2.23-1.414-1.414-2.148 2.148c-2.392-1.9-5.918-1.624-8.164.622L9.314 5.778l8.485 8.485 1.945-1.944c2.078-2.079 2.47-5.253 1.01-7.61zM3.833 11.258c-2.078 2.079-2.47 5.254-1.01 7.611L.593 21.1l1.414 1.415 2.148-2.149c2.392 1.901 5.918 1.625 8.164-.621l1.944-1.945-1.414-1.414 2.121-2.122-1.414-1.414-2.121 2.121-2.828-2.828 2.121-2.121-1.414-1.414-2.122 2.121-1.414-1.414-1.945 1.944z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const IntegrationsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={IntegrationsFilledSvg} {...props} />
));
IntegrationsFilled.displayName = 'IntegrationsFilled';
export default IntegrationsFilled;
