import styled, { css } from 'styled-components';

import { transitions } from '@feather/animation';

import { getInterpolatedYValue } from '../../utils/getInterpolatedYValue';
import type { AvatarType } from './AvatarType';

function getDotSize(avatarSize: number) {
  return Math.round(
    getInterpolatedYValue(
      [
        [64, 16],
        [40, 12],
        [32, 10],
        [20, 8],
        [16, 0],
      ],
      avatarSize,
    ),
  );
}

export const StatusDot = styled.div<{ color?: string; avatarSize: number; type: AvatarType }>`
  position: absolute;
  border: 2px solid #fff;
  ${({ avatarSize, color }) => css`
    right: ${avatarSize > 40 ? 0 : -2}px;
    top: ${avatarSize > 40 ? 0 : -2}px;
    width: ${getDotSize(avatarSize)}px;
    height: ${getDotSize(avatarSize)}px;
    border-radius: ${getDotSize(avatarSize) / 2}px;
    background-color: ${color};
    transform: scale(${color ? 1 : 0});
  `}
  transition: ${transitions({ duration: 0.2, properties: ['background-color', 'transform'] })};
`;

StatusDot.displayName = 'StatusDot';
