import type { ReactNode } from 'react';
import { useState, useCallback, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import { Spinner } from '@feather/components/spinner';
import type { TooltipProps } from '@feather/components/tooltip';
import { Tooltip } from '@feather/components/tooltip/Tooltip';
import cssVariables from '@feather/theme/cssVariables';

import { getInterpolatedYValue } from '../../utils/getInterpolatedYValue';
import { AvatarType } from './AvatarType';
import { RandomAvatar } from './RandomAvatar';
import { StatusDot } from './StatusDot';
import { getHashcode } from './getHashcode';

export interface AvatarProps {
  type: AvatarType;
  profileID: string | number;
  imageUrl?: string;

  /**
   * xsmall(16px), small(20px), medium(32px), xmedium(40px), large(60px)
   */
  size: 'xsmall' | 'small' | 'medium' | 'xmedium' | 'large' | number;
  status?: 'online' | 'away' | 'offline';
  className?: string;
  isLoading?: boolean;
  /** Tooltip appears when an image fails to be loaded. */
  tooltipOnLoadFail?: Omit<TooltipProps, 'children'>;
}

enum AvatarShape {
  RoundedSquare = 'square',
  Circle = 'circle',
}

const sizeMap = {
  xsmall: 16,
  small: 20,
  medium: 32,
  xmedium: 40,
  large: 60,
};

const spinnerSizeMap = [
  [60, 24],
  [40, 20],
  [32, 16],
  [20, 10],
  [16, 8],
] as const;

const squareAvatarBorderRadiusMap = [
  [60, 12],
  [40, 8],
  [32, 4],
  [20, 4],
  [16, 2],
] as const;

const shapeMap: Record<AvatarType, AvatarShape> = {
  [AvatarType.Application]: AvatarShape.RoundedSquare,
  [AvatarType.Channel]: AvatarShape.RoundedSquare,
  [AvatarType.Member]: AvatarShape.Circle,
  [AvatarType.Organization]: AvatarShape.RoundedSquare,
  [AvatarType.User]: AvatarShape.Circle,
  [AvatarType.Bot]: AvatarShape.Circle,
};

const statusColorMap = {
  online: cssVariables('bg-positive'),
  away: cssVariables('bg-attention'),
  offline: cssVariables('neutral-5'),
};

const Container = styled.div<{ $size: number }>`
  position: relative;
  flex: none;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`;

const AvatarWrapper = styled.div<{
  $shape: 'circle' | 'square';
  $size: number;
  $squareBorderRadius: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $shape, $squareBorderRadius }) => ($shape === 'circle' ? '50%' : `${$squareBorderRadius}px`)};
  overflow: hidden;
  user-select: none;
`;

const SpinnerWrapper = styled(AvatarWrapper)`
  background-color: ${cssVariables('bg-3')};
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NotFoundAvatar = ({ size }: { size: number }) => (
  <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.875 39.875C33.875 40.9105 33.0355 41.75 32 41.75C30.9645 41.75 30.125 40.9105 30.125 39.875C30.125 38.8395 30.9645 38 32 38C33.0355 38 33.875 38.8395 33.875 39.875Z"
      fill="#858585"
    />
    <path
      d="M26.0016 29.0858C25.9253 25.6405 28.5945 23 32.1533 23C35.4834 23 38 25.213 38 28.1553C38 30.8077 36.4632 32.0378 35.1372 33.0992C34.1374 33.8996 33.2573 34.604 33.2464 35.75H30.3485C30.3485 33.2241 31.7498 32.1841 33.0058 31.252C34.0256 30.4951 34.9496 29.8094 34.9496 28.4571C34.9496 26.8476 33.7802 25.8669 32.1025 25.8669C30.1705 25.8669 29.0012 27.25 29.0012 29.0858H26.0016Z"
      fill="#858585"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 32C17 23.7157 23.7157 17 32 17C40.2843 17 47 23.7157 47 32C47 40.2843 40.2843 47 32 47C23.7157 47 17 40.2843 17 32ZM32 20C25.3726 20 20 25.3726 20 32C20 38.6274 25.3726 44 32 44C38.6274 44 44 38.6274 44 32C44 25.3726 38.6274 20 32 20Z"
      fill="#858585"
    />
  </svg>
);

export const Avatar = ({
  profileID = 0,
  imageUrl,
  className,
  type,
  size,
  status,
  isLoading,
  tooltipOnLoadFail,
}: AvatarProps) => {
  const shape = shapeMap[type];

  const [didFailToLoadImage, setDidFailToLoadImage] = useState(false);
  const [isImageLoadCompleted, setIsImageLoadCompleted] = useState(true);

  useEffect(() => {
    // If imageUrl is empty, set isImageLoadCompleted true.
    setIsImageLoadCompleted(!imageUrl);

    setDidFailToLoadImage(!imageUrl);
  }, [imageUrl]);

  const onImgLoad = useCallback(() => setIsImageLoadCompleted(true), []);

  const profileHash = typeof profileID === 'string' ? getHashcode(profileID) : profileID;
  const sizeInNumber = typeof size === 'number' ? size : sizeMap[size];
  const squareAvatarBorderRadius = getInterpolatedYValue(squareAvatarBorderRadiusMap, sizeInNumber);
  const spinnerSize = getInterpolatedYValue(spinnerSizeMap, sizeInNumber);

  const shapedSpinner = useMemo(() => {
    return (
      <SpinnerWrapper $size={sizeInNumber} $shape={shape} $squareBorderRadius={squareAvatarBorderRadius}>
        <Spinner size={spinnerSize} stroke={cssVariables('content-3')} />
      </SpinnerWrapper>
    );
  }, [shape, sizeInNumber, spinnerSize, squareAvatarBorderRadius]);

  const statusDot =
    status && [AvatarType.User, AvatarType.Member, AvatarType.Bot].includes(type) ? (
      <StatusDot color={statusColorMap[status]} avatarSize={sizeInNumber} type={type} />
    ) : null;

  const wrapAvatar = useCallback(
    (children: ReactNode) => {
      return (
        <AvatarWrapper
          $size={sizeInNumber}
          $shape={shape}
          $squareBorderRadius={squareAvatarBorderRadius}
          style={isImageLoadCompleted ? undefined : { backgroundColor: cssVariables('bg-3') }}
        >
          {children}
        </AvatarWrapper>
      );
    },
    [isImageLoadCompleted, shape, sizeInNumber, squareAvatarBorderRadius],
  );

  if (isLoading === true) {
    return (
      <Container className={className} $size={sizeInNumber}>
        {shapedSpinner}
        {statusDot}
      </Container>
    );
  }

  if (!imageUrl) {
    return (
      <Container className={className} $size={sizeInNumber}>
        {wrapAvatar(<RandomAvatar type={type} size={sizeInNumber} hashcode={profileHash} />)}
        {statusDot}
      </Container>
    );
  }

  if (didFailToLoadImage) {
    if (tooltipOnLoadFail) {
      return (
        <Container className={className} $size={sizeInNumber}>
          <Tooltip {...tooltipOnLoadFail}>
            {wrapAvatar(<NotFoundAvatar size={sizeInNumber} />)}
            {statusDot}
          </Tooltip>
        </Container>
      );
    }

    return (
      <Container className={className} $size={sizeInNumber}>
        {wrapAvatar(<NotFoundAvatar size={sizeInNumber} />)}
        {statusDot}
      </Container>
    );
  }

  return (
    <Container className={className} $size={sizeInNumber}>
      <AvatarWrapper
        $size={sizeInNumber}
        $shape={shape}
        $squareBorderRadius={squareAvatarBorderRadius}
        style={isImageLoadCompleted ? undefined : { backgroundColor: cssVariables('neutral-3') }}
      >
        {wrapAvatar(
          <>
            <Image
              ref={(imgElement) => {
                const isImageLoaded = imgElement ? imgElement.complete && imgElement.naturalHeight !== 0 : false;
                setIsImageLoadCompleted(isImageLoaded);
              }}
              src={imageUrl}
              onError={() => {
                setDidFailToLoadImage(true);
              }}
              onLoad={onImgLoad}
              style={{ opacity: isImageLoadCompleted ? 1 : 0 }}
            />
            {!isImageLoadCompleted && <Spinner size={spinnerSize} stroke={cssVariables('neutral-6')} />}
          </>,
        )}
      </AvatarWrapper>
      {statusDot}
    </Container>
  );
};
