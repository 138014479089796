import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ChatHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16.623 4.303a2.72 2.72 0 0 1 2.73 2.712v6.328a2.72 2.72 0 0 1-2.73 2.712H2.978v-9.04a2.72 2.72 0 0 1 2.729-2.712h10.916zM8.436 17.411H2.978v5.423l5.458-5.423zm21.502-1.595c-1.347 0-2.353-1.039-2.353-2.398 0-1.359 1.006-2.417 2.353-2.417.945 0 1.75.519 1.95 1.238h2.875c-.261-2.258-2.353-3.936-4.825-3.936-2.916.001-5.127 2.199-5.127 5.115 0 2.916 2.211 5.095 5.127 5.095 2.513 0 4.624-1.679 4.845-4.016H31.91c-.201.76-1.026 1.318-1.971 1.318zm8.744-6.033c.624-.94 1.548-1.479 2.674-1.479 2.534 0 4.062 1.678 4.062 4.455v5.554h-2.835V13.12c0-1.279-.764-2.117-1.95-2.117s-1.95.838-1.95 2.117v5.194h-2.836V4.773h2.835v5.01zm15.622.12c-.684-1.02-1.75-1.599-3.137-1.599-2.553 0-4.584 2.278-4.584 5.095 0 2.817 2.031 5.114 4.584 5.114 1.387 0 2.454-.579 3.137-1.598v1.398h2.835V8.504h-2.835v1.398zm-2.473 5.913c-1.368 0-2.473-1.059-2.473-2.418 0-1.36 1.107-2.418 2.473-2.418 1.366 0 2.473 1.059 2.473 2.418 0 1.36-1.086 2.418-2.473 2.418zm13.188-7.312v2.676h-2.433v3.097c0 1.06.724 1.518 1.71 1.518.24 0 .501-.02.723-.08v2.597c-.383.101-.965.16-1.367.16-2.332 0-3.901-1.519-3.901-4.155V11.18h-1.83V8.503h1.83V5.926h2.835v2.578h2.433z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 68, height: 24 },
);
const ChatHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ChatHorizontalSvg} {...props} />
));
ChatHorizontal.displayName = 'ChatHorizontal';
export default ChatHorizontal;
