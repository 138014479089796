import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MaximizeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M14 5h3.586l-5.293 5.293 1.414 1.414L19 6.414V10h2V3h-7v2zM5 17.586V14H3v7h7v-2H6.414l5.293-5.293-1.414-1.414L5 17.586z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Maximize = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MaximizeSvg} {...props} />
));
Maximize.displayName = 'Maximize';
export default Maximize;
