import { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { DropdownVariant, DropdownToggleTheme, DropdownSize } from '@feather/types';
import { Body } from '@feather/typography';

export const defaultDropdownSizeStyleMap = {
  small: {
    contentMarginLeft: 15,
    paddingRight: 11,
    height: 32,
  },
  medium: {
    contentMarginLeft: 15,
    paddingRight: 11,
    height: 40,
  },
};

const inlineDropdownSizeStyleMap = {
  small: {
    contentMarginLeft: 8,
    paddingRight: 4,
    height: 32,
  },
  medium: defaultDropdownSizeStyleMap['medium'],
  // FIXME: only small inline is defined by design team
};

export const generateToggleContainerSizeStyle = ({
  size,
  variant,
}: {
  size: DropdownSize;
  variant: DropdownVariant;
}) => css`
  padding-top: 0;
  padding-bottom: 0;
  ${variant === 'default'
    ? css`
        min-width: 80px;
        height: ${defaultDropdownSizeStyleMap[size].height}px;
      `
    : css`
        height: ${inlineDropdownSizeStyleMap[size].height}px;
      `}
`;

export const generateToggleContentSizeStyle = ({ size, variant }: { size: DropdownSize; variant: DropdownVariant }) => {
  const sizeStyleMap = variant === 'default' ? defaultDropdownSizeStyleMap : inlineDropdownSizeStyleMap;

  return css`
    ${Body['body-short-01']};
    margin-left: ${sizeStyleMap[size].contentMarginLeft}px;
  `;
};

export const generateToggleArrowStyle = css<{ dropdownSize: DropdownSize; variant: DropdownVariant }>`
  margin-right: ${({ dropdownSize, variant }) =>
    variant === 'default'
      ? defaultDropdownSizeStyleMap[dropdownSize].paddingRight
      : inlineDropdownSizeStyleMap[dropdownSize].paddingRight}px;
`;

interface GetToggleColors {
  (variant: DropdownVariant, toggleTheme?: Partial<DropdownToggleTheme>): DropdownToggleTheme;
}

export const getToggleColors: GetToggleColors = (variant, toggleTheme) => {
  let toggleColors: DropdownToggleTheme | null = null;
  switch (variant) {
    case 'inline':
      toggleColors = {
        contentColor: cssVariables('neutral-10'),
        hoverContentColor: cssVariables('neutral-10'),
        activeContentColor: cssVariables('neutral-10'),
        disabledContentColor: cssVariables('neutral-6'),
        bgColor: 'transparent',
        hoverBgColor: cssVariables('neutral-2'),
        activeBgColor: cssVariables('purple-2'),
        disabledBgColor: 'transparent',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent',
        activeBorderColor: 'transparent',
        disabledBorderColor: 'transparent',
        pressedContentColor: cssVariables('purple-7'),
        pressedBorderColor: 'transparent',
        pressedBgColor: cssVariables('purple-2'),
        readOnlyBgColor: 'transparent',
        readOnlyContentColor: cssVariables('neutral-7'),
        readOnlyArrowColor: cssVariables('neutral-9'),
        focusOutlineColor: cssVariables('purple-7'),
      };
      break;
    default:
      toggleColors = {
        contentColor: cssVariables('neutral-10'),
        hoverContentColor: cssVariables('purple-7'),
        activeContentColor: cssVariables('purple-7'),
        disabledContentColor: cssVariables('neutral-5'),
        bgColor: 'transparent',
        hoverBgColor: 'transparent',
        activeBgColor: cssVariables('purple-2'),
        disabledBgColor: 'transparent',
        borderColor: cssVariables('neutral-4'),
        hoverBorderColor: cssVariables('purple-7'),
        activeBorderColor: cssVariables('purple-7'),
        disabledBorderColor: cssVariables('neutral-2'),
        pressedContentColor: cssVariables('purple-7'),
        pressedBorderColor: cssVariables('purple-7'),
        pressedBgColor: cssVariables('purple-2'),
        readOnlyBgColor: cssVariables('neutral-2'),
        readOnlyContentColor: cssVariables('neutral-7'),
        readOnlyArrowColor: cssVariables('neutral-9'),
        focusOutlineColor: cssVariables('purple-7'),
      };
      break;
  }
  return {
    ...toggleColors,
    ...toggleTheme,
  };
};
