import type { ReactNode } from 'react';
import { useRef, useState, useEffect } from 'react';

import { useLatestValue } from '@feather/utils/useLatestValue';

const transitionDuration = 0.2;

export const useAnimatedErrorMessage = (hasError: boolean, message: ReactNode) => {
  const isUnmounted = useRef(false);

  const [state, setResult] = useState({
    hasNegativeTranslateY: !hasError,
    visibleMessage: hasError ? message : null,
  });
  const lastSetTimeoutId = useRef<number | null>(null);

  const errorMessage = hasError ? message : null;
  const latestErrorMessage = useLatestValue(errorMessage);

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setResult({ hasNegativeTranslateY: false, visibleMessage: errorMessage });
      return;
    }

    // update the position only immediately.
    setResult((currentResult) => ({
      hasNegativeTranslateY: true,
      visibleMessage: currentResult.visibleMessage,
    }));

    lastSetTimeoutId.current != null && clearTimeout(lastSetTimeoutId.current);

    // update the text after the transition.
    lastSetTimeoutId.current = window.setTimeout(() => {
      if (isUnmounted.current) {
        return;
      }

      setResult({
        hasNegativeTranslateY: !latestErrorMessage.current,
        visibleMessage: latestErrorMessage.current,
      });
    }, transitionDuration * 1000);
  }, [errorMessage, latestErrorMessage]);

  return state;
};
