import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ProductsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.5 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM13.5 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM13.5 18.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM12 13.6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM18 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM18 20a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM19.5 12.1a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM7.5 18.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM6 13.6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Products = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ProductsSvg} {...props} />
));
Products.displayName = 'Products';
export default Products;
